@import "../../mpd-library/common/mixins";
@import "../../mpd-library/common/variables";
@import "./variables.scss";

@keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  
  .#{$ns}-list-loader-animated {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: transparent;
    background: linear-gradient(to right, #bdbdbd 8%, white 18%, #bdbdbd 33%);
    background-size: 800px 104px;
    position: relative;
  }