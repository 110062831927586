@import "../../components/common/styles";

.top-toolbar-main-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  background-color: white;
  border-radius: 20px 0px 0px 0px;
  padding: 0 20px;
}

@include for-size(phone-only) {
  .top-toolbar-main-wrapper {
    padding: 10px;
  }
}
