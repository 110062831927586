@import "../common/styles";

.root {
    background: #fafafa;
    display: flex;
    width: 100%;
    min-height: 70px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    position: sticky;
    z-index: 1;
    bottom: 0;
    column-gap: 20px;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;

    .counter {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: #293136;
    }
}

.form-was-changed-enter {
    height: 0;
    transform: translateY(70px);
}
.form-was-changed-enter-active {
    transform: translateY(0);
    transition: all 300ms;
}
.form-was-changed-exit {
    transform: translateY(0);
    opacity: 1;
}
.form-was-changed-exit-active {
    transform: translateY(70px);
    transition: all 300ms;
}
