@import "../../../common/styles";

.root {
    display: flex;
    width: 100%;
    column-gap: 20px;
    position: relative;
    padding-bottom: 30px;

    &::after {
        position: absolute;
        left: 22px;
        top: 44px;
        content: "";
        width: 1px;
        height: calc(100% - 44px);
        background-color: rgba(0, 0, 0, 0.15);
    }

    // &:has(+ div > div[sticky-header="true"]) + div {
    //     background-color: red !important;
    // }

    &:has(+ div > div[sticky-header="true"]) {
        padding-bottom: 0;
    }

    &:has(+ div > div[sticky-header="true"])::after {
        display: none;
    }

    .left-icon {
        width: 44px;
        height: 44px;
        border-radius: 100%;
        background-color: $primary-color;
    }

    .main-content {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        min-width: 400px;
        height: 20px;
    }

    .subtitle {
        font-family: $font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        height: 17px;
    }

    .title:global(.bp4-skeleton),
    .subtitle:global(.bp4-skeleton) {
        width: 70%;
    }

    .highlighted {
        color: $primary-color;
    }

    .left-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .date {
        margin-left: auto;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        color: #999999;
        max-height: 22px;
    }

    &:last-of-type::after {
        display: none;
    }
}
