@import "../common/styles";

.not-found-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: -webkit-fill-available;

    :global(.mpd-icon) {
        width: 68px;
        height: 68px;

        path {
            stroke: #1f76ff;
        }
    }
}

.title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin: 10px 0;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.02em;

    color: $black;
}

.subtitle {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.02em;

    color: rgba(0, 0, 0, 0.5);
}
