@import "../../../common/styles";

.root {
    position: relative;
    :global(.bp4-collapse) {
        z-index: 1;
        position: relative;
    }
    .top-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;
        height: 60px;
        // margin-bottom: 40px;

        .down-arrow {
            transition: transform 0.2s;
            transform: rotate(-90deg);
        }

        &__left-wrapper {
            display: flex;
            align-items: center;
            column-gap: 10px;
        }
    }

    .top-wraper_expanded {
        .down-arrow {
            transition: transform 0.2s;
            transform: rotate(360deg);
        }
    }

    .title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: #293136;
        margin-right: auto;
    }

    .collapse-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        padding: 20px 0 40px 0;
    }
}
