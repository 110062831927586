@import "../common/styles";

.reauth-message-wrapper {
    display: flex;
    align-items: center;
    .reauth-warning,
    .reauth-warning > .social-network-name {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #ff1f70;
    }

    .reauth-warning {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .social-network-name {
            text-transform: capitalize;
        }
    }

    button {
        border: none;
        background-color: unset;
        padding: 0;
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        box-sizing: border-box;
        margin-left: 12px;
        margin-right: 12px;
        border-bottom: 1px solid black;
        line-height: 23px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &:hover {
            cursor: pointer;
            color: $primary-color;
            border-bottom: 1px solid $primary-color;
        }
    }
}
