@import "../common/styles";

.avatar-wrapper {
    position: relative;
    flex-shrink: 0;
    font-size: 16px;

    .no-name-user-avatar {
        border-radius: 100%;
        background-color: #6b7aff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100%;
    }

    .image-source-tooltip-wrapper {
        // width: 32px;
        // height: 32px;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
    }
    .avatar-status-dot {
        width: 10px;
        height: 10px;
        border: 1px solid white;

        position: absolute;
        top: 0;
        right: 0;
        border-radius: 100%;
    }
    .avatar-status-dot.online {
        background-color: #00c54f;
    }

    .avatar-status-dot.away {
        background-color: #deb038;
    }

    .avatar-status-dot.offline {
        background-color: grey;
    }

    .image-source {
        width: 32px;
        height: 32px;
    }

    .anon-circle {
        width: 100%;
        height: 100%;
        background-color: #6b7aff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        font-weight: 600;
    }

    .avatar-channel-icon {
        border: 1px solid #ffffff;
        height: 16px;
        width: 16px;
        position: absolute;
        bottom: -5px;
        right: -5px;
        border-radius: 100%;

        svg {
            max-width: 100%;
            height: 100%;
        }

        img {
            max-width: 100%;
            height: 100%;
        }
    }
}
