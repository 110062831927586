@import "../../../../common/styles";

.admin-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    column-gap: 10px;

    &-left {
        display: flex;
        justify-content: space-between;
        // margin-right: 10px;
        white-space: nowrap;
        // background-color: red;
        width: 100%;
    }
    .based-on-permissions {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        color: rgba(0, 0, 0, 0.3);
        margin-right: 10px;
        position: relative;
    }

    .invited {
        margin-left: 7px;
        height: 16px;
        line-height: 16px;
        align-items: center;

        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 40%;
        width: 51px;
        white-space: nowrap;
    }

    &-avatars-wrapper {
        margin-right: 5px;
        position: relative;
        max-width: 143px;
        height: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        :nth-child(1) {
            z-index: 6;
            left: 0px;
        }
        :nth-child(2) {
            z-index: 5;
            left: 28px;
        }
        :nth-child(3) {
            z-index: 4;
            left: 56px;
        }
        :nth-child(4) {
            left: 84px;
            z-index: 2;
        }
        :nth-child(5) {
            z-index: 1;
            left: 112px;
        }
    }
}

.queue-add {
    padding: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    row-gap: 35px;

    :global(.list-item-with-checkbox) {
        :global(.list-item-with-checkbox-left-wrapper) {
            width: 50%;
        }
    }
    &-main-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        max-width: 1050px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;
        letter-spacing: 0.1em;

        color: $black;
    }

    &-name-and-admins {
        display: flex;
        justify-content: space-between;
        max-width: 1050px;
        width: 100%;
    }

    &-spinner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &-text-area-wrapper {
        width: 100%;
        max-width: 1050px;

        :global(textarea) {
            border-radius: 12px !important;
        }
    }

    &-input-wrapper {
        width: 50%;
        margin-right: 40px;
        position: relative;

        &:last-of-type {
            margin-right: 0;
        }

        :global(.styled-floating-input) {
            width: 100%;

            :global(.mpd-floating-input-container) {
                input {
                    border-radius: 12px;
                }

                :global(.mpd-floating-input-label) {
                    font-size: 12px !important;
                    // top: -28px !important;
                }
            }
        }

        :global(.bp4-popover2) {
            padding: 0;
        }
    }
    &-footer {
        background: #fafafa;
        display: flex;
        width: 100%;
        min-height: 70px;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        position: sticky;
        z-index: 1;
        bottom: 0;
        column-gap: 20px;
        justify-content: flex-end;
        align-items: center;
        padding: 0 20px;
    }
}

@include for-size(phone-only) {
    .queue-add {
        padding: 20px 10px;
        &-name-and-admins {
            flex-direction: column;
        }

        &-input-wrapper {
            width: 100%;
            margin-bottom: 20px;
        }

        &-text-area-wrapper {
            margin-top: 0px;
        }
    }
}
