@import "../../../common/styles";

.conversation-status-label {
    height: 19px;
    padding: 0 10px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 100%;
    color: #ffffff;
    margin-right: 5px;
}

.inbox {
    background-color: #4D4AE9;
}

.assigned {
    background-color: #1acaa0;
}

.resolved {
    background-color: #ff1c52;
}
