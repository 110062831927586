@import "../common/styles";

.image-plus-text {
    display: flex;
    user-select: none;
    align-items: center;
}

.image-plus-text-title {
    color: $light-black-color;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
}

.image-plus-text-image {
    display: flex;
}