@import "../common/styles.scss";
.styled-textarea {
  .mpd-floating-textarea-container {
    padding-top: 28px;
  }
  .mpd-floating-textarea-container.mpd-disabled {
    opacity: 0.5;
  }

  textarea {
    font-family: $font-family;
  }

  &.primary-basic {
    textarea {
      font-size: 16px;
      font-weight: 600;
      color: $light-black-color;
      border-width: 0px 0px 1px 0px !important;
      border-color: transparent !important;
      transition: border-color 0.3s ease-in-out;
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 0;
    }

    textarea::placeholder {
      font-family: $font-family;
      font-size: 12px;
    }

    .mpd-floating-textarea-label {
      display: none;
    }
    .mpd-floating-textarea-counter {
      display: none;
    }
    textarea:focus {
      border-color: $primary-color !important;
    }
  }

  .error {
    &.mpd-active {
      color: $form-error-color;
      font-family: $font-family;
      font-size: 12px;
      // letter-spacing: 0.02em;
      font-weight: 600;
    }
  }
  &.primary {
    textarea::placeholder {
      color: transparent;
    }
    textarea {
      font-size: 16px;
      font-weight: 600;
      color: $light-black-color;
      border-width: 0px 0px 1px 0px !important;
      border-color: transparent !important;
      transition: border-color 0.3s ease-in-out;
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 0;
    }

    .mpd-floating-textarea-label {
      color: $light-black-color;
      font-family: $font-family;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    .mpd-floating-textarea-counter {
      text-align: right;
      color: $light-black-color;
      font-family: $font-family;
      font-size: 12px;
      font-weight: 400;
    }
    textarea:focus {
      border-color: $primary-color !important;
    }

    textarea:focus ~ .mpd-floating-textarea-label,
    textarea:not(:focus):valid ~ .mpd-floating-textarea-label {
      // color: $primary-color;
      font-size: 12px;
      font-weight: 600;
    }

    textarea:focus ~ .mpd-floating-textarea-label {
      color: #1f76ff;
    }
  }

  &.primary-static {
    textarea::placeholder {
      color: transparent;
    }
    textarea {
      margin-bottom: 7px;
      order: 1;
      resize: none;
      outline: none !important;
      font-size: 16px;
      font-weight: 600;
      color: $light-black-color;
      transition: border-color 0.3s ease-in-out;
      background: $off-white-color;
      border: 1px solid $form-border-color;
      box-sizing: border-box;
      border-radius: 12px;
      box-shadow: none;
      -webkit-appearance: none;
    }

    .mpd-floating-textarea-label {
      color: $light-black-color;
      background: none;
      font-family: $font-family;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.1em;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: $form-lable-color;
      top: -4px !important;
    }
    .mpd-floating-textarea-counter {
      text-align: right;
      color: $light-black-color;
      font-family: $font-family;
      font-size: 12px;
      font-weight: 400;
      order: 2;
    }
    textarea:focus {
      border-color: $primary-color;
      box-shadow: 0 0 0 1px $primary-color;
      background: #f4f8ff;
    }

    textarea:focus ~ .mpd-floating-textarea-label,
    textarea:not(:focus):valid ~ .mpd-floating-textarea-label {
      // color: $primary-color;
      font-size: 12px;
      font-weight: 600;
    }

    textarea:focus ~ .mpd-floating-textarea-label {
      color: $primary-color;
    }
  }

  &.mpd-error {
    .styled-textarea-floating-textarea {
      textarea {
        border: 2px solid #ff7675;
        background-color: rgba(255, 118, 117, 0.15);
      }

      .mpd-floating-textarea-counter {
        color: #ff7675;
        font-weight: 600;
      }

      .mpd-floating-textarea-label {
        color: $form-error-color;
      }
    }
  }
}
