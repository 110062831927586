:global(.infinite-scroll) {
    // display: flex;
    width: 100%;
    height: 100%;
    overflow: auto;

    .infinite-scroll-load-more-spinner-wrapper {
        padding: 10px 0;
    }
}
