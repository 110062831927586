@import "../common/styles";

.sidebar-item-tooltip {
    .bp4-popover2-content {
        padding: 10px 12px;
    }
}
.styled-navbar {
    &.sidebar {
        width: 60px;
        background-color: #003554;
        display: flex;
        flex-direction: column;
        z-index: 11;
        flex-shrink: 0;
        justify-content: space-between;
    }
    &.topbar {
        height: 60px;
        background: #003554;
        display: flex;
        z-index: 1;
        justify-content: space-between;
        flex-shrink: 0;
    }
}

.styled-navbar-navbar-item {
    &.sidebar {
        height: 45px;
        width: 100%;
        cursor: pointer;
        .mpd-navbar-item-popover,
        .bp4-popover2-target,
        .bp4-popover2-wrapper,
        .bp4-popover2-target {
            width: 100%;
            height: 100%;
        }
        .bp4-popover2-target {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &.mpd-selected {
            background-color: rgba(31, 118, 255, 0.3);
            .notifications-item-num-of-notifications {
                border-color: #064887;
            }
        }
    }
    &.topbar {
        height: 100%;
        padding: 0 20px;
        cursor: pointer;
        .mpd-navbar-item-popover,
        .bp4-popover2-target,
        .bp4-popover2-wrapper,
        .bp4-popover2-target {
            width: 100%;
            height: 100%;
        }
        .bp4-popover2-target {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &.mpd-selected {
            background-color: rgba(31, 118, 255, 0.3);
            .notifications-item-num-of-notifications {
                border-color: #064887;
            }
        }
    }

    &:hover {
        .styled-button svg {
            opacity: 1 !important;
        }
    }
}
.styled-navbar-navbar-group:nth-child(2):not(:last-child):not(:first-child) {
    &.sidebar,
    &.topbar {
        flex: 1;
    }
}

.styled-navbar-navbar-item {
    .atlas-side-item-styled-button {
        padding: 0;
        min-width: unset;

        // svg.mpd-icon.right-icon {
        //     width: 24px;
        //     height: 26px;
        // }
        svg.mpd-icon.mpd-icon.button-left-icon {
            margin: 0;
        }
    }

    &.sidebar,
    &.topbar {
        .bp4-popover2-target {
            .mpd-icon path {
                opacity: 0.5;
            }
        }
        &.mpd-hover {
            .bp4-popover2-target {
                .mpd-icon path {
                    opacity: 0.75;
                }
            }
        }
        &:hover,
        &.mpd-selected {
            .bp4-popover2-target {
                .mpd-icon path {
                    opacity: 1;
                }
            }
        }
    }

    &.home-side-item {
        .bp4-popover2-target {
            .mpd-icon path {
                opacity: 1;
            }
        }
    }
}

.styled-navbar-navbar-item:active {
    &.sidebar,
    &.topbar {
        .bp4-popover2-wrapper {
            .mpd-icon path {
                opacity: 1;
            }
            .mpd-button-text {
                opacity: 1;
            }
        }
    }
}

.styled-navbar-navbar-item {
    &.sidebar {
        .mpd-button-text {
            display: none;
            font-size: 15px;
            font-weight: 600;
            font-family: $font-family;
            color: white;
        }
    }
    &.topbar {
        .mpd-button-text {
            font-size: 18px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.02em;
            font-family: $font-family;
            color: white;
            margin-left: 10px;
        }
    }
}

@include for-size(phone-only) {
    .styled-navbar {
        &.sidebar {
            position: fixed;
            top: 0;
            bottom: 0;
            width: 260px;
            opacity: 0;
            z-index: 0;
            &.mpd-active {
                opacity: 1;
                z-index: 1;
            }
        }
    }
    .styled-navbar-navbar-item {
        &.sidebar {
            padding: 25px;
            height: 60px;

            .notifications-item-num-of-notifications {
                left: 12px;
            }

            .mpd-button-text {
                display: initial;
                margin-left: 60px;
                font-size: 15px;
                font-weight: 600;
                font-family: $font-family;
            }
            .mpd-button {
                position: relative;
                svg {
                    position: absolute;
                    left: 0;
                }
            }
            .bp4-popover2-target {
                justify-content: initial;
            }
        }
    }
}
