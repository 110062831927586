@import "../../../../common/styles.scss";

.settings-audit-log-list-item-container:hover {
    background: rgba(0, 0, 0, 0.01);
}

.settings-audit-log-list-item-container {
    display: flex;
    justify-content: space-between;
    padding: 15px 40px;
    border-bottom: solid 1px $border-color;
    user-select: none;
    min-width: 800px;
}

.settings-audit-log-list-item-inner-container {
    display: flex;
    overflow: hidden;
    // width: 425px;
}

.settings-audit-log-list-item-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.activity-log-tooltip-target {
    max-width: 12px;
    max-height: 12px;
    margin-right: 40px;
    cursor: pointer;
}

.activity-log-level-dot {
    min-width: 12px;
    height: 12px;
    border-radius: 100%;
}

.activity-log-level-dot_info {
    background-color: #1deaa0;
}

.activity-log-level-dot_warning {
    background-color: #ffcd00;
}

.activity-log-level-dot_error {
    background-color: #ff1f70;
}

.settings-audit-log-list-item {
    white-space: nowrap;
    display: flex;
    align-items: center;
    // padding: 0 20px;
    flex: 1;
    width: 150px;
    min-width: 150px;
    span {
        display: flex;
        width: 100%;
        padding-right: 5px;
        font-family: $font-family;
        font-size: 14px;
        font-weight: 600;
        color: $light-black-color;
        text-align: start;
        word-break: break-word;
        white-space: initial;
    }

    &.activity-type {
        padding: 0;
        flex: 1;
        width: initial;
        .user,
        .case-id {
            color: $primary-color;
        }
    }
    &.date {
        .time {
            font-weight: 400;
            color: $lighter-black-color;
        }
    }
    &.ip {
        span {
            padding: 1px 0;
        }
        .location {
            font-weight: 400;
            font-size: 12px;
            color: $lighter-black-color;
        }
    }
    &.browser-type {
        min-width: 70px;
        overflow-x: auto;
        span {
            padding: 1px 0;
        }
        .os {
            color: $lighter-black-color;
            font-size: 12px;
            font-weight: 400;
        }
    }
}

@include for-size(phone-only) {
    .settings-audit-log-list-item-container {
        padding: 18px;
    }

    .settings-audit-log-list-item {
        width: 65%;
        overflow: hidden;
    }

    .settings-audit-log-list-item-inner-container {
        width: 33%;
        overflow: hidden;
    }

    .settings-audit-log-list-item {
        &.ip,
        &.browser-type {
            display: none;
        }
    }
}
