.api-keys-settings {
    height: calc(100% - 50px);
    &-top-wrapper {
        padding: 0 40px;
        margin-bottom: 40px;
        position: relative;
        z-index: 2;
    }

    :global(.empty-block) {
        height: calc(100% - 60px);

        svg {
            width: 67px;
            height: 58px;
            margin-bottom: 15px;
        }

        :global(.empty-block-subtitle) {
            max-width: 360px;
        }
    }
}
