@import "../../../../../../common/styles";

.root {
    max-width: 480px;
    width: 100%;

    .create-list-checkbox {
        display: flex;
        align-items: center;
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        text-align: right;

        color: rgba(0, 0, 0, 0.75);
        :global(.bp4-control-indicator) {
            width: 24px;
            height: 24px;
            margin-top: 1px;
            &::before {
                width: 24px;
                height: 24px;
            }
        }
    }
}
