@import "../../common/styles";

.root {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.no-results table {
    height: 60px;
}

.header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background-color: #fafafa;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    row-gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;

    .header__left {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    .search-input {
        width: 230px;
    }
}

.table table {
    width: 100%;
}

.edit-list-name-dialog-content {
    width: 420px;
}

.selected-counter {
    color: #000;
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.28px;
}

.trash-icon path {
    stroke: black;
}
