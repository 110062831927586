@import "../common/styles";

@import "../../../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css";

.styled-dateinput-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  &.primary {
    .styled-dateinput-left-icon {
      position: absolute;
      pointer-events: none;
      left: 10px;
      z-index: 1;
    }
    .styled-dateinput-right-icon {
      position: absolute;
      pointer-events: none;
      right: 10px;
      z-index: 1;
    }
    .styled-dateinput {
      width: 100%;
      .bp4-popover-target {
        width: 100%;
      }
      input {
        padding: 0 10px;
        box-shadow: none !important;
        border: 1px solid $border-dark-color;
        cursor: pointer;
        font-family: $font-family;
        font-size: 14px;
        font-weight: 700;
      }
      svg {
        path {
          stroke: $primary-color;
        }
      }
      &.padding-left {
        input {
          padding-left: 35px;
        }
      }
      .bp4-input-group {
        &.bp4-active {
          input {
            border-color: $primary-color;
            background-color: rgba(31, 118, 255, 0.05);
            color: #1f76ff;
          }
        }
      }
    }
  }
}

.styled-dateinput-popover {
  @import "../../../src/App/normalize";

  .DayPicker-wrapper {
    outline: none;
  }

  .DayPicker-NavBar {
    justify-content: flex-end;
  }

  .DayPicker-Day {
    border-radius: 50% !important;
    outline: none;
  }
  .DayPicker-Day--selected {
    background-color: $primary-color !important;
  }
  .DayPicker-NavButton--prev {
    left: initial !important;
    right: 29px;
    border-radius: 4px 0 0 4px;
    width: 32px;
    margin: 0 3px 0 !important;
    border: 1px solid $border-dark-color;
    border-width: 1px 1px 1px 1px;
    background-color: white;
    z-index: 1;
  }
  .DayPicker-Day {
    color: $primary-color;
  }
  .DayPicker-NavButton--next {
    border-radius: 0 4px 4px 0;
    right: -2px !important;
    width: 32px;
    margin: 0 !important;
    border: 1px solid $border-dark-color;
    background-color: white;
    z-index: 1;
  }
  .bp4-datepicker-month-select,
  .bp4-datepicker-year-select {
    font-family: $font-family !important;
    pointer-events: none;
    font-size: 14px;
    font-weight: 600 !important;

    select {
      padding: 0 7px;
      outline: none;
    }

    .bp4-icon-double-caret-vertical {
      display: none;
    }
  }
  .bp4-datepicker-caption-select:first-child {
    position: absolute;
    left: 0px;
  }
  .bp4-datepicker-caption-select:last-child {
    position: absolute;
    left: 80px;
  }
  .bp4-datepicker-caption-select {
    color: black;
    .bp4-icon {
      display: none;
    }
  }
  .bp4-datepicker-caption {
    border: none !important;
    position: relative;
    height: 30px;
    max-width: 150px;
    margin: 0 0 5px;
  }
  .bp4-datepicker {
    padding-top: 20px;
  }
  &.primary {
    .DayPicker-NavButton--prev,
    .DayPicker-NavButton--next {
      // .bp4-button {
      outline: none;
      // }

      &:hover:not(:active) {
        border-color: $primary-color;
        color: $primary-color;
      }
      &:active {
        border-color: $primary-color;
        background-color: $white-button-active-color;
        color: $primary-color;
      }
    }
    .DayPicker-NavButton--prev {
      &:hover,
      &:active {
        z-index: 10;
        border-right: solid 1px $primary-color;
      }
    }
  }
}

.styled-dateinput-wrapper:hover {
  &.primary {
    .styled-dateinput {
      input {
        border-color: $primary-color;
        color: #1f76ff;
      }
    }
    .styled-dateinput-right-icon,
    .styled-dateinput-left-icon {
      path {
        stroke: $primary-color;
      }
    }
  }
}
