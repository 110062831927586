@import "../common/styles";

.sort {
    & > div {
        justify-self: flex-end;
    }
    display: grid;
    column-gap: 10px;
    grid-template-columns: minmax(min-content, 1fr) 32px;
}

.sort-dropdown {
    justify-self: end;
}

.sort-direction-button:global(.bp4-active) {
    border-color: $primary-color !important;
    background-color: #f4f8ff;
    :global(.mpd-icon) {
        transform: rotate(540deg);

        path {
            stroke: $primary-color;
        }
    }
}

.sort-direction-button {
    justify-self: end;
    :global(.mpd-icon) {
        transition: all 0.2s;
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
        width: 16px;
        height: 16px;
    }
}
