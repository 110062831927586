@import "../../../common/styles.scss";

.access-permissions-item-title-container {
    display: flex;
    align-items: center;
}

.access-permissions-item-image {
    width: 45px;
    height: 42px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1), 0 -1px 5px rgba(0, 0, 0, 0.02);
    border-radius: 9px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.access-permissions-item-title {
    padding: 0 20px;
    color: black;
    font-family: $font-family;
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
}

.access-permissions-item-checkbox {
    color: $light-black-color;
    padding-top: 10px;
    padding-bottom: 0 !important;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    padding-left: 90px !important;
    white-space: nowrap;
    &.mpd-disabled {
        .bp4-control-indicator {
            opacity: 0.5;
        }
    }
}

.access-permissions-item-enable-item {
    display: flex;
}

.access-permissions-item-enable-item-image {
    width: 45px;
    height: 42px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1), 0 -1px 5px rgba(0, 0, 0, 0.02);
    border-radius: 9px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.access-permissions-item-styled-switch {
    flex: 1;
    margin-left: 20px;
    display: flex;
    .bp4-control-indicator {
        order: 0;
        display: none !important;
        margin-left: 0px !important;
    }
    .bp4-control {
        padding-left: 0 !important;
        color: black;
        font-family: $font-family;
        font-size: 18px;
        font-weight: 600;
    }
    &.mpd-active {
        .bp4-control-indicator {
            display: initial !important;
        }
    }
}

@include for-size(phone-only) {
    .access-permissions-item-checkbox {
        white-space: initial;
    }
}

.access-permissions-item {
    position: relative;
    margin-bottom: 20px;

    .styled-radio-group {
        padding-left: 65px;
        margin-top: 17px;
    }

    .styled-radio-group.mpd-disabled {
        .bp4-control.bp4-radio {
            opacity: 0.75;
        }
    }

    .bp4-control.bp4-radio {
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 11px;
        font-size: 14px;
        span.bp4-control-indicator {
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            display: flex;
            flex-direction: row;
            border: 1px solid #1f76ff;
            background-color: white !important;
            margin: 0 8px 0 0;
        }
    }

    .bp4-control.bp4-radio input:checked ~ .bp4-control-indicator::before {
        width: 12px !important;
        height: 12px !important;
        background: $primary-color !important;
        border-radius: 100% !important;
    }
}
