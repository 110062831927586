@import "../common/styles";

.toggle-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  .closebutton {
    display: none;
    width: 30px;
  }
  .leftbutton {
    display: none;
  }
  .label {
    padding: 0px 10px;
    color: black;
    white-space: nowrap;
    user-select: none;
    font-size: 16px;
    font-family: $font-family;
    font-weight: 700;
  }
  .togglebutton {
    margin-right: 10px;
    width: unset !important;
    min-width: unset !important;
  }
}

@include for-size(phone-only) {
  .toggle-buttons-container {
    .closebutton,
    .leftbutton {
      display: flex;
    }
    .togglebutton {
      display: none;
    }
  }
}
