pre[class*="language-"] {
	background-color: white;
	margin: 0;
	padding: 20px 10px 20px 50px;
}

code[class*="language-"] {
	font-family: Fira Code;
	// font-size: 12px;
	// line-height: 12px;
}

pre.line-numbers {
	position: relative;
	padding-left: 3.8em;
	counter-reset: linenumber;
}

pre.line-numbers > code {
	position: relative;
	border-left: unset;
	box-shadow: unset;
}

.line-numbers .line-numbers-rows {
	position: absolute;
	pointer-events: none;
	// height: 16px;
	top: 0;
	font-size: 100%;
	left: -3.8em;
	width: 3em; /* works for line-numbers below 1000 lines */
	letter-spacing: -1px;
	// border-right: 1px solid #999;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

}

	.line-numbers-rows > span {
		pointer-events: none;
		display: block;
		counter-increment: linenumber;
	}

		.line-numbers-rows > span:before {
			content: counter(linenumber);
			color: #999;
			display: block;
			padding-right: 0.8em;
			text-align: right;
		}