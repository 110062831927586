@import "../../../../common/styles.scss";

.user-settings-view-details-team {
    border-radius: 20px;
    background-color: $primary-color;
    color: white;
    text-align: center;
    padding: 3px 13px;
    margin-right: 10px;
    white-space: nowrap;
}

.user-settings-view-details-teams {
    display: flex;
    user-select: none;
}

.user-settings-view-details-teams-header {
    color: $primary-color;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 5px;
}

.user-settings-view-details-administrator-header {
    color: black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
}

.user-settings-view-details-administrator {
    display: flex;
}

.user-settings-view-details-input-container {
    display: flex;
}

.user-settings-view-details-icon-container {
    display: flex;
}

.user-settings-view-details-initials {
    width: 60px;
    height: 60px;
    background-color: #4a90e2;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
}

.user-settings-view-details-name {
    color: $light-black-color;
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
}

.user-settings-view-details-role {
    color: $primary-color;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 600;
}

.user-settings-view-details-info-item-container-studio {
    .access-permissions-item-image {
        svg {
            path {
                fill: $primary-color;
                fill-opacity: 1;
            }
        }
    }
}


.user-settings-view-details-name-inner-container {
    display: flex; 
    flex-direction: column;
    justify-content: center; 
}

.user-settings-view-details-info-item-container {
    pointer-events: none;
}