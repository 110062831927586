
.#{$ns}-column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.#{$ns}-row {
    display: flex;
    flex: 1;
}

.#{$ns}-row-wrap {
    @extend .#{$ns}-row;
    flex-wrap: wrap;
}

@include for-size(big-desktop-up) {
    
}

