@import "../common/styles";

.empty-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.02em;

        color: $black;

        margin-bottom: 5px;
        max-width: 70%;
    }

    &-subtitle {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.5);
        max-width: 70%;
    }
}

.empty-block.dropdown {
    padding: 20px 0px;

    :global(.emty-block-icon) {
        max-height: 50px;
        margin-bottom: 5px;
    }

    .empty-block-title {
        font-size: 14px;
        margin-bottom: 0;
    }

    .empty-block-subtitle {
        font-size: 12px;
    }
}

.empty-block.list {
    height: 100%;
    width: 100%;
    row-gap: 10px;

    :global(.emty-block-icon) {
        width: 70px;
        height: 70px;
    }
}
