@import "../../../../../common/styles";

.reauth-warning-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 49px;
    max-height: 80px;
    background-color: rgba(255, 31, 112, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    padding: 10px 20px;
}

.social-settings-edit-header {
    display: flex;
    align-items: center;
    height: 120px;
    background-color: #fafafa;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 39px 21px 33px 47px;
    justify-content: space-between;

    .disconnect-button {
        width: 120px;
        height: 36px;

        border: 1.5px solid #ff1f70;
        box-sizing: border-box;
        border-radius: 100px;
        color: #ff1f70 !important;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        background-color: #fafafa;
        outline: none;
    }

    .disconnect-button:hover {
        cursor: pointer;
    }

    .social-settings-edit-header-left {
        display: flex;
        align-items: center;

        .profile-pic {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            margin-right: 41px;
        }

        .names-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .name {
                font-family: Open Sans;
                font-style: normal;
                font-weight: bold;
                font-size: 28px;
                line-height: 38px;
            }

            .screen-name {
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}
.main-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
}

.social-settings-accounts-action {
    margin: 10px 20px;
    flex: initial;
    .bp4-button-text {
        color: $primary-color;
        font-family: $font-family;
        font-size: 12px;
        font-weight: 700;
    }
}
.social-settings-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.social-settings-accounts-wrapper {
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px 0;

    .profile-pic {
        border-radius: 100%;
        max-height: 48px;
        max-width: 48px;
    }
}

.social-settings-edit {
    height: 100%;
    .settings-control-property {
        padding: 25px 35px;
    }
}

.social-settings-edit-settings {
    // height: 100%;

    .alert-replies-settings-wrapper {
        max-width: 560px;
        display: flex;
        row-gap: 35px;
        flex-direction: column;
        width: 100%;
    }

    .panel-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 40px;
        overflow: auto;
    }
    .tabs {
        // height: 100%;
        // border-top: 1px solid rgba(0, 0, 0, 0.15);
        background-color: #fafafa;
        margin-top: 1px;

        .bp4-tab {
            width: 120px;
        }
    }

    .bp4-tab-panel {
        margin-top: 0;
        background-color: white;
        height: calc(100% - 176px);
    }

    .restyled-radio-group {
        padding-bottom: 17px;
    }

    .shadowed-setting-list-item-collapse-padding-wrapper {
        display: flex;
        gap: 0 40px;
        align-items: center;

        .bp4-popover2-target {
            flex: 1;
        }
        .dropdown-select-with-search-main-wrapper {
            flex: 1;
        }

        .bp4-popover2 {
            max-height: 200px;
        }
    }
}
