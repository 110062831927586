@import "../../../../../common/styles";

.business-hours {
    padding: 24px 0 0 64px;

    :global(.profile-automations-section-textarea) {
        margin-top: 72px !important;
    }

    &-content-time {
        width: 120px;
        background: #fafafa;
        border: 1px solid rgba(34, 36, 45, 0.12);
        box-sizing: border-box;
        border-radius: 12px;
    }

    &-day-label {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */

        display: flex;
        align-items: center;
        letter-spacing: 0.02em;

        color: rgba(0, 0, 0, 0.75);

        flex: 1;
        max-width: 251px;
        // max-width: ;
    }

    :global(.styled-switch) {
        justify-content: flex-start;
        :global(.bp4-control-indicator) {
            margin-top: 0px;
            margin-right: 20px;
        }
    }

    .collapse-without-padding {
        padding-top: 0;
    }

    &-text-area:global(.primary-static) {
        margin-top: 94px !important;
    }

    &-switch-label {
        display: flex;
        align-items: center;

        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */

        display: flex;
        align-items: center;
        letter-spacing: 0.02em;

        /* Primary Blue */

        color: #1f76ff;
    }

    &-day-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        max-width: 560px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;
    }

    &-switch-label-incative {
        color: $gray;
    }

    &-datepicker-wrappers {
        display: flex;
        align-items: center;

        .to {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            margin: 0 8px;
            /* or 114% */

            display: flex;
            align-items: center;
            letter-spacing: 0.02em;

            /* Grey */

            color: $gray;
        }
    }

    &-collapse {
        display: flex;
        flex-direction: column;
        padding-top: 30px;
    }

    &-subtitle {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;
        letter-spacing: 0.1em;

        color: $black;
        margin-bottom: 35px;
    }
}

.business-hours.custom-collapsed {
    :global(.styled-radio-group) {
        &::after {
            display: none;
        }
    }

    .business-hours-radio-group {
        label:last-of-type {
            margin-bottom: 0;
        }
    }
}


@include for-size(phone-only) {
    .profile-automations-section-textarea {
        // height: 250px;
    }
}
