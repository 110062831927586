@import "../common/styles";

.notifications-popover {
    &-top-toolbar {
        position: fixed;
        top: 0;
        background-color: white;
        z-index: 10;
        width: 100%;
        border-radius: 20px;
    }

    overflow: auto;
    height: 100%;
    padding-top: 100px;

    &-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        color: $black;
    }

    &-cancel-button:global(.bp4-button.mpd-button.styled-button.primary-simple) {
        padding: 0;
        min-width: unset;
    }

    &-sub-header-right-wrapper {
        display: flex;
        row-gap: 10px;
        :global(.bp4-button-text .mpd-button-text) {
            text-transform: unset !important;
        }

        :global(.delete) {
            min-width: 85px;
        }
    }

    &-read-button {
        margin-right: 10px;
        // max-width: 115px;
    }

    &-sub-header {
        width: 100%;
        position: fixed;
        top: 50px;
        z-index: 1;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        background-color: #fafafa;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        :global(.bp4-popover2-target) {
            max-width: max-content;
        }
        :global(.bp4-control.bp4-checkbox.styled-checkbox) {
            padding: 0 0 0 12px;

            :global(.bp4-control-indicator) {
                width: 24px;
                height: 24px;
                margin: 0;

                &::before {
                    margin: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        :global(.mpd-select) {
            width: max-content;
        }
    }

    &-load-more-spinner-wrapper {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        // :global(.bp4-spinner.bp4-intent-primary .bp4-spinner-head) {
        //     stroke: $primary-color;
        // }
    }

    :global(.bp4-popover2-target .mpd-select) {
        padding: 0 16px;
        width: max-content;
        margin: 0;
    }

    &-filter-dropdown-portal {
        :global(.bp4-popover2) {
            padding: 10px;
            z-index: 21;
            width: 220px;
            height: 142px;
            background-color: white;

            box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.15), 0px 2px 12px rgba(0, 0, 0, 0.15);

            :global(.bp4-popover2-content) {
                border-radius: unset;
            }
        }
    }

    &-mute-button {
        margin-right: 8px;
    }

    &-mute-button.muted:global(.mpd-button) {
        border-color: $primary-color !important;
    }

    :global(.empty-block) {
        width: 100%;
        height: calc(100% - 100px);
        div {
            width: 100%;
            max-width: unset;
        }
        :global(.mpd-icon) {
            width: 68px;
            height: 68px;

            margin-bottom: 15px;

            path {
                stroke: $primary-color;
            }
        }
    }
}

@include for-size(phone-only) {
    .notifications-popover {
        &-top-toolbar {
            justify-content: flex-start;
        }

        &-collapse-button {
            margin-right: 10px;
        }
    }
    .notifications-list {
        display: initial;
        flex: 1;
        width: 0;
        min-width: 400px;
        left: 0px;
        position: relative;
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}
