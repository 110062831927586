@import "../common/styles";

.stop-propagation-block {
    width: 100%;
}

.styled-checkbox.bp4-control.bp4-checkbox {
    width: 100%;
    user-select: none;
    margin-bottom: 0px !important;
    input ~ .bp4-control-indicator {
        outline: none !important;
        border-radius: 4px !important;
        box-shadow: unset;
        // border: 1px solid rgba(0, 0, 0, 0.15);
    }
    input:checked ~ .bp4-control-indicator,
    input:indeterminate ~ .bp4-control-indicator {
        background-color: $primary-color !important;
        box-shadow: none !important;
        border: unset;
        box-sizing: border-box;
    }

    input:indeterminate ~ .bp4-control-indicator {
        background-color: rgba(0, 0, 0, 0.15) !important;
    }

    input:indeterminate ~ .bp4-control-indicator::before {
        background-image: unset;
        width: 15px;
        height: 2px;
        background-color: white;
        border: unset;

        &::before {
            border-color: $primary-color;
        }
    }

    .bp4-control-indicator:before {
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
    }
}

.bp4-control input:disabled ~ .bp4-control-indicator {
    background-color: white !important;
    box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%), inset 0 -1px 0 rgb(16 22 26 / 10%);
    opacity: 0.5;
}

.bp4-disabled.styled-checkbox {
    input:checked ~ .bp4-control-indicator {
        background-color: $primary-color !important;
        box-shadow: none !important;
        opacity: 0.5;
    }
}
