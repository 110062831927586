.smart-links-qr-code {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    overflow: auto;

    .qr-code-wrapper {
        width: 166px;
        height: 166px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background-image: url("../../mpd-library/icon/assets/transparent-bg.jpeg");
        background-size: 20px;
        border: 1px solid #d5d5d5;
    }

    .generated-qr-code {
        display: flex;
        column-gap: 20px;
        align-items: center;
    }
}
