@import "../../../common/styles";

.root {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    overflow: auto;
    max-height: 100%;

    .contact-statistics {
        display: grid;
        column-gap: 40px;
        row-gap: 40px;
        grid-template-columns: 2fr 1fr 2fr;
        height: 120px;
        padding: 35px;
        box-sizing: border-box;
        align-items: start;

        &__card {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            align-items: center;
            justify-content: center;

            &__title {
                font-family: $font-family;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #000000;
            }

            &__value {
                font-family: $font-family;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #1f76ff;
            }
        }
    }
}
