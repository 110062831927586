@import "../../../common/styles";

.root {
    height: 75px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(34, 36, 45, 0.12);
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    cursor: pointer;

    .ago {
        color: $primary-color;
        text-align: right;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-left: auto;
    }

    .left-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 44px;
        border-radius: 100%;
        background-color: red;
    }

    .left-icon-wrapper_convo {
        background-color: $primary-color;
        flex-shrink: 0;
    }

    .main-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        justify-content: space-between;
        &__top-wrapper {
            width: 100%;
            &__title {
                font-family: $font-family;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;

                color: #000000;
                display: flex;
                column-gap: 15px;
            }
        }

        &__subtitle {
            font-family: $font-family;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            height: 19px;
            color: rgba(0, 0, 0, 0.75);
            max-lines: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }

        .tags-wrapper {
            display: flex;
            column-gap: 5px;
        }
    }

    .highlighted {
        color: $primary-color;
    }
}
