@import "../../../../common/styles";

.profile-automations-list {
    width: 100%;
    height: 100%;
    overflow: auto;
    // background-color: crimson;
    display: flex;
    flex-direction: column;
    padding: 40px;

    &-spinner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &-subtitle {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;
        letter-spacing: 0.1em;

        color: $black;
        margin-bottom: 40px;
    }

    :global(.duo-buttons) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 33px;
    }

    :global(.profile-automations-section-textarea) {
        :global(textarea.bp4-input) {
            font-family: $font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.04em;

            color: #293136;

            &:focus {
                box-shadow: unset !important;
                background: #f4f8ff !important;
            }
        }
        :global(.mpd-floating-textarea-label) {
            top: 6px !important;
        }
    }

    :global(.mpd-floating-textarea-label) {
        font-size: 12px !important;
    }
}

@include for-size(phone-only) {
    .profile-automations-list {
        padding: 10px;
    }
}
