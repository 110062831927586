@import "../../../../common/styles";

.title {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .list-type {
        text-transform: uppercase;
        color: $primary-color;
        font-family: Open Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
    }
}
