@import "../../../../../common/styles";

.smart-link-add {
    // padding: 65px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    &-content {
        display: grid;
        grid-template-columns: minmax(200px, 200px) minmax(320px, 590px);
        grid-template-rows: 1fr;
        align-self: stretch;
        justify-content: center;
        column-gap: 26px;
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    &-stepper-wrapper {
        padding: 65px 0 0 0;
    }

    &-stepper-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: #000000;
        margin-bottom: 30px;
    }

    &-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        bottom: 0;
        min-height: 50px;
        max-height: 50px;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        width: 100%;
        padding: 0 20px;
        background-color: white;
    }

    &-tr-group {
        position: relative;
        height: 100%;
        overflow: auto;
    }

    .smart-link-add-route-wrapper {
        width: 100%;
        height: 100%;
        padding: 65px 15px 20px 15px;
        box-sizing: border-box;
        overflow: auto;

        :global(.alert-replies-settings-wrapper) {
            display: flex;
            flex-direction: column;
            row-gap: 35px;
        }

        :global(.step-inputs-wrapper) {
            display: flex;
            flex-direction: column;
            row-gap: 35px;
        }
    }

    &-step-title {
        margin-top: 0;
        font-family: $font-family;
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 18px;
        color: #000000;
        margin-bottom: 35px;
    }
}
