@import '../../../../../common/styles';

.social-networks-channels-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding: 40px;

    &-group-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px 0;
    }

    &-connected-item-left-wrapper {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        span {
            font-family: $font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: right;
            color: $primary-color;
        }
    }
}
