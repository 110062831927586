@import "../../../../common/styles";
// .profile-automations-list {
//     .profile-listing-footer {
//     }

//     .profile-listing-item-wrapper {
//     }
// }

@include for-size(phone-only) {
    .profile-automations-list {
        .profile-automations-item-wrapper {
            padding: 42px 0 50px 0;
        }

        .profile-listing-footer {
            border: unset;
            margin-top: 0;
            padding: 0;
            height: 30px;

            .styled-button.mpd-button.bp4-button {
                margin: 0 0 0 15px;
            }
        }
    }
}
