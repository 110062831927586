@import "../common/styles.scss";

.stepper.vertical {
  flex-direction: column;
  row-gap: 20px;
  align-items: flex-start;
  padding: 0;

  .bp4-button {
    margin-right: 15px;
  }

  .stepper-step {
    max-width: 100%;
  }

  .stepper-step-text {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
  }

  .step-container {
    width: 100%;
    max-width: unset;
  }

  .step-container.mpd-default .stepper-step button.bp4-button {
    background: #fafafa !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .step-container.mpd-active .stepper-step button.bp4-button {
    background: #f4f8ff !important;
    border: 2px solid #1f76ff !important;

    .bp4-button-text .mpd-button-text {
      color: $primary-color !important;
    }
  }

  .step-container.step-with-validation-error:not(.mpd-active) {
    .stepper-step-text {
      color: #db2e45;
    }
    .stepper-step button.bp4-button {
      border: 1px solid #db2e45 !important;
      background-color: rgba(255, 118, 117, 0.15) !important;

      .bp4-button-text .mpd-button-text {
        color: #db2e45 !important;
      }
    }
  }
}

.stepper {
  display: flex;
  justify-content: center;
  position: relative;
}

.stepper-step {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  max-width: 36px;
}

.step-container.disabled {
  cursor: not-allowed !important;
}

.stepper-step-number {
  min-width: 32px !important;
  min-height: 32px !important;
  border-radius: 50% !important;
  padding: 0 !important;

  .mpd-button-text {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
  }
}

.step-container.mpd-active .stepper-step-number.mobile {
  min-width: 24px !important;
  min-height: 24px !important;
}

.step-container.mpd-finished .stepper-step-number.mobile,
.stepper-step-number.mobile {
  min-width: 12px !important;
  min-height: 12px !important;
}

.studio-cards {
  .step-container {
    align-items: center;
  }
}

.step-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
  max-width: 114px;
  &.last-item {
    flex: initial;
  }

  .stepper-step-line {
    margin-left: 0;
    margin-right: 0;
    border-color: #f4f4f5 !important;
    margin: 18px 0 0;
    max-width: 114px;
  }
  .stepper-step-text {
    white-space: nowrap;
  }

  &.mpd-finished {
    .stepper-step-number {
      background-color: #f4f4f5 !important;
      border-color: $primary-color !important;
      .mpd-button-text {
        color: white;
      }
    }
    .stepper-step-line {
      border-color: $primary-color !important;
    }
    .stepper-step-text {
      opacity: 1;
      color: $black;
    }
  }
  &.mpd-active {
    .stepper-step-number {
      background-color: #1f76ff;
      border: 2px solid $primary-color;
      .mpd-button-text {
        color: $primary-color !important;
      }
    }
    .stepper-step-line {
      border-color: #f4f4f5 !important;
    }
    .stepper-step-text {
      opacity: 1;
      color: $black;
    }
  }
  &.mpd-default {
    .stepper-step-number {
      background-color: #f4f4f5 !important;
    }

    .mpd-button-text {
      color: $gray;
    }
  }
}

.stepper-step-line {
  padding: 0;
  width: 100%;
  min-width: 5px;
  border-width: 0px;
  border-bottom: 2px solid $border-color;

  &.last-item {
    display: none;
  }
}

.stepper-step-text {
  // opacity: 0.25;
  color: $gray;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  top: 30px;
  cursor: pointer;
  user-select: none;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  .stepper-step-text {
    cursor: not-allowed !important;
  }
}

.stepper.horizontal {
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  display: flex;

  .step-container {
    display: flex;
    flex: 1;
    max-width: unset !important;
    min-width: 200px;
    justify-content: center;
  }
  .stepper-step {
    display: flex;
    flex-direction: column;
    z-index: 2;

    .bp4-button {
      max-width: 24px;
      max-height: 24px;
      box-sizing: border-box;
      min-width: 24px !important;
      min-height: 24px !important;

      .mpd-icon {
        width: 12px;
        height: 8px;
      }
    }
  }

  .step-container.mpd-finished .bp4-button {
    background-color: $primary-color !important;
    border: 2px solid $primary-color !important;
  }

  .step-container:not(.mpd-active, .mpd-finished) {
    .bp4-button {
      z-index: 2;
      border: 2px solid #cad8e6 !important;
    }
  }

  & > div:last-child {
    width: max-content;
  }

  .step-container.mpd-active .stepper-step .bp4-button.stepper-step-number {
    border: 2px solid $primary-color;
    background-color: white;
  }

  & > div:not(:first-child) {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: calc(100% - 24px);
      flex: 1;
      background-color: #cad8e6;
      left: calc(-50% + 12px);
      right: calc(50% + 12px);
      top: 12px;
      z-index: 1;
    }
  }

  .step-container.mpd-active::after,
  .step-container.mpd-finished::after {
    background-color: $primary-color;
  }

  .stepper-step-text {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #293136;
    margin-top: 10px;
  }

  .step-container.step-with-validation-error {
    .stepper-step-text {
      color: #db2e45;
    }
    .stepper-step button.bp4-button {
      border: 2px solid #db2e45 !important;
      background-color: rgba(255, 118, 117, 0.15) !important;

      .bp4-button-text .mpd-button-text {
        color: #db2e45 !important;
      }
    }
  }
}

@include for-size(phone-only) {
  .stepper {
    padding: 20px $small-padding;
  }

  .stepper-step-line {
    margin: 0px 3px 40px 3px;
  }

  .step-container {
    .stepper-step-text {
      white-space: normal;
    }
  }
}

@include for-size(tablet-portrait-up) {
  .stepper {
    padding: 20px $small-padding;
  }
  .stepper-step-line {
    margin: 0px 5px 40px 5px;
  }

  .step-container {
    .stepper-step-text {
      white-space: normal;
    }
  }
}

@include for-size(tablet-landscape-up) {
  .stepper {
    padding: 20px $large-padding;
  }
  .stepper-step-line {
    margin: 0px 10px 40px 10px;
  }
}
