@import "../common/styles";

.color-selector {
    .color-list {
        padding-left: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 20px;

        li {
            list-style: none;
            border-radius: 12px;
            min-width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.active {
                border: 2px solid #1f76ff;
            }

            span {
                width: 46px;
                height: 46px;
                border-radius: 12px;
            }
        }
    }
    // }

    .label {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;

        display: flex;
        align-items: center;
        letter-spacing: 0.1em;

        color: #7f8896;
    }

    .transparent-bg {
        background-image: url("../../mpd-library/icon/assets/transparent-bg.jpeg");
        background-size: 20px;
    }
}
