@import "../../../../common/styles.scss";

.group-settings-add {
    width: 50%;
}

@include for-size(tablet-portrait-up) {
    .group-settings-add {
        width: initial;
    }
}

@include for-size(phone-only) {
    .group-settings-add {
        width: initial;
    }
}