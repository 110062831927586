@import "../../../../../common/styles";

.smart-links-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 40px;
    row-gap: 40px;
    overflow: auto;

    :global(.title-subtitle-wrapper) {
        :nth-child(2) {
            overflow: visible !important;
        }
    }

    &-tooltip-copy-button:global(.bp4-popover2-target) {
        display: none;
        position: absolute;
        max-width: 24px;
        max-height: 24px;
        right: 0;
    }

    &-copy-button {
        max-height: unset;
        max-width: unset;
        min-height: unset !important;
        min-width: unset !important;

        max-width: 24px !important;
        max-height: 24px !important;

        :global(.bp4-button-text) {
            max-width: 24px;
            height: 24px;
        }
    }

    &-link-wrapper {
        display: flex;
        column-gap: 10px;
        align-items: center;
        position: relative;

        &:hover {
            .smart-links-list-link {
                color: $primary-color;
            }
        }

        &:hover {
            .smart-links-list-tooltip-copy-button:global(.bp4-popover2-target) {
                display: inline-block;
            }
        }
    }

    &-link {
        max-width: calc(100% - 42px);
        overflow: hidden;
        text-overflow: ellipsis;
        color: #808080;
        &:link {
            text-decoration: none;
        }
    }

    &-group {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    &-group-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: #000000;
    }

    :global(.shadowed-setting-list-item-left-wrapper) {
        max-width: calc(100% - 60px);
        flex-wrap: wrap;

        & > div {
            display: flex;
            flex: 1 1 auto;
        }
    }

    :global(.empty-block) {
        display: flex;
        justify-content: center;
    }

    :global(.empty-block-subtitle) {
        max-width: 524px;
    }

    :global(.mpd-icon) {
        margin-bottom: 20px;
    }

    :global(.title-subtitle-wrapper) {
        flex-grow: 0.1;
        max-width: 35%;
    }

    :global(.smart-link-info) {
        :global(.info-cell-value) {
            font-size: 16px;
        }
    }

    :global(.title-subtitle-wrapper) {
        min-width: 20%;
        // max-width: 90%;
        div:nth-of-type(1) {
            max-width: 100%;
        }
        div:nth-of-type(2) {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            cursor: pointer;
        }
    }

    &-name:global(.bp4-skeleton),
    &-link-wrapper:global(.bp4-skeleton) {
        min-width: 100%;
        height: 18px;
    }

    &-item-circle {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        max-width: 48px;
        border-radius: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-item-circle-initials {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 18px;
        color: #ffffff;
    }
}

@media screen and (max-width: 1440px) {
    .smart-links-list {
        :global(.title-subtitle-wrapper) {
            max-width: 30%;
        }
    }
}

@media screen and (max-width: 1325px) {
    .smart-links-list {
        :global(.title-subtitle-wrapper) {
            max-width: 85%;
        }
    }
}

@media screen and (max-width: 1050px) {
    .smart-links-list {
        :global(.title-subtitle-wrapper) {
            max-width: 80%;
        }

        :global(.shadowed-setting-list-item-left-wrapper) {
            max-width: 90%;
        }
    }
}

@media screen and (max-width: 900px) {
    .smart-links-list {
        :global(.title-subtitle-wrapper) {
            max-width: 80%;
        }

        :global(.shadowed-setting-list-item-left-wrapper) {
            max-width: 85%;
        }
    }
}
