@import "../common/styles.scss";

.styled-floating-input {
  position: relative;
  padding-top: 28px;
  // .mpd-floating-input-container {
  //   padding: 0 3px;
  // }

  .mpd-floating-input-counter {
    color: $light-black-color;
    font-family: $font-family;
  }

  .mpd-floating-input-fixed-placeholder {
    color: $border-dark-color;
    font-family: $font-family;
  }

  &.primary {
    .error {
      position: absolute;
      &.mpd-active {
        color: #db2e45;
        font-family: $font-family;
        font-size: 11px;
        line-height: 14px;
        font-weight: 600;
        font-family: $font-family;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        // letter-spacing: 0.02em;
        margin-top: 5px;
      }
    }

    &.mpd-error {
      .mpd-floating-input-container.mpd-active,
      .mpd-floating-input-container {
        .mpd-floating-input-label {
          color: #db2e45;
        }
      }
    }
    .mpd-floating-input-container.mpd-active {
      .mpd-floating-input-label {
        color: $primary-color;
      }
    }
    .mpd-styled-floating-input {
      &.mpd-active {
        input {
          border-color: $primary-color;
        }
        .mpd-floating-input-label {
          color: $primary-color;
        }
      }
    }
    .mpd-floating-input-label {
      position: absolute;
      top: -28px;
      font-family: $font-family;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.1em;
      font-weight: 600;
      text-transform: uppercase;
      color: $form-lable-color;
    }
    input:focus ~ .mpd-floating-input-label {
      color: $primary-color;
    }
    input:focus ~ .mpd-floating-input-label,
    input:not(:focus):valid ~ .mpd-floating-input-label {
      // color: $primary-color;
      font-size: 12px;
      font-weight: 600;
    }
    input {
      padding: 16px 17px;
      // margin-bottom: 7px;
      background: $off-white-color;
      border: 1px solid $form-border-color;

      border-radius: 12px;
      transition: 0.5s ease-in-out all;
      box-sizing: border-box;

      font-family: $font-family;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      letter-spacing: 0.04em;
      color: $form-text-color;
      -webkit-appearance: none;
    }
    .mpd-floating-input-invisible {
      font-size: 16px;
    }
    input:focus {
      border-color: $primary-color;
      background: #f4f8ff;
      // border: unset;
      box-shadow: 0 0 0 1px $primary-color;
    }

    input::placeholder {
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #bfbfbf;
    }
  }

  &.mpd-disabled {
    cursor: not-allowed !important;
    .mpd-floating-input-container {
      opacity: 0.5;
    }

    input {
      background: #fafafa !important;
      cursor: not-allowed !important;
    }
  }

  &.mpd-error.primary {
    &:focus-within {
      input {
        box-shadow: 0 0 0 1px #db2e45;
      }
    }
    input {
      border: 1px solid #db2e45;
      background-color: rgba(255, 118, 117, 0.15);
    }

    .mpd-floating-input-label {
      color: #db2e45;
    }

    .mpd-floating-input-counter {
      color: #db2e45;
      font-weight: 600;
    }
  }
}
