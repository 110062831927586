@import "../../../common/styles";

.billing-settings {
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
}

@include for-size(phone-only) {
    .billing-settings {
        .bp4-tab {
            flex: 1;
        }
    }
}