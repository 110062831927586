@import "../../../common/styles.scss";

.user-settings-inner-container {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.user-settings {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .container-empty {
        height: 100%;
        justify-content: center;
    }
}

.user-settings-empty,
.users-settings-list-container-empty {
    height: 100%;
    justify-content: center;
    .title {
        font-size: 22px;
    }
    .subtitle {
        color: $light-black-color;
        font-size: 16px;
    }
}

.user-settings-tabs {
    height: 100%;
    position: relative;

    .bp4-tab-list {
        background: #fafafa;
        position: sticky;
        top: 0;
    }

    .bp4-tab-panel {
        padding-bottom: 40px;
    }
}

.user-settings-tabs #bp4-tab-panel_social-settings-tabs_1 {
    height: 100%;
}
