@import "../common/styles";

.container-empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    user-select: none;
    padding: 0 44px;
    .title {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.02em;
        color: $black;
        margin-top: 13px;
        text-align: center;
    }
    .subtitle {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.5);
        margin-top: 3px;
        text-align: center;
    }
}

@include for-size(phone-only) {
    .container-empty {
        .title {
            max-width: 400px;
        }
        .subtitle {
            max-width: 400px;
        }
    }
}

@include for-size(tablet-portrait-up) {
    .container-empty {
        .title {
            max-width: 400px;
        }
        .subtitle {
            max-width: 400px;
        }
    }
}

@include for-size(tablet-landscape-up) {
    .container-empty {
        .title {
            max-width: 550px;
        }
        .subtitle {
            max-width: 550px;
        }
    }
}
