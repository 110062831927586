.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    border-top: 1px solid rgba(0, 0, 0, 0.15);
    height: 49px;
    width: calc(100% + 20px);
    margin: 0 0 -10px -10px;
    position: sticky;
}

.fake-block {
    flex: 1;
}
