@import "../../../common/styles.scss";

.audit-log-settings {
    display: inline-grid;
    .mpd-select-label {
        font-size: 14px;
        font-weight: 600;
    }
}
.audit-log-settings-table-virtuoso table {
    width: 100%;
}

.audit-log-settings-list-container-empty {
    height: 100%;
    justify-content: center;
}

.audit-log-settings-wrapper {
    height: 100%;
    padding: 0;
    height: calc(100% - 100px);
    // overflow: auto;

    .infinite-scroll {
        overflow: auto;
        height: 100%;
    }
}

.audit-log-settings-admin-dropdown-target {

    .mpd-select {
        background: white;
        max-width: 180px;

        .mpd-select-label {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .mpd-select.mpd-active {
        background: #f4f8ff;

        .mpd-select-label {
            color: $primary-color;
        }
    }
}

.audit-log-settings-users-dropdown-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: -6px;

    .image-source-tooltip-wrapper {
        width: 25px !important;
    }
}

.audit-log-settings-users-dropdown {
    .mpd-select {
        padding-left: 4px;
    }

    .mpd-select-options {
        min-height: 100%;
        max-height: unset;
        // background-color: pink;
    }

    .bp4-popover2-target {
        .image-source {
            width: 25px;
            max-height: 25px;

            .image-source-src {
                max-height: 25px;
            }
        }
    }

    .bp4-popover2 {
        min-height: 340px;
        max-height: 380px;
        height: 380px;
        overflow: hidden;
        width: 250px;
        border-radius: 12px;

        .bp4-popover2-content {
            height: 100%;
        }
    }
}

.audit-log-settings-select {
    margin-right: 10px !important;

    .mpd-select {
        width: 120px;
    }

    .mpd-select-options {
        min-height: 212px;
    }
}

.audit-log-settings-select-with-search {
    .mpd-select-options {
        min-height: 265px;
    }
}

.audit-log-settings-alerts-select {
    margin-left: 10px;
}

.audit-log-settings-list {
    height: 100%;
    min-width: 800px;
    // padding: 0 0 30px 0;
    // overflow-x: auto;
    // overflow-y: hidden;
    overflow: hidden;
}

.audit-log-settings-scrolled-data {
    height: 100%;
    margin: 0;
    overflow-y: auto;
}

.audit-log-settings-styled-date-inputs {
    width: 150px;
    max-width: 150px;
}

.audit-log-settings-styled-date-inputs.audit-log-settings-styled-date-inputs:first-child {
    margin-right: 0px;
}

.audit-log-settings-spinner {
    margin: 30px 0 60px;
}

.audit-log-settings-period-dropdown {
    // max-width: 150px;
}

.activities-popover,
.period-popover {
    min-width: 220px;
    min-height: 300px;
    background-color: white;

    .mpd-select-options {
        max-height: unset;
        padding: 10px;
    }
}

.activities-popover {
    .mpd-select-options {
        max-height: 300px;
    }
}

.audit-log-settings-header {
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #fafafa;
    position: sticky;
    top: 0;
    padding: 0 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    z-index: 11;

    .start-end-date-timer {
        display: flex;
        gap: 0 10px;
    }

    .bp4-popover2-target {
        width: max-content;
        margin-right: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 10px;
    }
}

.date-pickers-enter {
    max-width: 0;
    opacity: 0;
}

.date-pickers-enter-active {
    max-width: 300px;
    opacity: 1;
    transition: all 500ms;
}

.date-pickers-exit {
    max-width: 300px;
    opacity: 1;
}

.date-pickers-exit-active {
    max-width: 0;
    opacity: 0;
    transition: all 500ms;
}

@include for-size(phone-only) {
    .audit-log-settings-wrapper {
        padding: 15px 0;
    }

    .audit-log-settings-header {
        flex-wrap: wrap;
    }

    .audit-log-settings-styled-dates-picker {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        gap: 0 10px;
    }

    .audit-log-settings-list {
        min-width: auto;
        padding-top: 5px;
    }
}

.skeleton-block {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-left: 30px;
    margin-bottom: 40px;
    margin-top: 30px;
}

.skeleton {
    background: linear-gradient(90deg);
    animation: scale 4s infinite;
    width: 100%;
    height: 20px;
  }

@keyframes scale {
    0% {transform: scale(0.9)}
    100% {transform: scale(1)}
}
  
.icon-circle {
    background: linear-gradient(90deg);
    animation: scale 4s infinite;
    display: flex;
    min-height: 15px;
    min-width: 15px;
    height: 15px;
    width: 15px;
    max-height: 15px;
    max-width: 15px;
    background-clip: content-box;
    border-radius: 50% !important;
}