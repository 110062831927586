@import "../common/styles.scss";

.#{$ns}-grow-out-appear, .#{$ns}-grow-out-enter {
    transform: scale(0);
    transition: none;
}

.#{$ns}-grow-out-appear-active, .#{$ns}-grow-out-enter-active {
    transition: transform 500ms ease;
    transform: scale(100%);
}

.#{$ns}-grow-out-appear-done, .#{$ns}-grow-out-enter-done {
    transform: scale(100%);
    transition: transform 500ms ease;
}


.#{$ns}-swipe-left-appear, .#{$ns}-swipe-left-enter {
    transform: translateX(100%);
    transition: none;
}

.#{$ns}-swipe-left-appear-active, .#{$ns}-swipe-left-enter-active {
    transform: translateX(0%);
    transition: transform 500ms ease;
}

.#{$ns}-swipe-left-exit-active {
    transform: translateX(-100%);
    transition: transform 500ms ease;
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.#{$ns}-swipe-right-appear, .#{$ns}-swipe-right-enter {
    transform: translateX(-100%);
    transition: none;
}

.#{$ns}-swipe-right-appear-active, .#{$ns}-swipe-right-enter-active {
    transform: translateX(0%);
    transition: transform 500ms ease;
}

.#{$ns}-swipe-right-exit-active {
    transform: translateX(100%); 
    transition: transform 500ms ease;
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}