@import "../../common/styles";

.root {
    display: flex;
    flex-direction: column;
    row-gap: 0;

    .see-all-btn :global(.bp4-button-text .mpd-button-text) {
        text-align: right;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: #000000;
    }

    :global(.empty-block) {
        margin-top: 60px;
        min-height: 130px;

        :global(.emty-block-icon) {
            width: 67px;
            height: 67px;
        }

        :global(.empty-block-title) {
            font-size: 14px;
        }

        :global(.empty-block-subtitle) {
            font-size: 12px;
        }
    }
}
