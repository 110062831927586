@import "../common/styles";

.tab-with-validation-error:not(.bp4-tab[aria-selected="true"]) {
    color: #db2e45 !important;
}

.styled-tabs {
    position: relative;
    .bp4-tab-list {
        display: flex;
        .bp4-tab {
            user-select: none;
            padding-top: 12px;
            padding-bottom: 12px;
            border-width: 0px 0px 1px 0px !important;
            -webkit-tap-highlight-color: transparent !important;
            &.mpd-disabled {
                background-color: rgba(0, 0, 0, 0.2) !important;
                color: white !important;
                // cursor: default;
            }
        }
        .bp4-tab[aria-selected="true"] {
            padding-bottom: 10px;
            border-width: 0px 0px 3px 0px !important;
        }
        .bp4-tab-indicator-wrapper {
            display: none;
        }
    }

    &.rounded {
        hr {
            display: none;
        }
        .bp4-tab-list {
            border-bottom: unset !important;
            .bp4-tab {
                flex: 1;
                text-align: center;
                color: $lighter-black-color;
                font-family: $font-family;
                font-size: 14px;
                font-weight: 700;
                padding: 2px;
                outline: none;
                margin: 0;
                border-style: solid;
                border-width: 1px !important;
                border-color: $border-color;
                border-right-color: transparent;
                &.next-tab {
                    border-left-color: transparent;
                }
                &.previous-tab {
                    border-right-color: transparent;
                }
            }
            // .bp4-tab:nth-last-child(2) {
            //     border-right-color: $border-color;
            // }
            .bp4-tab[aria-selected="true"] {
                border-color: black;
                padding: 2px;
            }
            .bp4-tab:last-child {
                border-radius: 0 100px 100px 0;
                border-right-color: $border-color;
            }
            .bp4-tab:nth-child(2) {
                border-radius: 100px 0 0 100px !important;
            }
        }
    }

    &.primary-reversed {
        .bp4-tab-list {
            overflow: none;
            min-width: 400px;
            transform: translateZ(0);
            z-index: 1;
            opacity: 1;

            .bp4-tab {
                user-select: none;
                text-transform: uppercase;
                color: rgba(0, 0, 0, 0.5);
                opacity: 0.75;
                flex: 1;
                margin: 0;
                font-size: 12px;
                font-family: $font-family;
                font-weight: 700;
                text-align: center;
                outline: none;
                border-bottom-width: 0px !important;
                border-top-width: 3px !important;
                border-style: solid;
                border-color: $border-color;
                padding-top: 5px !important;
                &:last-child {
                    padding-right: 10px;
                    text-indent: 10px;
                }
            }
            .bp4-tab:not([aria-disabled="true"]):hover:not([aria-selected="true"]) {
                color: $primary-color;
            }
            .bp4-tab[aria-selected="true"] {
                opacity: 1;
                color: black;
                padding-bottom: 12px !important;
                border-color: $primary-color;
            }
            // .bp4-tab[aria-selected="true"].tab-with-dot:before {
            //     display: inline-block;
            //     content: "";
            // }
            .bp4-tab-indicator-wrapper {
                display: none;
            }
        }
    }

    &.primary {
        .bp4-tab-list {
            overflow: none;
            min-width: 400px;
            transform: translateZ(0);
            z-index: 1;
            opacity: 1;
            margin-top: -1px;
            .bp4-tab {
                user-select: none;
                text-transform: uppercase;
                color: rgba(0, 0, 0, 0.5);
                opacity: 0.75;
                flex: 1;
                margin: 0;
                font-size: 12px;
                font-family: $font-family;
                font-weight: 700;
                text-align: center;
                outline: none;
                border-style: solid;
                border-color: $border-color;
                &:last-child {
                    padding-right: 10px;
                    text-indent: 10px;
                }
            }
            .bp4-tab:not([aria-disabled="true"]):hover:not([aria-selected="true"]) {
                color: $primary-color;
            }
            .bp4-tab[aria-selected="true"] {
                opacity: 1;
                color: black;
                border-color: $primary-color;
            }

            .bp4-tab[aria-selected="true"].tab-with-dot:before {
                display: inline-block;
                content: "";
            }
        }
    }

    &.free-blue {
        hr:not(.start-end-timer-seperator) {
            position: absolute;
            width: 100%;
            padding: 0;
            margin: 0;
            z-index: -1;
            border-bottom: solid 0px rgba(0, 0, 0, 0.15);
            border-width: 0px 0px 1px 0px;
            display: initial;
        }
        .bp4-tab-list {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            overflow: none;
            min-width: 400px;
            transform: translateZ(0);
            z-index: 1;
            opacity: 1;
            margin-top: -1px;
            .tab-underlined-with-yellow.tab-with-dot:before {
                background-color: #ffcd00;
            }
            .tab-with-dot:before {
                display: inline-block;
                content: "";
                background-color: $primary-color;
                min-width: 6px;
                min-height: 6px;
                max-width: 6px;
                max-height: 6px;
                border-radius: 100%;
                display: inline-block;
                position: absolute;
                top: 24px;
                left: 10px;
            }
            .bp4-tab {
                user-select: none;
                text-transform: uppercase;
                color: rgba(0, 0, 0, 0.5);
                opacity: 0.75;
                margin: 0;
                font-size: 12px;
                font-family: $font-family;
                font-weight: 700;
                text-align: center;
                outline: none;
                border-style: solid;
                border-color: transparent;
                &:last-child {
                    // padding-right: 10px;
                    text-indent: 10px;
                }
            }
            .bp4-tab:not([aria-disabled="true"]):hover:not([aria-selected="true"]) {
                color: $primary-color;
            }
            .bp4-tab[aria-selected="true"] {
                opacity: 1;
                color: black;
                border-color: $primary-color;
            }
            .bp4-tab-indicator-wrapper {
                display: none;
            }

            .bp4-tab[aria-selected="true"].tab-with-dot:before {
                display: inline-block;
                content: "";
            }
            .bp4-tab[aria-selected="true"].tab-underlined-with-yellow {
                border-color: #ffcd00;
            }
        }
    }

    &.free-blue-reversed {
        .bp4-tab-list {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            overflow: none;
            min-width: 400px;
            transform: translateZ(0);
            z-index: 1;
            opacity: 1;
            hr {
                position: absolute;
                width: 100%;
                padding: 0;
                margin: 0;
                z-index: -1;
                border-top: solid 0px rgba(0, 0, 0, 0.15);
                border-width: 1px 0px 0px 0px;
                display: initial;
                top: 0px;
            }
            .bp4-tab {
                user-select: none;
                text-transform: uppercase;
                color: rgba(0, 0, 0, 0.5);
                opacity: 0.75;
                flex: 1;
                margin: 0 !important;
                font-size: 12px;
                font-family: $font-family;
                font-weight: 700;
                text-align: center;
                outline: none;
                border-bottom-width: 0px !important;
                border-top-width: 0px !important;
                border-style: solid;
                border-color: $border-color;
                margin-top: 2px;
                padding-top: 5px !important;
                &:last-child {
                    padding-right: 10px;
                    text-indent: 10px;
                }
            }
            .bp4-tab:not([aria-disabled="true"]):hover:not([aria-selected="true"]) {
                color: $primary-color;
            }
            .bp4-tab[aria-selected="true"] {
                opacity: 1;
                color: black;
                padding-bottom: 12px !important;
                border-color: $primary-color;
                border-top-width: 3px !important;
            }

            .bp4-tab[aria-selected="true"].tab-with-dot:before {
                display: inline-block;
                content: "";
            }
        }
    }

    &.rectangle {
        hr {
            display: none;
        }
        .bp4-tab-list {
            .bp4-tab {
                flex: 1;
                text-align: center;
                color: $lighter-black-color;
                font-family: $font-family;
                font-size: 14px;
                font-weight: 700;
                padding: 2px;
                outline: none;
                margin: 0;
                border-style: solid;
                border-width: 1px !important;
                border-color: $border-color;
                border-right-color: transparent;
                &.next-tab {
                    border-left-color: transparent;
                }
                &.previous-tab {
                    border-right-color: transparent;
                }
            }
            .bp4-tab:nth-last-child(2) {
                border-right-color: $border-color;
            }
            .bp4-tab[aria-selected="true"] {
                border-color: black;
                padding: 2px;
            }
        }
    }
}

@include for-size(phone-only) {
    .styled-tabs {
        &.padding {
            .bp4-tab-list {
                min-width: unset;
                .bp4-tab {
                    padding-right: $small-padding;
                    padding-left: $small-padding;
                }
            }
        }
    }
}

@include for-size(tablet-portrait-up) {
    .styled-tabs {
        &.padding {
            .bp4-tab-list {
                .bp4-tab {
                    padding-right: $medium-padding;
                    padding-left: $medium-padding;
                }
            }
        }
    }
}

@include for-size(tablet-landscape-up) {
    .styled-tabs {
        &.padding {
            .bp4-tab-list {
                .bp4-tab {
                    padding-right: $large-padding;
                    padding-left: $large-padding;
                }
            }
        }
    }
}
