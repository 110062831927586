@import "../common/styles.scss";

.left-panel-container {
  display: flex;
  // flex: 1;
  flex-direction: column;
  // max-width: 400px;
  // min-width: 400px;
}

.left-panel-container-wrapper {
    // flex: 1;
    // flex-shrink: 0;
    transition: min-width 0.3s;
    // width: 0;
    background: white;
    &.mpd-collapsed {
      min-width: 0px;
    }
    &.mpd-loading {
      opacity: 0.5;
      pointer-events: none;
    }
}

.left-panel-overlay {
    display: none;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    z-index: 999;
    &.mpd-active {
      display: initial;
    }
}

@include for-size(phone-only) {
  .left-panel-container-wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100%;
    min-width: initial !important;
    position: fixed !important;
    transition: transform 0.15s ease;
    &.sidebar-mpd-active {
      transform: translateX(260px);
      transition: transform 0.15s ease;
    }
  }
  .left-panel-container {
    min-width: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    &.sidebar-mpd-active {
      left: 260px;
    }
  } 
}