@import "../../../../common/styles";

.teams-settings-edit {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.teams-settings-edit-empty {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.teams-settings-edit-alert-inner-container {
    padding-bottom: 40px;
}


.teams-settings-edit-alert-footer {
    display: none;
}

.teams-settings-edit-alert-team-members {
    flex: 1;
}

.teams-settings-edit-alert-title {
    color: black;
    font-size: 19px;
    font-weight: 700;
    text-align: center;
    user-select: none;
}

.teams-settings-edit-alert {
    min-width: initial !important;
    width: 500px !important;
    max-width: initial !important;
    border-radius: 20px !important;
    .bp4-alert-footer {
        display: none;
    }
}

.teams-settings-edit-alert-subtitle {
    color: $primary-color;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    user-select: none;
}

.teams-settings-edit-alert-top {
    position: relative;
    .mpd-icon {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
    }
}