@import "../common/styles";

.shadowed-setting-list-item.not-scale-on-hover {
    &:hover {
        transform: scale(1);
    }
}

.processing {
    opacity: 0.4;
}

.shadowed-setting-list-item {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.02), 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    transition: transform 0.3s ease;
    // padding: 20px;
    flex-direction: column;

    &:hover {
        transform: scale(1.01);
        cursor: pointer;
    }

    &-left-icon {
        max-height: 48px;
        max-width: 48px;
        height: 48px;
        width: 48px;

        path {
            // stroke: $primary-color;
        }
    }

    &-top-wrapper {
        display: flex;
        align-items: center;
        // align-items: center;
        column-gap: 20px;
        padding: 20px;
        width: 100%;
    }

    &-toggle-wrapper {
        display: flex;
        align-items: center;
        max-height: 56px;
    }

    &-left-wrapper {
        display: flex;
        flex: 1;
        flex-wrap: nowrap;
        column-gap: 20px;
        row-gap: 20px;
        align-items: center;
    }

    &-left-icon {
        min-width: 48px;
    }

    &-title-subtitle-wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        row-gap: 7px;
    }

    &-right-arrow {
        transform: rotateZ(-90deg);
        transition: transform 0.3s;
    }

    &-right-arrow.opened {
        transform: rotateZ(720deg);
    }

    &-collapse-padding-wrapper {
        padding: 32px 20px 20px 88px;
    }

    &-disable-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0.5;
        border-radius: 12px;
        z-index: 2;
    }
}

.shadowed-setting-list-item.active {
    border: 2px solid #1f76ff;
}
