@import "../common/styles";

.activity-tooltip-title {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    margin: 0 0 10px 14px;
}

.activity-tooltip-item {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    position: relative;
    margin-left: 20px;

    &:first-of-type {
        &:before {
            background-color: #004ac7;
        }
    }

    &:last-of-type {
        &:before {
            background-color: $primary-color;
        }
    }

    &:before {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        border-radius: 100%;
        left: -15px;
        top: 5px;
    }
}
