@import "../common/styles.scss";

.styled-alert {
  width: 600px !important;

  &.bp4-alert {
    padding: 0;
  }
  &.primary {
    width: initial;
    max-width: initial;
    display: flex;
    flex-direction: column;
    // padding: 20px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 12px 14px rgba(0, 0, 0, 0.1), 0 -1px 5px rgba(0, 0, 0, 0.02);
    .bp4-alert-contents {
      width: 100%;
      height: 100%;
      padding: 0;
    }
    .bp4-alert-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .bp4-alert-footer {
      display: none;
    }
    .styled-alert-footer {
      display: flex;
      justify-content: flex-end;
      padding: 20px 0;
      align-items: center;
      margin: 20px 20px 0 20px;
    }

    &-cancel,
    &-confirm {
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;
    }

    .styled-alert-cancel {
      margin-right: 10px;
      .mpd-button-text {
        color: #000000;
      }
    }
    .styled-alert-confirm {
      .mpd-button-text {
        color: white;
      }
    }
  }

  &-global-content-top {
    padding: 0 20px;
  }

  // &-global-content {
  //   padding: 20px 20px 0 20px;
  // }

  &-global-content-close.mpd-button.bp4-button.only-icon {
    min-width: unset;
    min-height: unset;
    width: 24px !important;
    height: 24px !important;

    .bp4-button-text {
      max-width: 15px;
      max-height: 15px;

      .mpd-icon {
        width: 15px;
        height: 15px;
      }
    }
  }
}

// @include for-size(phone-only) {
//   .styled-alert {
//     .styled-alert_fullscreen .styled-alert-global-content {
//       padding-top: 164px;
//     }
//   }
// }

@include for-size(phone-only) {
  
}
