@import "../common/styles";

.push-notification {
    padding: 20px;
    cursor: pointer;

    .event-icon {
        max-width: 22px;
        max-height: 22px;
        flex-shrink: 0;
    }
    &-more-dropdown-wrapper {
        position: absolute;
        top: 20px;
        right: 20px;

        :global(.bp4-popover2-transition-container) {
            min-width: 200px;
        }
    }

    &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @include notification;
}
