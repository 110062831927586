@import "../common/styles.scss";
@import "../common/styles.scss";

.#{$ns}-floating-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  outline: none !important;

  input {
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
    outline: none;
    background: none;
    padding: 0;
    font-size: 11px;
    font-weight: 600;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    transition: 0.5s ease-in-out all;
  }

  &.mpd-active {
    .#{$ns}-floating-input-label {
      // top: -10px;
    }
  }
}
.#{$ns}-floating-input-counter {
  text-align: right;
  font-size: 12px;
  font-weight: 400;
}

.#{$ns}-floating-input-label {
  // position: absolute;
  margin-bottom: 10px;
  pointer-events: none;
  transform: translateZ(0); // input disappears on some ios devices
  font-size: 9px;
  // z-index: -1;
  font-weight: 600;
  top: 3px;
  transition: 0.2s ease all;
  white-space: nowrap;
}

.#{$ns}-floating-input-fixed-placeholder {
  position: absolute;
  top: 6px;
  font-size: 16px;
  font-weight: 600;
  pointer-events: none;
  user-select: none;
}

.#{$ns}-floating-input-invisible {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  user-select: none;
}

.input-left-icon {
  position: absolute;
  top: 11px;
  left: 10px;
  height: 32px;
  width: 32px;
}

input.input_with-left-icon {
  padding-left: 52px !important;
}
