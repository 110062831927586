@import "../../../../../../../common/styles";

.sms-channel-create {
    padding: 0px 20px 50px 0;
    display: grid;
    grid-template-columns: minmax(200px, 200px) minmax(320px, 560px);
    align-self: stretch;
    justify-content: center;
    column-gap: 26px;
    height: 100%;
    position: relative;

    &-stepper-wrapper {
        margin-top: 65px;
    }

    &-tr-group {
        overflow: auto;
        padding-bottom: 20px;
    }

    &-stepper-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: #000000;
        margin-bottom: 30px;
    }

    &-step-title {
        margin-top: 0;
        font-family: $font-family;
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 18px;
        color: #000000;
        margin-bottom: 35px;
    }

    &-route-wrapper {
        // overflow: auto;
        margin-top: 65px;
        :global(.alert-replies-settings-wrapper) {
            display: flex;
            flex-direction: column;
            row-gap: 35px;
        }

        :global(.styled-floating-phone-input) {
            margin-bottom: 35px;
        }

        :global(.step-inputs-wrapper) {
            display: flex;
            flex-direction: column;
            row-gap: 35px;
        }

        :global(.styled-floating-input) {
            margin-top: 35px !important;
        }
    }

    &-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding: 0 20px;
        background-color: white;
        z-index: 10;
    }
}
