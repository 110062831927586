@import "../common/styles.scss";

.styled-floating-phone-input {
  .custom-phone-number-select {
    height: 54px;
    border: 1px solid rgba(34, 36, 45, 0.12);
    background: #fafafa;
    border-radius: 12px;
    padding: 0 16px;

    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;

    color: #293136;
  }
  .mpd-floating-phone-input-counter {
    color: $light-black-color;
    font-family: $font-family;
  }

  .mpd-floating-phone-input-fixed-placeholder {
    color: $border-dark-color;
    font-family: $font-family;
  }

  &.primary.mpd-floating-phone-input-container {
    padding-top: 28px;

    .primary-main-target:focus-within {
      background-color: #f4f8ff;
    }
  }

  &.primary.mpd-floating-phone-input-container.mpd-disabled {
    opacity: 0.5;
  }

  &.primary {
    .error {
      &.mpd-active {
        color: $off-color;
        font-family: $font-family;
        font-size: 11px;
        font-weight: 600;
      }
    }

    .PhoneInputCountry {
      display: flex;
      // padding: 0 17px;
      margin: 0;
      // background: $off-white-color;
      // border: 1.5px solid $form-border-color;
      // border-radius: 12px;
      transition: 0.5s ease-in-out all;

      box-shadow: none;
      -webkit-appearance: none;

      // &:hover {
      //   border-color: $primary-color;
      // }
    }

    .PhoneInputCountry:focus-within {
      border-color: $primary-color;
      // background-color: #f4f8ff;
    }

    .PhoneInputCountrySelect {
      outline: none;
    }

    .PhoneInput--focus + .mpd-floating-phone-input-label {
      color: $primary-color;
    }

    .mpd-styled-floating-phone-input {
      margin: 0px !important;
      .mpd-active {
        input {
          border-color: $primary-color;
        }
        .mpd-floating-input-label {
          color: $primary-color;
        }
      }
    }
    .mpd-floating-phone-input-label {
      top: 0;
      font-family: $font-family;
      font-size: 12px !important;
      line-height: 14px;
      font-weight: 600;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $form-lable-color;
      position: absolute;
    }

    .PhoneInputInput {
      // background: $off-white-color;
      border: unset;
      // border: 1.5px solid $form-border-color;
      // border-radius: 12px;
      transition: 0.5s ease-in-out all;
      box-sizing: border-box;

      font-family: $font-family;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      letter-spacing: 0.04em;
      color: $form-text-color;

      box-shadow: none;
      -webkit-appearance: none;
    }

    input:focus ~ .mpd-floating-phone-input-label,
    input:not(:focus):valid ~ .mpd-floating-phone-input-label {
      color: $primary-color;
      font-size: 12px;
      font-weight: 600;
    }
    input {
      border-color: $border-color;
      font-size: 16px;
      color: $light-black-color;

      // &:hover {
      //   border-color: $primary-color;
      // }
    }
    .mpd-floating-phone-input-invisible {
      font-size: 16px;
    }
    input:focus {
      // border-color: $primary-color;
      // background-color: #f4f8ff;
    }
  }

  &.mpd-error {
    .PhoneInput {
      border-color: $form-error-color;
      background-color: rgba(255, 118, 117, 0.15);
    }

    // .PhoneInputInput {
    //   background-color: rgba(255, 118, 117, 0.15);
    // }

    .error.mpd-active {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      // letter-spacing: 0.02em;
      margin-top: 5px;
      position: absolute;
      top: 100%;
    }

    .primary-main-target {
      border-color: #DB2E45;
    }

    .mpd-floating-phone-input-label,
    .error.mpd-active {
      color: #DB2E45;
    }
  }

  .PhoneInputCountrySelectArrow {
    border-width: 0 1px 1px 0;
    border-style: solid;
  }
}
.add-new-quick-action {
  .styled-floating-input {
    .PhoneInput--focus {
      span {
        color: $primary-color;
      }
    }
  }
}
