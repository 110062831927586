@import "../common/styles.scss";

.list-header-container {
    display: flex;
    align-items: center;
    background-color: $off-white-color;
    height: 50px;
    border-style: solid;
    border-color: $border-color;
    border-width: 0px 0px 1px 0px;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 10px 15px;
    z-index: 10;
    .mpd-left {
        display: flex;
        align-items: center;
        .select-sort:last-child {
            margin-left: 10px;
        }
    }
    .mpd-right {
        display: flex;
        align-items: center;
    }
    .selection-select {
        margin-right: 10px;
    }
    .search-input {
        margin-left: 10px;
        border-radius: 100px;
        max-width: 230px;
        input {
            line-height: 130%;
        }
    }
    .styled-select {
        margin-right: 10px;
        cursor: pointer;
        .bp4-popover2-wrapper {
            span {
                margin-right: 10px;
            }
        }
    }
}
