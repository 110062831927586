@import "../common/styles";

.start-end-timer {
    align-items: center;
    flex: initial !important;
    .mpd-select-label {
        margin-right: 12px
    }
    .mpd-select {
        min-width: 140px;
    }

    .styled-select:first-child {
        margin-right: 20px;
    }
}

.start-end-timer {
    .mpd-select:hover, .mpd-select:active {
        use {
            stroke: $primary-color;
        }
    }
}

.start-end-timer-seperator {
    padding: 0 8px;
    margin-left: 0;
    margin-right: 20px !important;
    color: black;
    border: solid 1px black;
    border-width: 0px 0px 1px 0px;
    height: 2px;
}