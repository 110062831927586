@import "../../../common/styles.scss";

.organization-settings-scroll-container {
    flex: 1;
}

.organization-settings {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.organization-settings-jurisdiction {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.account-details-scroll-container {
    height: 100%;
}

.account-details-department-name {
    text-transform: capitalize;
}

.account-details-map-address-info-item {
    .account-settings-info-item-text {
        max-width: 180px;
    }
}

.account-details-logo-wrapper {
    display: flex;
    align-items: center;
}

.account-details-details-info {
    .account-details-details-info-item-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .mpd-select {
        border-color: rgba(0, 0, 0, 0.15) !important;
    }
}

.account-details-styled-select {
    .mpd-select-label {
        flex: 1;
    }
}

.account-details {
    padding: 40px;
}

@include for-size(phone-only) {
    .account-details {
        flex-direction: column;
    }
}
