@import "../../components/common/styles";

.dialog-stepper {
    :global(.mpd-button .mpd-icon path) {
        stroke: white;
    }
}

.root {
    width: 100vw !important;
    height: 100vh;
    margin: 0 !important;
    opacity: 1;
    display: flex;
    justify-content: center;

    .header {
        display: flex;
        justify-content: space-between;
        height: 80px;
        align-items: center;
        padding: 0 30px;

        :global(.stepper) {
            flex: 5;
            max-width: 730px;
            padding: 0;
        }

        &__title {
            display: flex;
            flex: 1;
            font-family: $font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #293136;
        }

        &__title_steps {
            justify-content: flex-end;
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        height: 70px;
        background-color: #fafafa;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        align-items: center;
        padding: 0 20px;
        column-gap: 30px;

        &__left-wrapper {
            display: flex;
            align-items: center;
            column-gap: 20px;
        }
    }

    .step-panel {
        display: flex;
        flex: 1;
        flex-direction: column;

        width: 100%;
        align-items: center;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 100px 1px 0 1px;

        &__title {
            font-family: $font-family;
            font-style: normal;
            font-weight: 800;
            font-size: 26px;
            line-height: 18px;
            text-align: center;
            color: #000000;
            margin: 0 0 16px 0;
            max-width: 700px;
        }

        &__subtitle {
            font-family: $font-family;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;

            text-align: center;

            color: rgba(0, 0, 0, 0.5);
            margin: 0 0 50px 0;
            max-width: 700px;
        }
    }
}
