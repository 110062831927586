@import "../../../common/styles.scss";

.team-settings-empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
        font-size: 22px;
    }
    .subtitle {
        color: $light-black-color;
        font-size: 16px;
    }
}

.team-settings, .team-settings-inner-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.team-settings-list-container-empty {
    height: 100%;
    flex: 1;
    justify-content: center;
    .title {
        font-size: 22px;
    }
    .subtitle {
        width: 435px;
    }
}