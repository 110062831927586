@import "../../../../common/styles.scss";

.settings-data-export-settings-list-item-container:hover {
    background: rgba(0,0,0,0.01);
}

.settings-data-export-settings-list-item-container {
    display: flex;
    justify-content: space-between;
    padding: 15px 40px;
    border-bottom: solid 1px $border-color;
    user-select: none;
}

.settings-data-export-settings-list-item-inner-container {
    display: flex;
    align-items: center;
    width: 600px;
}

.settings-data-export-settings-list-item-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.settings-data-export-settings-list-item {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 120px;
    color: $light-black-color;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    span {
        font-family: $font-family;
        font-size: 14px;
        font-weight: 600;
        color: $light-black-color;
        padding-right: 5px;
        text-align: start;
    }
    &.export {
        color: $primary-color;
        .date-time {
            color: $lighter-black-color;
            font-family: $font-family;
            font-size: 12px;
            font-weight: 400;
            padding: 3px 0;
        }
    }
    &.exported-by {
        color: $primary-color;
    }
}   