@import "../common/styles";

.root {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    width: 100%;
    // transition: row-gap 0.3s;
}

.root.root_expanded {
    row-gap: 0;
}

.user-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 0px;
    width: 100%;
}

.avatar {
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin-bottom: 17px;
    transition:
        width 0.5s,
        height 0.5s;

    :global(.image-source-initials) {
        transition: font-size 0.5s;
        font-size: 16px;
    }
}

.avatar_expanded {
    width: 70px;
    height: 70px;

    :global(.image-source-initials) {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 35px;
        text-align: center;
        letter-spacing: 0.1em;
        text-indent: 0.1em;
        color: #ffffff;
        transition: font-size 0.5s;
    }
}

.avatar-status-dot {
    border: 2px solid white !important;
    transition: all 0.3s;
}

.user-block-name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    color: $black;
    // opacity: 0;
    // transition: all 0.3s ease-out;
    white-space: nowrap;
    // height: 0;
    position: relative;

    :global(.bp4-popover2-target) {
        width: 32px;
    }
}

.chart-wrapper {
    margin-top: 20px;
}

.star-button-tooltip-wrapper {
    position: absolute;
    top: -4px;
}

.star-button {
    padding: 0 !important;
    :global(svg.mpd-icon) {
        path {
            stroke: rgba(0, 0, 0, 0.15);
        }
    }

    &:hover {
        :global(svg.mpd-icon) {
            path {
                stroke: $primary-color;
            }
        }
    }
}

.star-button.active {
    :global(svg.mpd-icon) {
        path {
            fill: #ffd15b;
            stroke: #ffd15b;
        }
    }
}

.contact-details {
    // display: flex;
    // flex-direction: column;
    // margin-top: 15px;
    // align-items: center;
    // padding: 20px 0;
    width: 100%;
}

.contact-details_not-expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.collapse-content-wrapper {
    padding: 20px 0 0 0;
}

.tags-wrapper {
    position: relative;
    display: flex;
    column-gap: 10px;
    max-width: 100%;
    flex-wrap: wrap;
    row-gap: 10px;
    overflow: hidden;
    align-items: flex-start;
    // border-top: 15px solid transparent;
    padding: 15px 0;
}

.contact-details-item {
    margin-bottom: 18px;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 15px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.contact-details-item-icon-wrapper {
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.contact-details-item-label {
    // opacity: 0;
    // transition: opacity 0.3s;
    // display: none;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.75);
    display: flex;
    opacity: 1;

    &:hover {
        text-decoration: none;
        .contact-details-item-label {
            color: $primary-color;
        }
    }
}

.contact-details-link {
    opacity: 0;
    transition: all 0.3s;
    display: none;
    width: 0;

    &:hover {
        text-decoration: none !important;
    }
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #d5d5d5;
}
