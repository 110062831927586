.devider {
    width: 100%;
    height: 1px;
    background-color: #d5d5d5;
    min-height: 1px;
}

.block-label {
    width: 100%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #293136;
    display: flex;
    justify-content: flex-start;
    padding: 0px 0px 18px 0;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    // position: sticky;
    background-color: #fafafa;
    justify-content: space-between;
    cursor: pointer;
    // bottom: 0;
}

.arrow {
    transition: transform 0.2s;
    transform: rotate(540deg);
}

.arrow.arrow_active {
    transform: rotate(0deg);
}
