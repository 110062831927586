@import "../common/styles";

.admin-plus {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1px solid #ffffff;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 10px;
    color: rgba(0, 0, 0, 0.75);
}

.single-select-value-wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
    max-width: max-content;
}
