@import "../../../../common/styles";

.roles-settings-add {
    width: 50%;

    &-role-name-input {
        display: flex;
    }
    &-main-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &-footer {
        background: #fafafa;
        display: flex;
        width: 100%;
        min-height: 70px;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        position: sticky;
        z-index: 1;
        bottom: 0;
        column-gap: 20px;
        justify-content: flex-end;
        align-items: center;
        padding: 0 20px;
    }
}

@include for-size(tablet-portrait-up) {
    .roles-settings-add {
        width: initial;
    }
}

@include for-size(phone-only) {
    .roles-settings-add {
        width: initial;
    }
}
