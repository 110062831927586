@import "../common/styles.scss";


.simple-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

}

.simple-list-filtered-list {
}

.generic-list-empty {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .mpd-icon {
        width: 60px;
        height: 60px;
        path {
            stroke: $primary-color;
        }
    }
}

.simple-list-list-header-styled-select {
    .bp4-popover2-wrapper {
        .bp4-popover2-target {
            margin: 0 !important;
            padding: 0;
        }
    }
}

.simple-list-map-contain-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    .styled-map{
        height: 100%;
    }

    .wrapper {
        background-color: white;
        padding-top: 15px;
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    .bp4-popover2-enter-done {
        .simple-list-list-header-styled-select-popover {
            transform: translateY(175px);
        }
    }
}
