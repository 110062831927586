@import "../../components/common/styles";

input,
textarea {
    border: unset;
    outline: none;
    resize: none;
}

.edit.active {
    .placeholder {
        top: 0px;
    }
}

.root {
    display: flex;
    align-items: center;
    max-width: 100%;
    column-gap: 15px;
    min-width: 0;
}

.input-wrapper.input-wrapper_without-title.edit {
    padding-top: 0px;
    height: 32px;

    input,
    textarea {
        align-self: center;
    }
}

.edit {
    // height: 30px;
    width: 100%;
    min-width: 0;
    height: 41px;

    border-bottom: 1px solid transparent;
    display: flex;
    align-items: center;
    // padding-top: 10px;
    justify-content: flex-start;
    // background-color: pink;
    position: relative;
    column-gap: 15px;
    // background-color: red;
    // margin-bottom: 20px;
    // max-width: calc(100% - 45px);

    transition: border-color 0.3s;

    // input {
    //     background-color: red;
    // }

    :global(.mpd-icon) {
        flex-shrink: 0;
    }

    &:focus-within,
    &:hover {
        border-bottom: 1px solid #1f76ff;
    }

    &:hover {
        .actons-wrapper {
            display: flex;
            align-items: center;
            column-gap: 5px;
            max-height: 32px;
            align-self: flex-start;
            position: relative;
            z-index: 5;
            // top: -20px;
        }
    }

    &:focus-within {
        .actons-wrapper {
            display: none;
        }
    }

    .placeholder {
        display: block;
        position: absolute;
        top: 15px;
        left: 0;

        transition: top 0.3s;

        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: 0.1em;
        color: #7f8896;
        text-transform: uppercase;
    }

    input,
    textarea {
        background-color: transparent;
        margin-top: 6px;
        height: 19px;
        margin-bottom: 3px;
        align-self: flex-end;

        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #293136;
        flex-shrink: 1;
        overflow: hidden;
        max-width: 100%;
        flex: 1;

        margin-left: -2px;
    }

    .actons-wrapper {
        // position: absolute;
        // top: 4px;
        // right: 0;
        display: none;
        // display: flex;
        max-height: 100%;
        justify-self: flex-end;
    }

    // .actons-wrapper {
    //     display: flex;
    //     align-items: center;
    //     column-gap: 5px;
    //     max-height: 19px;
    // }
}
