@import "../common/styles";

.settings-left-panel-title {
    color: black;
    font-family: $font-family;
    font-size: 21px;
    font-weight: 700;
    // min-width: 250px;
    margin-top: 3px;
    user-select: none;
}

.settings-left-panel-header {
    padding: 10px 20px;
    height: 50px;
    border-bottom: 1px solid $border-color;
    .mpd-button {
        flex: initial;
        margin-top: 1px;
        display: none;
    }
}

.settings-left-panel {
    width: 0;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (max-width: 1200px) {
        min-width: 180px !important;
    }
}

.settings-left-panel-scroll-container {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    height: calc(100vh - 50px);

    @media screen and (max-width: 1200px) {
        min-width: 180px !important;
    }
}

@include for-size(phone-only) {
    .settings-left-panel-header {
        display: flex;
        z-index: 1;
        width: 100%;
        background: white;
        .mpd-button {
            display: initial;
        }
    }
    .settings-left-panel-children-container {
        margin-top: 50px;
    }
    .settings-left-panel-title {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        z-index: -1;
    }
}
