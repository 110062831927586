@import "../../../../../components/common/styles";

.second-line-block {
    column-gap: 20px;
    row-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    :global(.search-input) {
        flex: 1;
        max-width: 230px;
        min-width: 230px;
    }
}

.counter {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    // height: 100%;
    display: flex;
    align-items: center;
    color: #000000;
    display: flex;
    column-gap: 10px;
    white-space: nowrap;
    flex: 2;
}

.counter-value {
    padding-right: 10px;
}

.sort-wrapper {
    display: flex;
    column-gap: 10px;
    margin-left: auto;
}

.contacts-settings-dropdown-wrapper {
    position: relative;

    .spinner-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.8);
    }
}
