@import '../../../../common/styles';

.spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
}
.snippets-settings {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &-content {
        width: 100%;
        height: 100%;
        overflow: hidden;

        :global(.emty-block-icon) {
            width: 54px;
            height: 58px;
            margin-bottom: 8px;
        }
    }

    &-snippet-items-wrapper {
        height: 100%;
        overflow: auto;
        box-sizing: border-box;
        padding: 40px;

        :last-child {
            margin-bottom: 0 !important;
        }
    }
}

@include for-size(phone-only) {
    .snippets-settings {
        &-snippet-items-wrapper {
            padding: 10px;
        }
    }
}
