@import "../../../../../components/common/styles";

.root {
    width: 320px;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
    overflow-y: auto;
    background-color: #fafafa;
    z-index: 21;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;

    .actions-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 20px;
        margin: 10px 0 20px 0;
        z-index: 5;
        position: relative;
    }

    button.view-record-button:global(.styled-button) {
        border-radius: 100px 0 0 100px;
        padding: 10px;
        border-right-color: transparent !important;

        &:hover {
            border-right-color: $primary-color;
        }
    }

    .neighbour-hovered {
        border-left-color: transparent !important;
    }

    .content {
        padding: 0 20px;
    }

    .prev-convos {
        height: 350px;
    }

    .header {
        height: 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        width: 320px;

        position: sticky;
        top: 0;
        background: white;
        z-index: 10;

        &__user-name {
            font-family: $font-family;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;

            color: #000000;
        }

        &__close-button :global(.mpd-icon) {
            width: 12px;
            height: 12px;
        }
    }
}
