.main-error-fallback-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: rgba(255, 31, 112, 0.2);
    width: 100%;

    .title {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 38px;
        color: #ff1f70;
        margin-bottom: 20px;
    }
}

.error-info-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    box-shadow: 0px -1px 5px rgb(0 0 0 / 2%), 0px 2px 6px rgb(0 0 0 / 10%);
    border-radius: 12px;
    overflow: hidden;
    padding: 20px;
    background-color: #290000;
    margin-bottom: 15px;
    overflow: auto;

    p {
        color: #f77c7c;
        margin: 0px;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        font-family: "Open Sans";
        color: #ff1f70;

        .route {
            color: white;
            text-decoration: underline;
        }
    }
}

// .send-report-btn-wrapper {
//     display: flex;
//     justify-content: flex-end;
//     width: 100%;
// }
