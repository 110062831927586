@import "../common/styles";

.target-wrapper {
    width: max-content;
    height: max-content;
    // background-color: orange;
}

.tooltip {
    padding: 12px 16px;
}

.portal {
    z-index: 21;
}

.error-tooltip {
    width: max-content;
    :global(.bp4-popover2-content) {
        background-color: #ffd4e1;
        color: $form-error-color !important;
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }
}

.primary-tooltip {
    :global(.bp4-popover2-content) {
        padding: 12px 16px;
        background: #00263c;
        box-shadow:
            0px -1px 5px rgba(0, 0, 0, 0.15),
            0px 2px 12px rgba(0, 0, 0, 0.15);
        border-radius: 12px;

        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;

        color: #ffffff;
    }
}

.target {
    width: max-content;
    height: max-content;
}
