@import "../common/variables.scss";

.bp4-popover2-target:hover {
  .mpd-select:not(.mpd-disabled) {
    border-color: $primary-color !important;
    background-color: #f4f8ff;
  }

  .mpd-select.mpd-disabled {
    cursor: not-allowed;
  }
}

.styled-select {
  // background: white;
  height: 32px;

  &.hide-arrow {
    .mpd-select-arrow-icon {
      display: none;
    }

    .mpd-select-left-icon {
      margin-right: 0;
    }

    .mpd-select {
      padding: 0 !important;
      justify-content: center;
    }
  }

  .bp4-popover2-wrapper {
    display: flex;
  }

  .bp4-popover2-target {
    width: 100%;
    // padding: 3px;
    // min-width: 96px;

    .mpd-select {
      border-radius: 100px;
      // min-width: 96px;
      //   min-height: 32px;
      //  min-width: 96px;
    }
  }

  .mpd-select-empty {
    font-family: $font-family;
  }

  .mpd-select-placeholder {
    font-family: $font-family;
    color: $light-black-color;
  }

  &.simple {
    background: transparent;

    .mpd-select {
      border: none;
      padding: 0;
    }

    .mpd-select-label {
      font-family: $font-family;
      font-size: 12px;
      font-weight: 700;
      color: black;
    }

    .mpd-select-option {
      color: black;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
    }

    .mpd-select-placeholder {
      position: initial;
      z-index: 1;
      margin-right: 5px;
      margin-top: 3px;
    }
  }

  &.primary {
    .mpd-select-label {
      font-family: $font-family;
      font-size: 12px;
      font-weight: 700;
      text-transform: capitalize;
    }

    .mpd-select-option {
      color: rgba(0, 0, 0, 0.75);
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;

      &.mpd-selected {
        color: #000;
      }
    }

    .mpd-select-option:hover {
      background-color: $primary-color;
      color: white;
    }

    .mpd-select-placeholder {
      position: initial;
      z-index: 1;
      margin-right: 15px;
    }

    &:hover:not(:active) {
      .mpd-select {
        border: 1px solid $primary-color !important;
        background-color: white;
      }

      .mpd-select-label {
        color: $primary-color !important;
      }

      &:not(.fill-svg) {
        .mpd-select-left-icon {
          rect {
            fill: $primary-color;
          }
        }
      }

      .mpd-select-arrow-icon {
        path {
          stroke: $primary-color;
        }
      }

      .mpd-select-placeholder {
        color: $primary-color;
      }
    }

    .mpd-select {
      &.mpd-active {
        border: 1px solid $primary-color;
        background-color: white;

        .mpd-select-label {
          color: $primary-color !important;
        }

        .mpd-select-arrow-icon {
          path {
            stroke: $primary-color;
          }
        }

        .mpd-select-placeholder {
          color: $primary-color;
        }
      }
    }

    &:not(.fill-svg) {
      .mpd-select {
        &.mpd-active {
          .mpd-select-left-icon {
            rect {
              fill: $primary-color;
            }
          }
        }
      }
    }

    &.fill-svg {
      .mpd-select {
        &.mpd-active {
          .mpd-select-left-icon {
            path {
              fill: $primary-color;
            }
          }
        }
      }
    }

    &:active {
      .mpd-select {
        border: 1px solid $primary-color !important;
        background-color: $white-button-active-color !important;
      }

      .mpd-select-label {
        color: $primary-color !important;
      }

      &:not(.fill-svg) {
        .mpd-select-left-icon {
          rect {
            fill: $primary-color;
          }
        }
      }

      &.fill-svg {
        .mpd-select-left-icon {
          path {
            fill: $primary-color;
          }
        }
      }

      .mpd-select-arrow-icon {
        path {
          stroke: $primary-color;
        }
      }

      .mpd-select-placeholder {
        color: $primary-color;
      }
    }
  }

  &.image {
    .mpd-select-label {
      display: none;
    }

    .mpd-select-options {
      width: 100%;
      padding: 10px;
    }

    .mpd-select-option {
      color: black;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
      border-radius: 6px;
    }

    .mpd-select-option:hover {
      background-color: $primary-color;
      color: white;
    }

    .mpd-select {
      background: white;
      padding: 0px 7px;
      min-width: 62px;
      width: auto;
      min-height: 30px;
    }

    &:hover:not(:active) {
      .mpd-select {
        // border-color: $primary-color !important;
        &:not(.mpd-active) {
          background-color: white !important;
          border: 1px solid $primary-color !important;

          svg {
            path {
              stroke: $primary-color;
            }
          }
        }
      }

      &:not(.fill-svg) {
        .mpd-select-left-icon {
          rect {
            fill: $primary-color;
          }
        }
      }

      &.fill-svg {
        .mpd-select-left-icon {
          path {
            fill: $primary-color;
          }
        }
      }

      .mpd-select-arrow-icon {
        path {
          stroke: $primary-color;
        }
      }
    }

    &:active {
      .mpd-select {
        border: 1px solid $primary-color !important;
        background-color: $white-button-active-color !important;
      }

      &:not(.fill-svg) {
        .mpd-select-left-icon {
          rect {
            fill: $primary-color;
          }
        }
      }

      &.fill-svg {
        .mpd-select-left-icon {
          path {
            fill: $primary-color;
          }
        }
      }

      .mpd-select-arrow-icon {
        path {
          stroke: $primary-color;
        }
      }
    }

    .mpd-select {
      &.mpd-active {
        border: 1px solid $primary-color !important;
        background-color: $white-button-active-color !important;
        path {
          stroke: $primary-color;
        }

        .mpd-select-arrow-icon {
          path {
            stroke: $primary-color;
          }
        }
      }
    }

    &:not(.fill-svg) {
      .mpd-select {
        &.mpd-active {
          .mpd-select-left-icon {
            rect {
              fill: $primary-color;
            }
          }
        }
      }
    }

    &.fill-svg {
      .mpd-select {
        &.mpd-active {
          .mpd-select-left-icon {
            svg {
              path {
                fill: $primary-color;
              }
            }
          }
        }
      }
    }
  }

  &.no-line {
    background: transparent;

    .mpd-select-label {
      font-family: $font-family;
      color: $light-black-color;
      font-size: 16px;
      font-weight: 600;
    }

    .mpd-select-option {
      color: black;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
    }

    .mpd-select-option:hover {
      background-color: $primary-color;
      color: white;
    }

    .mpd-select {
      border: none;
    }
  }

  &.line {
    // padding-top: 10px;
    .line-blue-label {
      text-transform: uppercase;
      font-family: $font-family;
      font-size: 12px;
      font-weight: 600;
    }

    .mpd-select-placeholder {
      z-index: 1;
    }

    .mpd-select-label {
      font-family: $font-family;
      color: $light-black-color;
      font-size: 16px;
      font-weight: 600;
      flex: 1;
    }

    .mpd-select-option {
      color: black;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
    }

    .mpd-select-arrow-icon {
      right: 7px;
      position: absolute;
    }

    .mpd-select-option:hover {
      background-color: $primary-color;
      color: white;
    }

    .mpd-select {
      border-width: 0px 0px 1px 0px;
      border-radius: 0;
      padding-left: 0;
      padding: 3px 20px 3px 0px;
      margin-top: 10px;

      &.mpd-active {
        border-color: $primary-color;

        .mpd-select-placeholder {
          color: $primary-color;
        }
      }

      &.mpd-full {
        .mpd-select-placeholder {
          color: $primary-color;
        }
      }

      // &.mpd-active, &.mpd-full {
      //   border-color: $primary-color;
      //   .mpd-select-placeholder {
      //     color: $primary-color;
      //   }
      // }
    }
  }

  &.solid {
    max-height: unset;
    margin-top: 31px;

    .mpd-select-label {
      font-family: $font-family;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.04em;
      font-weight: 600;
      color: $form-text-color;
    }

    .mpd-select-option {
      color: rgba(0, 0, 0, 0.75);
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;

      &.mpd-selected {
        color: #000;
      }
    }

    .mpd-select-option:hover {
      background-color: $primary-color;
      color: white;
    }

    .mpd-select-placeholder {
      // position: absolute;
      top: -26px;
      left: 0;
      z-index: 1;
      font-family: $font-family;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.1em;
      color: $form-lable-color;
    }

    &:hover:not(:active) {
      .mpd-select {
        border: 1.5px solid $primary-color !important;
        background-color: white;
      }

      .mpd-select-label {
        color: $primary-color !important;
      }

      &:not(.fill-svg) {
        .mpd-select-left-icon {
          rect {
            fill: $primary-color;
          }
        }
      }

      .mpd-select-arrow-icon {
        path {
          stroke: $primary-color;
        }
      }
    }

    .mpd-select {
      min-height: 54px;
      padding: 16px 17px;
      margin-bottom: 7px;
      background: $off-white-color;
      border: 1.5px solid $form-border-color;
      transition: 0.5s ease-in-out all;

      box-shadow: none;
      -webkit-appearance: none;

      .mpd-select-arrow-icon {
        position: absolute;
        right: 17px;
      }

      &.mpd-active {
        border: 1.5px solid $form-border-color;
        background-color: white;

        .mpd-select-label {
          color: $primary-color !important;
        }

        .mpd-select-arrow-icon {
          path {
            stroke: $primary-color;
          }
        }

        .mpd-select-placeholder {
          color: $primary-color;
        }
      }
    }

    &:not(.fill-svg) {
      .mpd-select {
        &.mpd-active {
          .mpd-select-left-icon {
            rect {
              fill: $primary-color;
            }
          }
        }
      }
    }

    &.fill-svg {
      .mpd-select {
        &.mpd-active {
          .mpd-select-left-icon {
            path {
              fill: $primary-color;
            }
          }
        }
      }
    }

    &:active {
      .mpd-select {
        border-color: 1.5px solid $primary-color !important;
        background-color: $white-button-active-color !important;
      }

      .mpd-select-label {
        color: $primary-color !important;
      }

      &:not(.fill-svg) {
        .mpd-select-left-icon {
          rect {
            fill: $primary-color;
          }
        }
      }

      &.fill-svg {
        .mpd-select-left-icon {
          path {
            fill: $primary-color;
          }
        }
      }

      .mpd-select-arrow-icon {
        path {
          stroke: $primary-color;
        }
      }

      .mpd-select-placeholder {
        color: $primary-color;
      }
    }
  }

  .error {
    &.mpd-active {
      color: $form-error-color;
      font-family: $font-family;
      font-size: 12px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  &.mpd-error {
    .mpd-select {
      border-color: $form-error-color;
    }

    .mpd-select-placeholder {
      color: $form-error-color;
    }
  }
}

.styled-select-popover {
  .mpd-select-option {
    color: black;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
  }

  .mpd-select-option:hover {
    background-color: $primary-color;
    color: white;
  }
}
