@import "../../../../../common/styles.scss";

.data-export-settings-list-header {
    display: flex;
    padding: 0px 40px;
    padding-bottom: 20px;
    border-bottom: solid 1px $border-color;
}

.data-export-settings-list-header-inner-container {
    display: flex;
    width: 600px;
}

.data-export-settings-list-header-item {
    white-space: nowrap;
    display: flex;
    user-select: none;
    color: black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    flex: 1;
    min-width: 120px;
    &.activity {
        padding: initial;
    }
}