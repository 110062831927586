@import "../../../../../common/styles";

.prev-conversations {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    // margin-bottom: 30px;
    cursor: pointer;
    row-gap: 13px;
    padding-top: 13px;
    padding-bottom: 20px;

    :global(.empty-block) {
        margin-top: 40px;
    }

    &-load-more-spinner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 100%;
    }
}
