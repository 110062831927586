@import "../common/styles";

.styled-radio-group {
    .bp4-control {
        font-family: $font-family;
        color: $light-black-color;
        font-size: 16px;
        font-weight: 600;
        &.bp4-radio {
            // margin-bottom: 17px;
        }
        .bp4-control-indicator {
            outline: none !important;
        }
    }
    .bp4-control input:checked ~ .bp4-control-indicator {
        box-shadow: none !important;
        background-color: $primary-color !important;
    }
}

.restyled-radio-group.styled-radio-group {
    // &::after {
    //     content: "";
    //     display: block;
    //     box-sizing: border-box;
    //     position: absolute;
    //     left: -24px;
    //     // bottom: -25px;
    //     width: calc(100% + 47px);
    //     border-top: 1px solid rgba(0, 0, 0, 0.15);
    //     z-index: 20;
    //     transition: all 0.3s;
    // }
    display: flex;
    flex-direction: column;
    gap: 17px 0;

    .bp4-control.bp4-radio {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        // margin-bottom: 16px;
        span.bp4-control-indicator {
            align-items: center;
            justify-content: center;
            // width: 24px;
            // height: 24px;
            display: flex;
            flex-direction: row;
            border: 1px solid #1f76ff;
            background-color: white !important;
            margin: 0 8px 0 0;
        }
    }

    .bp4-control.bp4-radio input:checked ~ .bp4-control-indicator::before {
        width: 16px !important;
        height: 16px !important;
        background: $primary-color !important;
        border-radius: 100% !important;
    }
}

.restyled-radio-group.styled-radio-group.large {
    span.bp4-control-indicator {
        width: 24px;
        height: 24px;
    }

    .bp4-control.bp4-radio input:checked ~ .bp4-control-indicator::before {
        width: 16px !important;
        height: 16px !important;
    }
}

.restyled-radio-group.styled-radio-group.small {
    span.bp4-control-indicator {
        width: 18px;
        height: 18px;
    }

    .bp4-control.bp4-radio input:checked ~ .bp4-control-indicator::before {
        width: 12px !important;
        height: 12px !important;
    }
}
