@import "../common/styles";

.styled-button.bp4-button.bp4-disabled {
    opacity: 0.35;
}

.styled-button.bp4-button:hover .refresh-icon {
    path {
        stroke: unset !important;
        fill: $primary-color;
    }
}

.styled-button.bp4-button {
    flex: initial;
    outline: none !important;
    min-width: 96px;
    // transition: all 0.3s;
    box-sizing: border-box;
    border-radius: 100px;

    .bp4-spinner {
        margin-right: 10px;
    }
    .mpd-button-text {
        font-weight: 600;
        font-family: $font-family;
        font-size: 14px;
    }
    &.disabled {
        opacity: 0.35;
    }
    &.simple {
        padding: 0;
        min-width: 32px;
        .mpd-button-text {
            text-align: left;
        }
    }
    &.primary-simple {
        padding: 0;
        .mpd-button-text {
            color: $primary-color;
            margin: 0;
            font-family: $font-family;
            font-size: 14px;
            font-weight: 600;
        }
    }
    &.primary {
        background-color: $primary-color !important;
        height: 30px;
        .mpd-button-text {
            // min-width: 96px;
            // padding: 0 25px;
            color: white;
            text-transform: capitalize;
        }
        // svg {
        //     fill: white;
        // }
        &.mpd-active {
            box-shadow: none !important;
            background-color: darken($color: $primary-color, $amount: 10%) !important;
        }
    }
    &.primary:active {
        box-shadow: none !important;
        background-color: darken($color: $primary-color, $amount: 10%) !important;
    }
    &.primary:hover:not(:active):not(.mpd-active) {
        box-shadow: 0 2px 4px $border-dark-color !important;
        background-color: lighten($color: $primary-color, $amount: 10%) !important;
    }
    &.secondary {
        background-color: white !important;
        border: 1px solid $border-color !important;
        // border-radius: 4px;
        height: 30px;
        .mpd-button-text {
            color: black;
            // min-width: 96px;
            // padding: 0 25px;
            text-transform: capitalize;
        }
        &.mpd-active {
            border-color: $primary-color !important;
            background-color: $white-button-active-color !important;
            .mpd-button-text {
                color: $primary-color !important;
            }
            &:not(.fill-svg) {
                svg {
                    path {
                        stroke: $primary-color;
                    }
                }
            }
            &.fill-svg {
                svg {
                    fill: $primary-color;
                }
            }
        }
    }
    &.secondary:active {
        border-color: $primary-color !important;
        background-color: $white-button-active-color !important;
        .mpd-button-text {
            color: $primary-color !important;
        }
        &:not(.fill-svg) {
            svg {
                path {
                    stroke: $primary-color;
                }
            }
        }
        &.fill-svg {
            svg {
                fill: $primary-color;
            }
        }
    }
    &.secondary:hover:not(:active):not(.mpd-active) {
        border-color: $primary-color !important;
        // background-color: white !important;
        .mpd-button-text {
            color: $primary-color !important;
        }
        &:not(.fill-svg) {
            svg {
                path {
                    stroke: $primary-color;
                }
            }
        }
        &.fill-svg {
            svg {
                fill: $primary-color;
            }
        }
    }
    &.mpd-selected {
        border-color: $primary-color !important;
        background-color: white !important;
        margin-right: 0px !important;
        svg {
            path {
                stroke: $primary-color;
            }
        }
    }

    &.delete {
        border-color: $primary-color !important;
        background-color: #f23a50 !important;

        .mpd-button-text {
            color: white !important;
            // min-width: 96px;
            // padding: 0 25px;
            text-transform: capitalize;
        }
    }

    &.delete-simple {
        background-color: white !important;
        border: 1px solid #ff1f70 !important;

        .mpd-button-text {
            color: #ff1f70 !important;
            text-transform: capitalize;
        }

        .bp4-spinner {
            max-width: 20px;

            .bp4-spinner-head {
                stroke: #ff1f70;
            }
            .bp4-spinner-track {
                stroke: rgba(255, 31, 112, 0.15);
            }
        }
    }

    &.delete-simple:hover {
        background-color: rgba(255, 31, 112, 0.05) !important;
    }

    &.delete-simple:active {
        background-color: darken($color: rgba(255, 31, 112, 0.1), $amount: 10%) !important;
    }

    &.delete-simple:hover:not(:active):not(.mpd-active) {
        background-color: lighten($color: rgba(255, 31, 112, 0.05), $amount: 10%) !important;
    }
}

.styled-button.circle-button {
    min-width: 32px !important;
    min-height: 32px !important;
    border-radius: 100%;
}

// .styled-button.bp4-button.with-spinner {
//     padding-left: 3px;
// }
