@import "../../../common/styles";

.select-list-add-teams {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
}

.select-list-add-team {
    border-radius: 15px;
    background-color: $primary-color;
    color: white;
    display: flex;
    align-items: center;
    margin-right: 5px;
    height: 25px;
}

.select-list-add-team-close {
    display: flex;
    align-items: center;
    justify-content: center;
    .mpd-button {
        padding: 0 10px 0 10px !important;
        margin-top: 1px;
    }
}

.select-list-add-team-text {
    padding-left: 10px;
    user-select: none;
    color: white;
    font-family: $font-family;
    font-size: 10px;
    font-weight: 600;
    white-space: nowrap;
}

.select-list-add {
    display: flex;
    flex-direction: column;
    position: relative;
    &.mpd-full {
        .select-list-add-placeholder {
            top: 0px;
            transition: 0.2s ease all;
            color: $primary-color;
        }
        .select-list-add-horizontal-list {
            border-color: $primary-color;
        }
    }   
}

.select-list-add:focus-within {
    .select-list-add-placeholder {
        top: 0px;
        transition: 0.2s ease all;
        color: $primary-color;
    }
    .select-list-add-horizontal-list {
        border-color: $primary-color;
    }
}

    .select-list-add-placeholder {
        position: absolute;
        top: 20px;
        transform: translateZ(0);
        z-index: 1;
        transition: 0.2s ease all;
        outline: none !important;
        user-select: none;
        pointer-events: none;
        font-size: 12px;
        font-weight: 600;
    }

.select-list-add-input {
    flex: 1;
    border: none;
    outline: none !important;
    color: black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    // margin-top: 10px;
}

.select-list-add-select-list-item {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    cursor: pointer;
    &.hover {
        background-color: $primary-color;
        .select-list-add-select-list-item-text {
            color: white;
        }
        .select-list-add-select-list-item-initials {
            color: $primary-color;
            background-color: white;
        }
    }
}

.select-list-add-select-list-item:hover {
    background-color: $primary-color;
    .select-list-add-select-list-item-text {
        color: white;
    }
    .select-list-add-select-list-item-initials {
        color: $primary-color;
        background-color: white;
    }
}

.select-list-add-select-list-item:first-child {
    border-radius: 4px 4px 0px 0px;
}

.select-list-add-select-list-item:last-child {
    border-radius: 0px 0px 4px 4px;
}

.select-list-add-select-list-item-initials {
    background-color: $primary-color;
    border-radius: 50%;
    color: white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.select-list-add-select-list-item-text {
    padding: 0 10px;
    color: black;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 600;
}

.select-list-add-select-list {
    box-shadow: 0 2px 12px $border-color, 0 -1px 5px $border-color;
    border-radius: 4px;
    background-color: white;
    z-index: 2;
}

.select-list-add-horizontal-list {
    border-bottom: 1px solid $border-color;
    padding: 10px 0 3px 0;
    // height: 50px;
}

.select-list-add-select-list {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    max-height: 160px;
    min-height: 40px;
    height: initial;
}

.select-list-add-select-list-empty {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0 2px 12px $border-color, 0 -1px 5px $border-color;
    border-radius: 4px;
    user-select: none;
}