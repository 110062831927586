@import "../common/styles.scss";

.#{$ns}-tree {
  .bp4-tree-node-content {
    height: auto !important;
  }
  .bp4-collapse {
    margin-left: 15px;
  }
}

.#{$ns}-tree-node-content {
  padding: 7.5px 15px;
  -webkit-tap-highlight-color: black;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.02);
  }
  &:active {
    transform: scale(1);
  }
}

.#{$ns}-tree-node {
  &.#{$ns}-selected {
    .#{$ns}-tree-node-content {
      transform: scale(1);
    }
  }

  .bp4-collapse {
    .#{$ns}-tree-node-content {
      &:hover {
        transform: scale(1.02);
      }
      &:active {
        transform: scale(1);
      }
    }
    .#{$ns}-tree-node {
      margin: 0;
      &.#{$ns}-selected {
        .#{$ns}-tree-node-content {
          transform: scale(1);
        }
      }
    }
  }
}

@include for-size(phone-only) {
  .#{$ns}-tree-node-content {
    &:hover {
      transform: scale(1);
    }
    &:active {
      transform: scale(0.95);
    }
  }
}
