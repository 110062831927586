@import "../../../common/styles.scss";

.sidebar-account {
    display: flex;
    flex-direction: column;
    width: 261px;
}

.sidebar-account-initials-src {
    border-radius: 50%;
    width: 36px;
    height: 36px;
}

.sidebar-account-name {
  text-transform: capitalize;
}

.sidebar-account-initials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: $font-family;
    font-size: 16px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #4a90e2;
    font-weight: 700;
    text-transform: uppercase;
  }

  .sidebar-account-profile-info {
    display: flex;
    align-items: center;
    color: black;
    user-select: none;
    padding: 20px 25px;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid $border-color;
  }
  
  .sidebar-account-status {
    color: $lighter-black-color;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 600;
  }

  .sidebar-account-status-control {
    border-bottom: 1px solid $border-color;
    user-select: none;
    padding: 0 25px;
    height: 51px;
    display: flex;
    .styled-switch {
      width: 100%;
    }
  }

  .sidebar-account-info {
    margin-left: 10px;
  }

  .sidebar-account-menu-container {
    display: flex;
    flex-direction: column;

    .mpd-button:last-child {
      z-index: 2;
    }

    .mpd-button {
      &.mpd-hover {
        background-color: $primary-color !important;
        .mpd-button-text {
          color: white;
        }
        svg {
          path {
            stroke: white;
          }
        }
      }
    }

    .mpd-button-text {
      color: black;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
      text-align: start;
    }

    .mpd-button {
      width: 100%;
      display: flex;
      align-items: flex-start;
      height: 50px;
      min-height: 50px;
      border-radius: 0 !important;
      padding: 0px 25px !important;
      flex-direction: column;
    }
    .mpd-icon {
      margin-right: 10px;
    }

    .inner-container:last-child {
      border-bottom: none;
    }

    .inner-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      border-bottom: 1px solid $border-color;
    }
  }