@import "../../../../common/styles";

.tags-list {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 40px;
    overflow: auto;
    row-gap: 20px;

    &-spinner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    :global(.emty-block-icon) {
        width: 63px;
        height: 65px;
        margin-bottom: 10px;
    }
    &-left-icon {
        max-width: 46px;
        max-height: 48px;
        height: 48px;
        width: 46px;
    }
}

.sub-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    height: 50px;
    width: 100%;
    background-color: #fafafa;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.search-input {
    max-width: 220px;
}

@include for-size(phone-only) {
    .tags-list {
        padding: 10px;
    }
}
