@import "../../../common/styles";

.address-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 15px;
    margin-top: 15px;
}

.edit-place-icon {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: $primary-color;
    flex-shrink: 0;

    svg {
        width: 100%;
        height: 100%;
    }
}
