@import "../../../../components/common/styles";

.content-sticky-title {
    position: sticky;
    width: 100%;

    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #000000;
    height: 36px;
    background-color: white;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    // margin-top: -10px;
    z-index: 10;
    padding-left: 10px;

    &_first {
        top: -10px;
        margin-top: -10px;
    }

    &_first-with-header-search {
        top: 89px;
    }
}

.ancestors {
    overflow: hidden;
    text-overflow: ellipsis;
}
