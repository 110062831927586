@import "../common/styles";

.checkmark-select {
    position: relative;
    width: 62px;
    margin-right: 10px;
    &.mpd-active {
        .bp4-control-indicator {
            border-color: rgba(31, 118, 255, 0.50) !important;
        }
        .mpd-select {
            border-color: $primary-color !important;
            svg {
                color: $primary-color;
            }
        }
    }
    .bp4-control-indicator {
        height: 20px !important;
        width: 20px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        outline: none !important;
        background-image: none !important;
        background-color: white !important;
        border-radius: 4px!important;
        box-shadow: none !important;
        border: 1px solid rgba(0,0,0,0.15) !important;
    }
    .bp4-checkbox {
        position: absolute;
        left: 8px;
        top: 11px;
        z-index: 100;
        input:checked ~ .bp4-control-indicator {
            background-color: $primary-color !important;
            box-shadow: none !important;
        }
    }
    .mpd-select-arrow-icon {
        position: absolute;
        right: 5px;
    }
}

.checkmark-select:hover {
    .bp4-control-indicator {
        border-color: rgba(31, 118, 255, 0.50) !important;
    }
}
