@import "../../components/common/styles";

.row-gap-fixer {
    height: max-content;
}

.empty {
    padding: 40px 0;
}

.portal {
    z-index: 21;
}

.bp4-popover2-open {
    .rounded-dropdown {
        .mpd-select-label {
            color: #1f76ff;
        }

        svg path {
            stroke: #1f76ff;
        }

        svg.mpd-select-arrow-icon:not(.arrow-icon_animation-disabled) {
            transform: rotate(540deg);
        }
    }
}

:global(.bp4-popover2-content) {
    height: 100%;
}

.content-footer {
    height: 50px;
    width: 100%;
    position: sticky;
    bottom: 0;
    margin-left: -10px;
    width: calc(100% + 20px);
    bottom: -10px;
    margin-bottom: -10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    background-color: white;
    padding: 0 10px;
    z-index: 10;

    :global(button.primary-simple) {
        padding: 0;

        :global(.bp4-button-text) {
            text-align: left;
        }
    }
}

.content-header {
    width: 100%;
    position: sticky;
    top: 0;
    margin-left: -10px;
    width: calc(100% + 20px);
    top: -10px;
    margin-top: -10px;
    margin-bottom: 10px;
    z-index: 2;
}

:global(.content-size-limiter) {
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.create-new-entity-header {
    // height: 50px;
    // border-bottom: 1px solid grey;
    position: sticky;
    top: -10px;
    margin-left: -10px;
    padding: 0 10px;
    width: calc(100% + 20px);

    .plus-circle {
        background: #1f76ff;
        border-radius: 35px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        :global(.mpd-icon) {
            width: 12px;
            height: 12px;
        }
    }
}
