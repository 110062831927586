@import "../../../../common/styles";

.account-branding-edit-info-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.account-branding-scroll-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.account-branding-edit {
    &-details-info {
        display: flex;
        flex-direction: column;

        .vanity {
            display: flex;
            align-items: center;
            column-gap: 10px;
            padding-bottom: 20px;
            .vanity__input.styled-floating-input {
                padding-top: 0 !important;
                max-width: 200px;
            }

            .vanity__tail {
                font-family: $font-family;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.04em;
                color: #293136;
            }
        }
    }

    &-icon-title,
    &-info-title {
        margin-top: 0;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;

        letter-spacing: 0.04em;
        text-transform: uppercase;

        color: #768086;
    }

    &-icon-block {
        .image-upload-icon-image {
            height: 60px;
            width: 60px;
        }

        margin-bottom: 22px;
        text-transform: capitalize;
    }

    &-info-text {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;

        /* Dark Grey */

        color: #293136;
    }
}

.branded-phone-number {
    &__title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: #1f76ff;
        margin-bottom: 5px;
        cursor: pointer;
    }
}

.hint {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
}

.account-branding-chose-number-wrapper {
    max-height: 515px;
    overflow: hidden;
    display: flex;
}

.branding-phone-number-modal-children {
    padding-bottom: 0;
}
