@import "../../../../../../../common/styles";

.main-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    // overflow: hidden;
    // max-height: 100%;
    padding: 0 1px;
    width: 560px;

    .top-wrapper {
        display: grid;
        column-gap: 20px;
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }

    .top-wrapper_only-number {
        grid-template-columns: 1fr;
    }
}

.row-template {
    display: grid;
    grid-template-columns: minmax(auto, 135px) minmax(auto, 1fr) minmax(auto, 75px) minmax(auto, 70px);
}

.empty-wrapper {
    display: flex;
    align-items: center;
    height: 230px;
}

.choose-number-step-list {
    list-style-type: none;
    // margin-top: 45px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    padding: 0 0 20px 0;
    position: relative;
    overflow: auto;
    min-height: 0;

    :global(.bp4-skeleton) {
        max-height: 16px;
        height: 16px;
        border-radius: 8px;
    }

    &-item {
        height: 32px;
        column-gap: 60px;
        // align-self: center;
        align-items: center;

        &-phone-number {
            font-family: $font-family;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
        }

        &-price {
            font-family: $font-family;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 100%;
            color: rgba(0, 0, 0, 0.75);
        }

        &-location {
            font-family: $font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            color: rgba(0, 0, 0, 0.75);
            display: flex;
            align-items: center;
            white-space: nowrap;

            img {
                width: 23px;
                height: 12px;
            }
        }

        &-buy-button {
            // top: 0;
            // left: 0;
            position: relative;
            height: 32px;
            width: 100%;

            label,
            input {
                cursor: pointer;
                // display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            input[type="radio"] {
                opacity: 0.011;
                z-index: 2;
            }
        }
        &-buy-button-label {
            z-index: 1;
            line-height: 1.8em;

            border: 2px solid $primary-color;
            border-radius: 100px;
            width: 70px;
            height: 32px;

            font-family: $font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            color: $primary-color;
            justify-content: center;
        }

        &-buy-button-label_checked {
            background: $primary-color;
            color: white;
        }
    }

    &-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        min-height: 32px;
        column-gap: 60px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;
    }

    &-header-value-label {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: #000000;
        white-space: nowrap;
    }
}

.choose-number-step-loading-spinner-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: 500px;
}
