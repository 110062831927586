@import "../common/styles";

.settings-title {
    user-select: none;
    white-space: nowrap;
    margin-bottom: 40px;

    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #000000;
    text-transform: uppercase;
}
