@import "../../../../../common/styles";

.profile-listing-wrapper {
    padding: 42px 0 0 64px;

    &-text-area {
        :global(textarea.bp4-input) {
            font-family: $font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.04em;

            color: #293136;

            &:focus {
                box-shadow: unset !important;
                background: #f4f8ff !important;
            }
        }
        :global(.mpd-floating-textarea-label) {
            top: 6px !important;
        }
    }
}
