@import "../../../../../common/styles";

.disabled {
    opacity: 0.3;
}

.tag-list-item {
    // min-height: 88px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    // height: 88px;
    flex-wrap: nowrap;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    transition: transform 0.3s ease;
    column-gap: 40px;

    .main-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 30px;
        flex-grow: 1;
        column-gap: 20px;
    }

    &:hover {
        transform: scale(1.01);
    }

    &-name-description-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        // width: calc(100% - 69px);
    }

    &-name {
        font-family: $font-family;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        // line-height: 18px;
        align-items: center;
        color: $black;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-description:global(.bp4-skeleton),
    &-name:global(.bp4-skeleton) {
        min-width: 100%;
        height: 15px;
        border-radius: 12px;
    }

    &-description {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        // line-height: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        align-items: center;
        color: $gray;
    }

    &-left-wrapper {
        display: flex;
        align-items: center;
        max-width: 70%;
        flex: 1;
        min-width: 270px;

        svg {
            margin-right: 25px;
        }
    }
    &-left-icon {
        max-width: 46px;
        max-height: 48px;
        height: 48px;
        width: 46px;
    }

    &-left-icon:global(.bp4-skeleton) {
        border-radius: 100%;
    }

    &-right-wrapper {
        display: flex;
        // max-width: 27%;
        justify-content: space-between;
        flex: 1;
        column-gap: 10px;
    }

    &-info-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: max-content;
    }

    &-dropdown-more {
        width: 32px;

        svg {
            margin: 0 0 0 2px !important;
        }
    }

    &-info-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $black;
        white-space: nowrap;
    }

    &-parent-tag-name {
        color: $gray;
        margin-right: 3px;
    }

    &-admin-avatar {
        width: 32px;
        height: 32px;
        margin-top: 5px;
        border-radius: 100%;
    }

    &-info-value {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 18px;
        text-align: center;
        color: $primary-color;
        margin-top: 14px;
    }

    &-more-dropdown-wrapper {
        display: flex;
        align-items: center;
    }

    :global(.bp4-popover2-target) {
        max-width: 32px;
        max-height: 32px;
    }
}

@include for-size(tablet-landscape-up) {
    .tag-list-item {
        &-left-wrapper {
            display: flex;
            align-items: center;
            flex: 1;
        }
    }
}

@media (max-width: 965px) and (min-width: 400px) {
    .tag-list-item {
        align-items: flex-start;
        height: max-content;
        max-height: unset;
        &-left-wrapper {
            display: flex;
            align-items: center;
            max-width: 100%;
            width: 100%;
            flex: 1;

            svg {
                margin-right: 15px;
            }

            .tag-list-item-more-dropdown-wrapper {
                display: flex;
                justify-content: flex-end;
            }
        }

        &-right-wrapper {
            max-width: 100%;
            width: 100%;
            justify-content: space-evenly;
        }
    }
}
