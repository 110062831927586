@import "../../../../../common/styles";

.snippet-item {
    display: flex;
    background: #ffffff;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    align-items: center;
    height: 88px;
    padding: 22px 20px;
    margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;

    &-left-wrapper {
        display: flex;
        align-items: center;
        :global(.mpd-icon) {
            margin-right: 18px;
            min-width: 45px;
            width: 45px;
            height: 45px;
        }
    }

    &-name-and-category-wrapper {
        display: flex;
        align-items: center;
        // margin-bottom: 9px;
    }

    &-name {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: $black;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        word-break: break-all;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
    }

    &-snippet-text {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        color: $gray;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
    }

    &-category {
        display: flex;
        align-items: center;
        background: #e6e6e6;
        border-radius: 4px;
        height: 24px;
        padding: 0 10px;

        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: $black;

        margin-left: 10px;
    }

    &-dropdown-more {
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        :global(svg) {
            margin: 0;
        }
    }

    &-main-info {
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 10px;
    }
}

@include for-size(phone-only) {
    .snippet-item {
        padding: 10px;
        max-height: unset;
        height: unset;

        &-left-wrapper {
            align-items: flex-start;
            max-width: 90%;

            svg:global(.mpd-icon) {
                min-width: 45px;
                min-height: 45px;
                margin-right: 10px;
            }
        }

        &-snippet-text {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &-main-info {
            max-width: 70%;
        }
    }
}
