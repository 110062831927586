@import "../../../common/styles.scss";

.channel-settings {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);

    .flag-state-wrapper {
        display: flex;
        column-gap: 16px;
        align-items: center;
        margin-right: 65px;
        width: 70px;
        // justify-content: flex-end;

        .country-flag {
            width: 30px;
            height: 22px;
        }

        .state-name {
            font-family: $font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: rgba(0, 0, 0, 0.75);
        }
    }

    .line-circle {
        max-width: 48px;
        width: 100%;
        flex-shrink: 0;
        height: 48px;
        border-radius: 100%;
        background-color: #e1286b;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 18px;
        color: #ffffff;
    }
}

@include for-size(phone-only) {
    .channel-settings-channel-box {
        width: 100%;
    }
}
