$primary-color: #1f76ff;
$white-button-active-color : rgba(31, 118, 255, 0.05);

$navy-color: #003554;
$private-color: #c3c3c3;
$public-color: #c5dcff;
$off-color: #ff5252;

$stepper-border-color: #cfcfcf;

$border-color: rgba(0, 0, 0, 0.15);
$border-dark-color: rgba(0, 0, 0, 0.25);
$lighter-black-color: rgba(0, 0, 0, 0.5);
$light-black-color: rgba(0, 0, 0, 0.75);

$off-white-color: #fafafa;
$light-white-color: rgba(255, 255, 255, 0.75);
$lighter-white-color: rgba(255, 255, 255, 0.5);

$severe-color: #F98028;
$moderate-color: #34ACE0;
$minor-color: #19B775;
$extreme-color: #FF2E79;

// $severe-border-color: #fa9d07;
// $moderate-border-color: #f7da00;
// $minor-border-color: #17c769;
// $extreme-border-color: #b01429;

$severe-border-color: #F98028;
$moderate-border-color: #34ACE0;
$minor-border-color: #19B775;
$extreme-border-color: #FF2E79;

$p1-color: #FF7675;
$p2-color: #FDCB6E;
$p3-color: #00CEC9;
$p4-color: #6C5CE7;

$new-color: #1F76FF;
$open-color: #47C6A0;
$pending-color: #A984E5;
$closed-color: #F96882;

// Form colors
$form-lable-color: #7F8896;
$form-text-color: #293136;
$form-primary-shadow-color: rgba(31, 118, 255, 0.15);
$form-error-color: #DB2E45;
$form-border-color: rgba(34, 36, 45, 0.12);
$form-error-shadow-color: rgba(219, 46, 69, 0.15);

$black: #000000;
$gray: #808080;