@import "../common/styles.scss";

.#{$ns}-scrollable-div-container {
  width: initial;
  position: relative;
  overflow: hidden;
  // display:inline-block;
  // display: block;
  &.y-direction {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }
  &.x-direction {
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
  }
}

.#{$ns}-scrollable-div-inner-container {
  width: 100%;
  z-index: -1;
  height: 100%;
  position: absolute;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}
