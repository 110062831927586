@import "../../../../common/styles.scss";

.settings-users-list-item:hover {
    background: rgba(0, 0, 0, 0.01);
}

.settings-users-list-item-left-loading {
    width: 80px;
    height: 10px;
    align-self: center;
    border-radius: 10px;
}

.settings-users-list-item-right-loading {
    width: 200px;
    height: 10px;
    align-self: center;
    border-radius: 10px;
}

.settings-users-list-item-select-popover {
    .mpd-select-options {
        width: 150px;
        transform: translateX(-10px);
    }
    .mpd-select-option {
        text-transform: capitalize;
    }
}

// .settings-users-list-item-select-wrapper {
    // align-self: stretch;
    // height: 32px;
    // margin-left: 15px;
// }

.settings-users-list-item-select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    .mpd-select {
        padding: 0;
        justify-content: flex-end;
    }
    .mpd-select-label {
        text-transform: capitalize;
        width: 100%;
        text-align: center;
    }
}

.settings-users-list-item {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
    height: 60px;
    flex-shrink: 0;
    border-bottom: solid 1px $border-color;
    user-select: none;
    align-items: center;
    cursor: pointer;
    .mpd-select-text-container {
        color: #ff6b6b;
    }
    .mpd-select-container {
        width: 100%;
    }
    &.mpd-active {
        .mpd-select-text-container {
            color: $primary-color;
        }
    }
    .styled-checkbox {
        align-items: center;
        display: flex;
        margin-bottom: 0;
        cursor: pointer;
    }
}

.settings-users-list-item-right-wrapper {
    display: flex;
    min-height: 40px;
    align-self: stretch;
    align-items: center;
    column-gap: 15px;
}

.settings-users-list-item-status-image-wrapper {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-left: 20px;
    align-self: center;
}

.settings-users-list-item-status-image {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.activated {
        background: #1daa45;
    }
    &.archived {
        background: #bfbfbf;
    }
    &.deactivated {
        background: #d31c48;
    }
    &.invited {
        background: #34ace0;
    }
}

.settings-users-list-item-teams {
    display: flex;
    align-items: center;
}

.settings-users-list-item-teams-items {
    width: 106px;
    height: 20px;
    border-radius: 11px;
    background-color: $primary-color;
    color: white;
    text-align: center;
    font-family: $font-family;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
}

.settings-users-list-item-left-wrapper {
    display: flex;
    align-items: center;
}

.settings-users-list-item-mpd-right {
    display: flex;
    align-items: center;
}

.settings-users-list-item-label-wrapper {
    display: flex;
    align-items: center;
    // padding-left: 10px;
}

.settings-users-list-item-status-text {
    color: black;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    margin-left: 20px;
    align-self: center;
}

.settings-users-list-item-label-initials {
    border-radius: 50%;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    color: white;
    font-family: $font-family;
    text-transform: uppercase;
}

.settings-users-list-item-label-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
}

@supports (-webkit-overflow-scrolling: touch) {
    .bp4-popover2-enter-done {
        .settings-users-list-item-select-popover {
            .mpd-select-options {
                margin-top: -125px;
            }
        }
    }
}

@include for-size(phone-only) {
    .settings-users-list-item-label-title {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        max-width: 105px;
        text-overflow: ellipsis;
    }

    .settings-users-list-item-status-text {
        overflow: hidden;
        white-space: nowrap;
        max-width: 52px;
        text-overflow: ellipsis;
    }
}
