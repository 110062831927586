@import "../common/styles.scss";

.select-main-wrapper {
  margin: 0 !important;
}

.#{$ns}-select-popover {
  margin-top: -4px;
  position: relative;
  box-shadow: none !important;
  .bp4-popover2-arrow {
    display: none;
  }
  .bp4-popover2-content {
    background: none !important;
  }
}

.bp4-popover2-target:hover {
  .mpd-select-title:not(.mpd-disabled) {
    color: #1f76ff;
  }
  .mpd-select-label:not(.mpd-disabled) {
    color: #1f76ff;
  }
}

.bp4-popover2-target:hover {
  .#{$ns}-select.semi-rounded-dropdown:not(.mpd-disabled) {
    border-color: #1f76ff;
    background-color: #f4f8ff;
    box-shadow: 0 0 0 1px #1f76ff;

    svg.mpd-icon.mpd-select-left-icon {
      path {
        stroke: #1f76ff;
        // fill: #1f76ff !important;
      }
    }

    // svg.mpd-icon.mpd-select-arrow-icon {
    //   path {
    //     // stroke: #1f76ff;
    //   }
    // }
  }
}

.#{$ns}-select.only-icon {
  .mpd-select-left-wrapper {
    min-width: unset;
  }
}

.#{$ns}-select.more-dropdown {
  .mpd-select-left-wrapper {
    min-width: unset;
  }
  &:hover {
    svg {
      path {
        stroke: unset !important;
        fill: #1f76ff;
      }
    }
  }
  &.#{$ns}-active {
    svg {
      path {
        stroke: unset !important;
        fill: #1f76ff;
      }
    }
  }
}

.bp4-popover2-target {
  width: 100%;
  .#{$ns}-select.more-dropdown-without-border {
    background-color: unset;
  }

  &:hover {
    .#{$ns}-select.more-dropdown-without-border {
      background-color: unset;
      svg {
        path {
          stroke: unset !important;
          fill: #1f76ff;
        }
      }
    }
  }
}

.#{$ns}-select-title.error-title {
  color: rgb(219, 46, 69);
}

.#{$ns}-select.#{$ns}-error {
  border: 1.5px solid #ff7675;
  background-color: rgba(255, 118, 117, 0.15);
}

.mpd-select-target-wrapper_with-title {
  padding-top: 28px;
}

.#{$ns}-select {
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.25);
  padding: 7px 10px 7px 10px;
  user-select: none;
  // z-index: 10;
  cursor: pointer;
  height: 32px;
  background-color: #fafafa;
  position: relative;
  &.#{$ns}-active {
    border: 1px solid #1f76ff;
    box-shadow: 0 0 0 1px #1f76ff;
    background: #f4f8ff;
    transition: all 0.3s;
  }
  &.#{$ns}-active,
  &.#{$ns}-full {
    .#{$ns}-select-placeholder {
      transition: 0.2s ease all;
      top: -10px;
    }
  }
  &.#{$ns}-disabled {
    cursor: default;
    opacity: 0.5;

    .mpd-select-label {
      opacity: 0.6;
    }
  }

  .#{$ns}-select-search-input {
    border: unset;
    background-color: #fafafa;

    input {
      background-color: #fafafa;
    }

    .mpd-icon {
      margin-left: 0;
    }
  }
}

.#{$ns}-select-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #7f8896;
  position: absolute;
  top: -28px;
  left: 0;
}

.#{$ns}-select-title.mpd-disabled {
  opacity: 0.5;
}

.#{$ns}-select-error {
  position: absolute;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  // letter-spacing: 0.1em;
  color: #db2e45;
}

.#{$ns}-select-title.active-title {
  color: #1f76ff;
}

.#{$ns}-select-placeholder {
  pointer-events: none;
  white-space: nowrap;
  user-select: none;
  position: absolute;
  transition: 0.2s ease all;
  transform: translateZ(0);
  font-size: 12px;
  font-weight: 600;
  top: 4px;
  z-index: -1;
}

.#{$ns}-select-label {
  margin-right: 10px;
  white-space: nowrap;
  user-select: none;
  font-size: 16px;
  font-weight: 600;
}

.#{$ns}-select-options {
  // display: flex;
  flex-direction: column;
  background: white;
  z-index: 1;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  max-height: 190px;
  border-radius: 3px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 100;
  min-width: 100%;
}
.with-only-icon {
  margin-right: 0 !important;
}
// .minimized {

// }

.#{$ns}-select-option {
  padding: 10px;
  max-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;

  &.#{$ns}-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .#{$ns}-select-icon {
      width: 16px;
    }
  }
}

.#{$ns}-select-left-icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.#{$ns}-select-option:hover {
  background: grey;
  color: white;
}

.#{$ns}-select-empty {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: black;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding: 10px;
}

.#{$ns}-select-empty-temp,
.#{$ns}-select-option-temp {
  position: fixed;
  visibility: hidden;
}

@supports (-webkit-overflow-scrolling: touch) {
  .bp4-popover2-enter-done {
    .#{$ns}-select-popover {
      transform: translateY(125px);
    }
  }
}

// .select-dropdown-portal {
.bp4-popover2-transition-container {
  border-radius: 12px;

  .bp4-popover2 {
    border-radius: 12px;
    width: 100%;
    overflow: hidden;
    box-shadow:
      0px -1px 5px rgba(0, 0, 0, 0.15),
      0px 2px 12px rgba(0, 0, 0, 0.15);

    .bp4-popover2-content {
      .just-options {
        padding: 10px;
      }

      .mpd-select-options {
        box-sizing: border-box;

        .simple-dropdwon {
          padding: 10px;
        }

        .mpd-select-item {
          height: 40px;
          display: flex;
          align-items: center;

          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          /* identical to box height */

          display: flex;
          align-items: center;
          letter-spacing: 0.04em;
          // }
          border-radius: 12px;
        }

        .mpd-select-option {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          align-items: center;
          letter-spacing: 0.02em;
        }

        .mpd-select-option:hover {
          background-color: #1f76ff !important;
          color: white;
          border-radius: 6px;
        }
      }
    }
  }
}

.mpd-select-minimized {
  width: 32px !important;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bp4-popover2 {
  transition-duration: 0.3s !important;
}

.bp4-popover2-exit-active {
  transition-duration: 0.3s !important;
}

.semi-rounded-dropdown {
  border: 1px solid rgba(34, 36, 45, 0.12);
  box-sizing: border-box;
  border-radius: 12px;
  height: 54px;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}

.semi-rounded-dropdown.mpd-active {
  svg.mpd-icon.mpd-select-arrow-icon path {
    stroke: black;
  }
}

.rounded-dropdown {
  border-radius: 100px;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: white;
  max-width: 170px;
  overflow: hidden;

  .mpd-select-label {
    font-size: 14px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .mpd-select-left-wrapper {
    min-width: 32px;
    max-width: 100%;
  }
}

svg.mpd-select-arrow-icon {
  min-width: 14px;
  transition: all 0.2s;
}

.mpd-select.mpd-active {
  svg.mpd-select-arrow-icon:not(.mpd-select-arrow-icon_animation-disabled) {
    transform: rotate(540deg);
  }
}

.mpd-select.mpd-active {
  .mpd-icon.mpd-select-arrow-icon {
    path {
      stroke: #1f76ff;
    }
  }
}

.mpd-select {
  .mpd-select-left-wrapper {
    display: flex;
    align-items: center;
  }
}

.mpd-select-modal {
  .bp4-dialog {
    padding-bottom: 0;
    height: 100%;
    @supports (-webkit-overflow-scrolling: touch) {
      height: -webkit-fill-available;
    }
  }

  .back-button-wrapper {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .mpd-select-options {
    max-height: unset;
    height: 100vh;
  }
}

.rounded-dropdown.only-icon {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg.mpd-icon {
    margin-right: 0;
  }
}

.rounded-dropdown.mpd-select:hover {
  .mpd-select-label {
    color: #1f76ff;
  }

  svg path {
    stroke: #1f76ff;
  }
}

.rounded-dropdown.mpd-select.mpd-active {
  border-width: 1px;
  box-shadow: unset !important;
  .mpd-select-label {
    color: #1f76ff;
  }

  svg path {
    stroke: #1f76ff;
  }

  svg.mpd-select-arrow-icon:not(.mpd-select-arrow-icon_animation-disabled) {
    transform: rotate(540deg);
  }
}
