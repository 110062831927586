@import "../../../../../common/styles";

.settings-social-banter-action {
    &.mpd-active {
        pointer-events: none;
        background-color: #1dd1a1 !important;
    }
}
.add-new-button-wrapper {
    display: flex;
    align-items: center;
}

.settings-social-banter-left-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 20px;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.settings-social-banter-main-info-wrapper {
    display: flex;
    align-items: center;
}

.styled-button.bp4-button.add-new-button {
    min-width: 96px;
    height: 32px;
    background: #1f76ff;
    color: white;
    border: 1.5px solid #1f76ff;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 0 20px;
    .bp4-button-text .mpd-button-text {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #ffffff;
    }
}

.settings-social-banter-description {
    flex: 1;
    padding-left: 25px;
    padding-right: 15px;
    flex-direction: column;
    overflow: hidden;
}

.settings-social-banter-container {
    border-bottom: solid 1px $border-color;
    box-sizing: border-box;
    padding: 30px 40px;
    display: flex;
    align-items: center;
    background-color: $off-white-color;
    user-select: none;
    position: sticky;
    top: 0;
    z-index: 1;
    justify-content: space-between;

    .phoneBlueSmall {
        path {
            fill: white;
        }
    }
}

.settings-social-banter-title {
    font-family: $font-family;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 38px;
    color: #000000;
}

.settings-social-banter-subtitle {
    color: $light-black-color;
    font-family: $font-family;
    font-size: 18px;
    font-weight: 400;
}

.settings-social-banter-sub-title {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.75);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    a {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.75);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
    }
}

@include for-size(phone-only) {
    .settings-social-banter-sub-title {
        text-align: center;
    }
}
