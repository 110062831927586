@include for-size(phone-only) {

    .#{$ns}-container-padding {
        padding: $small_padding;
    }

    .#{$ns}-container-padding-vertical {
        padding-top: $small_padding;
        padding-bottom: $small_padding;
    }

    .#{$ns}-container-padding-top {
        padding-top: $small_padding;
    }

    .#{$ns}-container-padding-bottom {
        padding-bottom: $small_padding;
    }

    .#{$ns}-container-padding-horizontal {
        padding-left: $small_padding;
        padding-right: $small_padding;
    }

    .#{$ns}-container-padding-left {
        padding-left: $small_padding;
    }

    .#{$ns}-container-padding-right {
        padding-right: $small_padding;
    }

    .#{$ns}-container-margin {
        padding: $medium_padding $small_padding;
    }

    .#{$ns}-container-margin-vertical {
        margin-top: $medium_padding;
        margin-bottom: $medium_padding;
    }

    .#{$ns}-container-margin-horizontal {
        margin-left: $small_padding;
        margin-right: $small_padding;
    }

    .#{$ns}-container-margin-bottom {
        margin-bottom: $medium_padding;
    }

    .#{$ns}-container-margin-top {
        margin-top: $medium_padding;
    }

    .#{$ns}-container-margin-left {
        margin-left: $small_padding;
    }

    .#{$ns}-container-margin-right {
        margin-right: $small_padding;
    }

    .#{$ns}-assign-block-margin {
        > * {
            margin-top: $medium_block-padding;
        }
        // > .#{$ns}-row {
        //     &.#{$ns}-responsive {
        //         margin-top: 0;

        //         > .styled-floating-input {
        //             margin-top: 0;
        //         }
        //     }
        // }
    }

    .#{$ns}-assign-block-padding {
        > * {
            padding-top: $medium_block-padding;
        }
        > .#{$ns}-row {
            &.#{$ns}-responsive {
                > .styled-floating-input {
                    &:not(:first-child){
                        margin-top: 0;
                        padding-top: $medium_block-padding;
                    }
                }
            }
        }
    }
    

    .#{$ns}-block-margin-vertical {
        margin-bottom: $small_block-padding;
        margin-top: $small_block-padding;
    }

    .#{$ns}-block-margin-horizontal {
        margin-left: $small_block-padding;
        margin-right: $small_block-padding;
    }

    .#{$ns}-block-margin-bottom {
        margin-bottom: $medium_block-padding;
    }

    .#{$ns}-block-margin-top {
        margin-top: $medium_block-padding;
    }

    .#{$ns}-block-margin-left {
        margin-left: $small_block-padding;
    }

    .#{$ns}-block-margin-right {
        margin-right: $small_block-padding;
    }

    .#{$ns}-block-padding {
        padding: $small_block-padding;
    }

    .#{$ns}-block-padding-vertical {
        padding-bottom: $small_block-padding;
        padding-top: $small_block-padding;
    }

    .#{$ns}-block-padding-horizontal {
        padding-right: $small_block-padding;
        padding-left: $small_block-padding;
    }
    
    .#{$ns}-block-padding-bottom {
        padding-bottom: $small_block-padding;
    }

    .#{$ns}-block-padding-top {
        padding-top: $small_block-padding;
    }

    .#{$ns}-block-padding-left {
        padding-left: $small_block-padding;
    }

    .#{$ns}-block-padding-right {
        padding-right: $small_block-padding;
    }

    .#{$ns}-column {
        > * {
            min-width: initial !important;
            width: 100% !important;
            &:not(:first-child){
                margin-top: $small_padding;
            }
        }
    }

    .#{$ns}-row {
        &.#{$ns}-responsive {
            flex-direction: column;
            > * {
                min-width: initial;
                width: 100%;
                &:not(:first-child){
                    margin-top: $small_padding;
                }
            }
        }
        &:not(.#{$ns}-responsive) {
            > * {
                &:not(:last-child){
                    margin-right: $small_padding;
                }
                :last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .#{$ns}-row-wrap {
        &.#{$ns}-responsive {
            flex-direction: column;
            > * {
                min-width: initial !important;
                width: 100% !important;
                &:not(:first-child){
                    margin-top: $small_padding;
                }
            }
            
        }
        &:not(&.#{$ns}-responsive) {
            margin-top: -$small_padding;
            > * {
                margin-top: $small_padding;
            }
        }
        > * {
            &:not(:last-child){
                margin-right: $large_padding;
            }
            :last-child {
                margin-right: 0;
            }
        }
    }

    @for $i from 1 through $grid-columns {
        .#{$ns}-row, .#{$ns}-column {
            &.#{$ns}-division-#{$i} {
                > * {
                    // flex-basis: calc((100 / #{$grid-columns} * #{$i}) * 1% - #{$small-padding});
                    flex-basis: calc((100 / #{$grid-columns} * #{$i}) * 1%);
                    min-width: 0 !important;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-right: 0;
                }
          }
        }
    }
}