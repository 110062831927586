@import "../common/styles";

.subscription-addons-box {
    user-select: none;
    width: 150px;
    height: 150px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1), 0 -1px 5px rgba(0, 0, 0, 0.02);
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.subscription-addons-box-icon {
    margin: 5px;
}

.subscription-addons-box-title {
    color: black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 0;
}

.subscription-addons-box-subtitle {
    font-family: $font-family;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 0;
    color: $primary-color;
}