@import "../common/styles";

.#{$ns}-list-container {
    height: 100%;
}

.#{$ns}-list-container-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}