@import "../../../../../../../common/styles";

.assign-dropdown {
    &-main-wrapper {
        display: flex;
        flex-direction: column;
        width: 250px;
        height: 280px;
    }

    &-two-people-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background-color: $primary-color;
    }

    &-all-users-item {
        display: flex;
        justify-content: flex-start;
        gap: 0 10px;
    }

    &-all-user-label {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */

        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        flex: 1;
    }

    &-content-wrapper {
        padding: 23px 10px 10px 10px;

        &:nth-child(2) {
            border-top: 1px solid rgba(0, 0, 0, 0.15);
        }

        :global(.empty-block) {
            height: 110px;
            :global(.mpd-icon) {
                margin-bottom: 10px;
            }
            div {
                max-width: unset;

                font-family: $font-family;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                /* identical to box height */

                text-align: center;
                letter-spacing: 0.02em;

                color: $black;
            }
        }

        :global(.empty-block) {
            min-height: 120px;
        }
    }

    &-subtitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: $black;
        margin-bottom: 10px;
    }
}

@include for-size(phone-only) {
    .assign-dropdown {
        &-main-wrapper {
            width: 100%;
            height: calc(100% - 100px);
        }
    }
}
