@import "../../../../../../common/styles";

.root {
    max-width: 100%;
    padding: 0 20px;
    overflow-x: auto;

    .table {
        width: 1080px;
        margin-bottom: 20px;
        border-radius: 12px;

        &__items-wrapper {
            display: flex;
            flex-direction: column;
        }

        &__header-wrapper {
            position: sticky;
            top: 0px;
            z-index: 50;
            background-color: white;
        }

        &__header {
            // border-bottom: 1px solid #d5d5d5;
            height: 50px;
            box-sizing: border-box;
            background-color: #fafafa;
            border-top-right-radius: inherit;
            border-top-left-radius: inherit;
            text-transform: uppercase;
            border-radius: 12px 12px 0 0;

            &__label {
                font-family: $font-family;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.1em;
                color: #000000;
            }
        }

        &__item-grid {
            display: grid;
            grid-template-columns: minmax(170px, 1fr) minmax(300px, 2fr) minmax(270px, 1fr) minmax(240px, 1fr);
            column-gap: 15px;
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            align-items: center;
            border: 1px solid #d5d5d5;
        }

        &__item {
            border-top-width: 0;
            // position: relative;
            // z-index: 1;

            &:last-of-type {
                border-radius: 0 0 12px 12px;
            }
        }
    }
}
