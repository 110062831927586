@import "../common/styles";

.list-subtitle {
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: $black;
    text-transform: uppercase;
}

.settings-shadowed-card-title {
    font-family: $font-family;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 18px;
    color: $black
}

.settings-shadowed-card-subtitle {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: $gray;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-vertical-40 {
    margin: 40px 0;
}

