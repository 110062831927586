@import "../../../../common/styles";

.roles-settings-edit {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.roles-settings-edit-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .bp4-tab-panel {
        flex: 1;
        margin-top: 0;
        display: flex;
        flex-direction: column;
    }
}

.roles-settings-edit-alert-inner-container {
    padding-bottom: 40px;
}

.roles-settings-edit-empty, .roles-settings-list-container-empty {
    flex: 1;
    justify-content: center;
    .title {
        font-size: 22px;
    }
    .subtitle {
        width: 435px;
    }
    .iconName {
        max-width: 60px;
        max-height: 44px;
        width: 60px;
        height: 44px;
    }
}

.roles-settings-list-container-empty {
    height: 100%;
}

.roles-settings-edit-alert-title {
    color: black;
    font-size: 19px;
    font-weight: 700;
    text-align: center;
    user-select: none;
}

.roles-settings-edit-alert {
    min-width: initial !important;
    width: 500px !important;
    max-width: initial !important;
    border-radius: 20px !important;
}

.roles-settings-edit-alert-subtitle {
    color: $primary-color;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    user-select: none;
}

.roles-settings-edit-alert-top {
    position: relative;
    .mpd-icon {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
    }
}



@include for-size(phone-only) {
    .roles-settings-edit {
        .bp4-tab {
            flex: 1;
        }
    }
    .roles-settings-edit-alert {
        max-width: 100vw !important;
    }
}