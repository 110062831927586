@import "../../../common/styles.scss";

.data-export-settings {
    display: inline-grid;
    .mpd-select-label {
        font-size: 14px;
        font-weight: 600;
    }
}

.data-export-settings-alerts-select {
    .mpd-select {
        width: 120px;
    }
}

.data-export-settings-header {
    display: flex;
    align-items: center;
}

.data-export-settings-list {
    padding: 30px 0;
}
