@import "../../../../common/styles.scss";

.role-select-wrapper {
    position: relative;
    margin-top: 0;

    .disabled-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0.7;
        z-index: 11;
    }

    .styled-select {
        margin-top: 0;
    }

    .styled-select .mpd-select {
        border-radius: 6px;
    }
}

.user-settings-add {
    display: flex;
    column-gap: 40px;
    .mpd-column {
        margin: 0;
    }
    &-main-container {
        height: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }

    .user-settings-add-user-info .mpd-assign-block-padding {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    &-footer {
        background: #fafafa;
        display: flex;
        width: 100%;
        min-height: 70px;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        position: sticky;
        z-index: 1;
        bottom: 0;
        column-gap: 20px;
        justify-content: flex-end;
        align-items: center;
        padding: 0 20px;
    }
}

.user-settings-add-team {
    border-radius: 20px;
    background-color: $primary-color;
    color: white;
    text-align: center;
    padding: 3px 13px;
    margin-right: 10px;
    white-space: nowrap;
}

.user-settings-add-teams-header {
    color: $primary-color;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 5px;
}

.user-settings-add-administrator-header {
    color: black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
}

.user-settings-add-administrator {
    display: flex;
    padding: 15px 0;
}

.user-settings-add-label {
    color: $primary-color;
    padding-top: 10px;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.user-settings-add-styled-select {
    transition: 0.5s ease max-height, 0.5s step-end opacity;
    opacity: 1;
    // max-height: 40px;
    height: max-content;
    &.mpd-active {
        transition: 0.5s ease max-height;
        max-height: 0;
        opacity: 0;
        pointer-events: none;
    }
}

.user-settings-add-control-property {
    // border: none;
    padding-left: 3px !important;
}

.user-settings-add-input-container {
    .styled-floating-input:first-child {
        padding-right: 20px;
    }
}

.user-settings-add-input-container {
    display: flex;
}

.user-settings-add-icon-container {
    display: flex;
    padding: 15px 0;
}

.user-settings-add-details-info {
    .settings-control-property {
        align-items: initial;
        border: none;
    }
}

.user-settings-add-control-container {
    padding-bottom: 60px;
}

.user-settings-add-checkbox {
    float: right;
    padding-top: 5px;
    font-size: 11px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    text-align: right;
}

.user-settings-add-checkbox.bp4-control.bp4-align-right .bp4-control-indicator {
    margin-top: -1px;
}

.user-settings-add-user-role-select-block {
    display: flex;
    flex-direction: column;

    .bp4-popover2-target {
        width: 100%;
    }
}

@include for-size(tablet-portrait-up) {
    .user-settings-add-user-role {
        padding-bottom: 60px;
    }
}

@include for-size(phone-only) {
    .user-settings-add-user-role {
        padding-bottom: 60px;
    }
}
