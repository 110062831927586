@import "../common/styles.scss";

.#{$ns}-button {
  flex: 1;
  border: none;
  background: none !important;
  box-shadow: none !important;
  outline: none !important;
  min-width: 96px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent !important;

  &.mpd-align-right {
    .mpd-icon {
      order: 1;
      margin-right: 10px;
    }
  }

  .bp4-button-text {
    display: flex;
    flex: 1;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
  }

  .mpd-button-text {
    font-size: 12px;
  }
}

.#{$ns}-button-url-src {
  width: 30px;
  height: 30px;
}

.#{$ns}-button-text {
  flex: 1;
  font-size: 10px;
}

.#{$ns}-button.bp4-button {
  min-height: 32px;
  padding: 0 15px;
  .mpd-icon.button-left-icon {
    flex-shrink: 0;
    min-width: 17px;
    margin-right: 8px;
  }
  .bp4-spinner {
    margin-right: 5px;
  }
}

.#{$ns}-button.bp4-button.only-icon {
  max-width: 32px;
  max-height: 32px;
  padding: 0 !important;

  .bp4-button-text {
    margin: 0;
  }

  .mpd-icon.button-left-icon {
    margin: 0 !important;
  }

  .bp4-spinner {
    margin: 0;
  }
}

.#{$ns}-button.secondary {
  .bp4-spinner {
    path.bp4-spinner-head {
      stroke: #1f76ff !important;
    }
    path.bp4-spinner-track {
      stroke: rgba(92, 112, 128, 0.2) !important;
    }
  }
}

.#{$ns}-button.primary {
  .bp4-spinner {
    path.bp4-spinner-head {
      stroke: white;
    }
  }

  // .right-icon {
  //   background-color: red;
  // }
}

.#{$ns}-button.delete {
  .bp4-spinner {
    path.bp4-spinner-head {
      stroke: white;
    }
  }
}

.#{$ns}-button.secondary {
  .bp4-spinner {
    path.bp4-spinner-head {
      stroke: black;
    }
  }
}

.#{$ns}-button.only-icon.bp4-button {
  min-width: 32px;
}

@include for-size(phone-only) {
  .#{$ns}-button.only-icon {
    .bp4-button-text {
      // max-width: 16px;
    }
  }
}
