@import "../../../../../common/styles";

.admins-tooltip-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    &:last-child {
        margin-bottom: 0;
    }
}

.admins-tooltip-list-item:global(.bp4-skeleton) {
    height: 40px;
}

.tooltip-avatar {
    width: 20px;
    height: 20px;
    border: unset;
    margin-right: 10px;

    :global(.image-source-initials) {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #ffffff;
    }
}

.tooltip-admin-name {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #ffffff;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 106px;
}
