$ns: "mpd" !default;

$large-padding: 40px;
$medium-padding: 30px;
$small-padding: 20px;

$large-block-padding: 20px;
$largest-block-padding: 40px;
$medium-block-padding: 15px;
$small-block-padding: 10px;

$input-padding: 10px;

$grid-columns: 12;

$bpns: "bp4";
