.fade-from-top-enter {
    opacity: 0;
    transform: translateY(-500px);
}

.fade-from-bottom-enter {
    opacity: 0;
    transform: translateY(500px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 0.3s ease-in;
}

.fade-exit {
    transform: translateY(0%);
    opacity: 1;
}

.fade-exit-to-bottom-active {
    transform: translateY(500px);
    opacity: 0;
    transition: all 0.3s ease-in;
}

.fade-exit-to-top-active {
    transform: translateY(-500px);
    opacity: 0;
    transition: all 0.3s ease-in;
}
