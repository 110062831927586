.account-branding-agency-details {
    .account-branding-agency-details-item-container {
        row-gap: 25px;
        display: flex;
        flex-direction: column;
    }
}

.account-branding-details-info-item-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
