@import "../common/styles.scss";

.styled-switch {
    color: rgba(0, 0, 0, 0.5);
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    margin: 0 !important;
    height: 22px;
    .bp4-control-indicator {
      width: 42px !important;
      // outline: none !important;
      height: 22px !important;
      border-radius: 18px !important;
      // order: 1;
    }

    input:checked ~ .bp4-control-indicator {
      background-color: $primary-color !important;
    }

    input:checked ~ .bp4-control-indicator:before {
      left: 22px !important;
    }

    .bp4-control-indicator:before {
      height: 17px !important;
      width: 17px !important;
    }
    
    // &.mpd-social {
    //   color: rgba(0, 0, 0, 0.5);
    //   font-family: $font-family;
    //   font-size: 14px;
    //   font-weight: 400;
    //   margin-bottom: 0;
    //   display: flex !important;
    //   flex-direction: column;
    //   margin: 0 !important;
    //   padding: 20px 0;
    //   white-space: nowrap;
    //   .bp4-control-indicator {
    //     width: 42px !important;
    //     outline: none !important;
    //     height: 22px !important;
    //     border-radius: 18px !important;
    //     margin-left: -3px !important;
    //     margin-bottom: 10px;
    //   }
    //   input:checked ~ .bp4-control-indicator {
    //     background-color: $primary-color !important;
    //   }
    //   input:checked ~ .bp4-control-indicator:before {
    //     left: 22px !important;
    //   }
    //   .bp4-control-indicator:before {
    //     height: 17px !important;
    //     width: 17px !important;
    //   }
    // }
}