@import "../components/common/colors.scss";

#app-container {
  display: flex;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent !important;
}

.global-spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

body {
  -webkit-tap-highlight-color: black;
  position: relative;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  // overflow-x: hidden;
  margin: 0;
  // overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  height: 100vh;
  height: -webkit-fill-available;
  overflow: hidden;
}

#root {
  height: 100%;
  overflow: auto;
}

.app-controller {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
  overflow-x: hidden;
  background-color: #fafafa;
}

*:focus {
  outline: none !important;
}

// input:autofill,
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:active {
//   -webkit-box-shadow: 0 0 0 30px #fafafa inset !important;
// }

// input:-webkit-autofill:focus {
//   -webkit-box-shadow: 0 0 0 30px transparent inset !important;
// }

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   border: 1px solid green;
//   -webkit-text-fill-color: green;
//   -webkit-box-shadow: 0 0 0px 1000px #000 inset;
//   transition: background-color 5000s ease-in-out 0s;
// }

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@supports not (-webkit-overflow-scrolling: touch) {
  #app-container {
    height: 100vh;
  }
}

.bp4-popover2 .bp4-popover2-arrow::before {
  display: none;
}

.bp4-popover2 .bp4-popover2-content {
  // border-radius: 12px;
  overflow: hidden;
}

.bp4-popover2 .bp4-popover2-arrow {
  svg {
    // display: none;
  }
}

.bp4-popover2 {
  border-radius: 12px;
  overflow: hidden;
}

.bp4-spinner {
  .bp4-spinner-animation {
    .bp4-spinner-head {
      stroke: $primary-color;
    }
    width: fit-content;
  }
}

.bp4-dialog {
  background-color: white !important;
}
