@import "../../../../../../../common/styles";

.sms-voice-channel-edit {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    &-form {
        padding: 40px;
        display: flex;
        flex-direction: row;
        column-gap: 50px;
        flex: 1;
        flex-grow: 1;

        row-gap: 25px;

        h4 {
            &:nth-of-type(2) {
                margin: 25px 0 10px 0;
            }
        }

        .form-part {
            display: flex;
            flex-direction: column;
            flex: 1;
            max-width: 505px;
        }

        :global(.alert-replies-settings-wrapper) {
            display: flex;
            flex-direction: column;
            row-gap: 35px;
            max-width: 493px;
        }
    }

    :global(.styled-floating-input) {
        max-width: 560px;
    }

    &-devider-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: #000000;
        margin: 0;
        margin-bottom: 45px;
    }
}
