@import "../../../../common/styles";

.root {
    max-width: 480px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.radio-option-label {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    .option-title {
        color: rgba(0, 0, 0, 0.75);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 157.143% */
        letter-spacing: 0.28px;
    }

    .option-description {
        color: rgba(0, 0, 0, 0.5);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
    }
}

.select-list-type-title {
    color: #000;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin: 0;
}

:global(.styled-radio-group.restyled-radio-group) {
    .radio:global(.bp4-control.bp4-radio) {
        align-items: flex-start !important;

        & > input ~ span:global(.bp4-control-indicator) {
            background-color: white;
            min-width: 24px;
            height: 24px;
            border: 1px solid rgba(0, 0, 0, 0.15) !important;
            box-shadow: unset;

            &::before {
                width: 8px !important;
                height: 8px !important;
                background-color: white !important;
                border-radius: 100%;
            }
        }

        & > input:checked ~ span:global(.bp4-control-indicator) {
            background-color: $primary-color !important;
            border: unset !important;
            ::before {
                width: 8px !important;
                height: 8px !important;
                background-color: white !important;
                border-radius: 100%;
            }
        }
    }
}
