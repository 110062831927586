@import "../../../../common/styles";

.teams-settings-add {
    width: 50%;
}

@include for-size(tablet-portrait-up) {
    .teams-settings-add {
        width: initial;
    }
}

@include for-size(phone-only) {
    .teams-settings-add {
        width: initial;
    }
}