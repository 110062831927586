.#{$ns}-container-inputs-row-gap {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.#{$ns}-card-box-shadow {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

@include for-size(tablet-landscape-up) {
    .#{$ns}-container-padding {
        padding: $large_padding;
    }

    .#{$ns}-align-center {
        align-items: center;
    }

    .#{$ns}-justify-center {
        justify-content: center;
    }

    .#{$ns}-container-padding-vertical {
        padding-top: $large_padding;
        padding-bottom: $large_padding;
    }

    .#{$ns}-container-padding-top {
        padding-top: $large_padding;
    }

    .#{$ns}-container-padding-bottom {
        padding-bottom: $large_padding;
    }

    .#{$ns}-container-padding-horizontal {
        padding-left: $large_padding;
        padding-right: $large_padding;
    }

    .#{$ns}-container-padding-left {
        padding-left: $large_padding;
    }

    .#{$ns}-container-padding-right {
        padding-right: $large_padding;
    }

    .#{$ns}-container-margin {
        margin: $large_padding;
    }

    .#{$ns}-container-margin-vertical {
        margin-top: $large_padding;
        margin-bottom: $large_padding;
    }

    .#{$ns}-container-margin-horizontal {
        margin-left: $large_padding;
        margin-right: $large_padding;
    }

    .#{$ns}-container-margin-bottom {
        margin-bottom: $large_padding;
    }

    .#{$ns}-container-margin-top {
        margin-top: $large_padding;
    }

    .#{$ns}-container-margin-left {
        margin-left: $large_padding;
    }

    .#{$ns}-container-margin-right {
        margin-right: $large_padding;
    }

    .#{$ns}-assign-block-margin {
        > * {
            margin-top: $large_block-padding;
        }
    }

    // .#{$ns}-assign-block-padding {
    //     > * {
    //         padding-top: $large_block-padding;
    //     }
    // }

    .#{$ns}-block-margin-vertical {
        margin-bottom: $large_block-padding;
        margin-top: $large_block-padding;
    }

    .#{$ns}-block-margin-horizontal {
        margin-left: $large_block-padding;
        margin-right: $large_block-padding;
    }

    .#{$ns}-block-margin-bottom {
        margin-bottom: $large_block-padding;
    }

    .#{$ns}-block-margin-top {
        margin-top: $large_block-padding;
    }

    .#{$ns}-block-margin-left {
        margin-left: $large_block-padding;
    }

    .#{$ns}-block-margin-right {
        margin-right: $large_block-padding;
    }

    .#{$ns}-block-padding {
        padding: $large_block-padding;
    }

    .#{$ns}-block-padding-vertical {
        padding-bottom: $large_block-padding;
        padding-top: $large_block-padding;
    }

    .#{$ns}-block-padding-horizontal {
        padding-right: $large_block-padding;
        padding-left: $large_block-padding;
    }

    .#{$ns}-block-padding-bottom {
        padding-bottom: $large_block-padding;
    }

    .#{$ns}-block-padding-top {
        padding-top: $large_block-padding;
    }

    .#{$ns}-block-padding-left {
        padding-left: $large_block-padding;
    }

    .#{$ns}-block-padding-right {
        padding-right: $large_block-padding;
    }

    .#{$ns}-column {
        > * {
            &:not(:first-child) {
                margin-top: $large_padding;
            }
        }
    }

    .#{$ns}-row {
        > * {
            &:not(:last-child) {
                margin-right: $large_padding;
            }
        }
        &.#{$ns}-responsive {
            > * {
                flex: 1;
            }
        }
    }

    .#{$ns}-row-wrap {
        margin-top: -$large_padding;
        margin-right: -$large_padding;
        > * {
            margin-top: $large_padding;
            margin-right: $large_padding;
        }
        &.#{$ns}-responsive {
            > * {
                flex: initial;
            }
        }
    }

    @for $i from 1 through $grid-columns {
        .#{$ns}-row,
        .#{$ns}-column {
            &.#{$ns}-division-#{$i} {
                > * {
                    flex-basis: calc((100 / #{$grid-columns} * #{$i}) * 1%);
                    min-width: 0 !important;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-right: 0;
                }
            }
        }
    }
}
