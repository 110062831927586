@import "../../../common/styles.scss";

.role-settings {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    .settings-select-wrapper {
        min-width: initial;
    }
}

.role-settings-empty {
    flex: 1;
    justify-content: center;
    align-items: center;
    .title {
        font-size: 22px;
    }
    .subtitle {
        color: $light-black-color;
        font-size: 16px;
    }
}

.role-settings-inner-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.role-settings-settings-groups-list-item {
    .settings-groups-list-item-select {
        .mpd-select {
            margin-left: 53px;
        }
    }
}

@include for-size(phone-only) {
    .role-settings .settings-select-wrapper {
        margin-left: 20px;
        width: 40px;
    }
}