@import "../../../../common/styles";

.api-request-log {
    display: flex;
    width: 100%;
    height: calc(100vh - 238px);
    flex-direction: column;
    overflow: auto;
    padding: 0 0 40px 0;

    table {
        width: 100%;
    }

    &-grid-row {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 2fr 3fr 2fr;
        min-height: 60px;
        align-items: center;
    }

    &-list {
        margin: 0;
        padding: 0;
        // height: calc(100% - 20px);
        height: 100%;
        // overflow: auto;
    }
}
