@import "../../../../common/styles";

.header {
    background-color: #fafafa;
    cursor: unset;
    &__label {
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 150% */
        letter-spacing: 1.2px;
    }
}

.root {
    display: grid;
    grid-template-columns:
        24px minmax(0, 2fr) 100px 100px minmax(100px, 2fr) minmax(100px, 2fr)
        32px;
    padding: 0 20px;
    column-gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    height: 60px;
    align-items: center;
    min-width: 1080px;
    width: 100%;

    &:not(.header):hover {
        background-color: #f4f8ff;
        cursor: pointer;
    }

    :global(.bp4-skeleton) {
        height: 18px;
        width: 100%;
        border-radius: 12px;
    }

    .label {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;

        display: flex;
        column-gap: 10px;
        align-items: center;
    }

    .name {
        font-size: 16px;
    }

    .checkbox {
        width: 24px;
        height: 24px;
        :global(.bp4-control-indicator) {
            min-width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                height: 24px;
                width: 24px;
            }
        }
    }

    .count {
        color: $primary-color;
    }

    .admin-avatar {
        width: 28px;
        height: 28px;
    }
    .more-dropdown-wrapper {
        position: sticky;
        right: 20px;
    }
}
