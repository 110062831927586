@import "../common/styles";

.settings-control-property {
    border-bottom: 1px solid $border-color;
    user-select: none;
    display: flex;
    align-items: center;
    padding-left: 0 !important;
    padding-bottom: 10px;
}
.settings-control-property-left {
    display: flex;
    flex-direction: column;
}

.settings-control-property-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
}

.settings-control-property-title,
.settings-control-property-left-title {
    white-space: nowrap;
    width: 170px;
    color: black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
}

.settings-control-property-sub-title,
.settings-control-property-left-sub-title {
    color: rgba(0, 0, 0, 0.5);
    font-family: $font-family;
    font-size: 16px;
    font-weight: 400;
    margin-top: 12px;
    padding-left: 3px;
}
.settings-control-property-left-switch-Wrap {
    display: flex;
}
