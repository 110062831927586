@import "../common/styles";

.bread-crumbs-bread-crumb-container {
    display: flex;
    align-items: center;
    // flex: 1;
    overflow: hidden;
    min-width: 32px;
}

.bread-crumbs-button {
    padding: 0 !important;
    .mpd-button-text {
        text-transform: capitalize;
        font-family: $font-family;
        font-size: 16px !important;
        line-height: 22px;
        font-weight: 700 !important;
        color: rgba(0, 0, 0, 0.5);
        white-space: nowrap;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.mpd-active {
        pointer-events: none;
        .mpd-button-text {
            color: black;
        }
    }
}

.bread-crumbs {
    display: flex;
    align-items: center;
    overflow: hidden;
    min-width: 32px;

    &-divider {
        margin: 0 7px;
    }
}

.bread-crumbs-divider {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
    user-select: none;
}
