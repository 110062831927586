@import "../../../common/styles.scss";

.group-settings {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.group-settings-empty {
    height: 100%;
    justify-content: center;
    .title {
        font-size: 22px;
    }
    .subtitle {
        color: $light-black-color;
        font-size: 16px;
    }
}

.group-settings-container-empty, .group-settings-list-container-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    .title {
        font-size: 22px;
    }
    .subtitle {
        font-size: 16px;
        color: $light-black-color;
    }
}

.group-settings-list-container-empty {
    height: 100%;
}