@import "../../../../common/styles";


.subscription-settings-overview-addons {
    margin-top: 40px;
    .settings-title {
        margin-bottom: 40px;
    }
}

.subscription-settings-overview-addons-container {
    .subscription-addons-box {
        flex-shrink: 0;
        flex-grow: initial !important;
        height: 150px;
        width: 150px;
    }
}

.subscription-settings-overview-subscription-box {
    height: 450px;
    flex: 1 !important;
}

.subscription-settings-overview-subscription-box-description {
    padding-left: 20px;
}


.subscription-settings-overview-subscription-box-description-title {
    color: $primary-color;
    font-family: $font-family;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 10px;

}

.subscription-settings-overview-subscription-box-description-subtitle {
    color: $lighter-black-color;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    text-align: right;
}

.subscription-settings-overview-content {
    padding: 40px;
}

@include for-size(tablet-landscape-up) {
    .subscription-box:last-child {
        margin-right: 0;
    }
    .subscription-box {
        flex: 1;
    }
}

@include for-size(tablet-portrait-up) {


}

@include for-size(phone-only) {


}