@import "../../../../../../../../components/common/styles";

.main-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    max-width: 560px;
}

.checkbox-wrapper {
    display: flex !important;
    // column-gap: 20px;

    :global(.styled-checkbox) {
        width: 24px;
    }

    :global(.bp4-control-indicator) {
        margin-right: 0;
        width: 24px !important;
        height: 24px !important;
        margin-top: 4px;
        margin-right: 20px;

        &:before {
            height: 24px !important;
            width: 24px !important;
        }
    }
}

.checkbox-description {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    &__title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: #000000;
    }

    &__subtitle {
        font-family: $font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
    }
}
