@import "../../../common/styles";

.notifications-item {
    padding: 20px;
    width: 375px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &-type-date-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .checkbox {
        span::before {
            width: 24px;
            height: 24px;
        }
    }

    .from-now {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        text-align: right;
        color: #1f76ff;
    }

    @include notification;

    &.unread {
        .icon-wrapper {
            background-color: $primary-color;
        }

        .account-channel-offline {
            background-color: #ff1f70;
        }

        .icon-wrapper.settings-mode-icon-wrapper {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &:hover {
        background-color: #f4f8ff;

        .icon-wrapper {
            background-color: #f4f8ff;
        }

        .icon-wrapper.settings-mode-icon-wrapper {
            background-color: #f4f8ff;
        }
    }

    :global(.bp4-control.bp4-checkbox input:checked ~ .bp4-control-indicator::before) {
        margin: 0;
        width: 100%;
        height: 100%;
    }

    &-main-info-wrapper {
        width: 100%;
    }

    .event-type {
        text-transform: uppercase;
    }

    &-type-date-wrapper:global(.bp4-skeleton) {
        margin-bottom: 10px;
        height: 22px;
        width: 100%;
    }

    .event-title:global(.bp4-skeleton) {
        height: 38px;
        width: 100%;
    }

    .event-description:global(.bp4-skeleton) {
        height: 45px;
        width: 100%;
    }

    .icon-wrapper {
        background-color: #bfbfbf;

        :global(.stop-propagation-block) {
            max-width: 24px;
        }

        :global(.mpd-icon) {
            width: 24px;
            height: 24px;
        }
    }

    .settings-mode-icon-wrapper {
        background-color: white;
    }

    :global(.bp4-control.bp4-checkbox.styled-checkbox) {
        padding: 0;

        :global(.bp4-control-indicator) {
            width: 24px;
            height: 24px;
            margin: 0;
        }
    }
}

@include for-size(phone-only) {
    .notifications-item {
        max-width: unset;
        width: 100%;
    }
}
