@import "../common/styles";

.right-panel-container {
    flex: 4;
    background-color: white;
    box-shadow: 3px 0 16px 1px rgba(0, 0, 0, 0.12);
    border-radius: 20px 0 0 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    
    &.mpd-expanded {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    &.mpd-loading { 
        pointer-events: none;
        opacity: 0.6;
    }
}


// right-panel-container-loader-wrapper mpd-active

@keyframes changewidth {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
}


.right-panel-container-loader-moving-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 1.75s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-name: changewidth;
    display: none;
    z-index: 1;
    &.mpd-active {
      display: initial;
    }
}

.right-panel-container-loader-moving {
    position: absolute;
    width: 389px;
    height: 2px;
    top: 49px;
    background-color: $primary-color;
    &.mpd-active {
      transform: translateX(0%);
    }
  }

.right-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
}

@include for-size(phone-only) {
    .right-panel-container {
        border-radius: 0;
    }
}
  
@supports (-webkit-overflow-scrolling: touch) {
    .right-panel-container {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: fixed;
    }
}

