@import "../../../../common/styles.scss";

.group-settings-edit-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.group-settings-edit-container-empty {
    justify-content: center;
    flex: 1;
    .title {
        font-size: 22px;
    }
    .subtitle {
        font-size: 16px;
        color: $light-black-color;
        width: 435px;
    }   
}
