@import "../../../../../common/styles";

.smart-link-edit {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    :global(.settings-social-banter-container) {
        flex-shrink: 0;
        :global(.settings-social-banter-main-info-wrapper) {
            width: 50%;
        }

        :global(.settings-social-banter-left-wrapper) {
            flex-wrap: wrap;

            & > div {
                display: flex;
                flex: 1 1 auto;
            }
        }
    }

    :global(.bp4-tab-list) {
        background-color: #fafafa;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
    }

    :global(.bp4-tabs) {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    }

    :global(.bp4-tab) {
        // max-width: 120px;
        height: 50px;
        align-items: center;
        line-height: 27px;
    }
    &-tab-panel {
        max-width: unset;
        width: unset;
        margin-top: 0;
        height: 100%;
        overflow: auto;
    }
    &-main-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-form {
        display: flex;
        flex-direction: column;
        max-width: 640px;
        padding: 40px 40px 40px 40px;

        h4:first-of-type {
            margin-top: 0;
        }

        h4 {
            margin: 35px 0;
            font-family: $font-family;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.1em;
            color: #000000;
        }

        :global(.step-inputs-wrapper) {
            display: flex;
            flex-direction: column;
            row-gap: 35px;
            margin-top: 0;

            // :global(.styled-floating-input.primary) {
            //     margin-top: 0 !important;

            //     :global(.mpd-floating-input-container) {
            //         margin-top: 0 !important;
            //     }
            // }
        }

        :global(.alert-replies-settings-wrapper) {
            display: flex;
            flex-direction: column;
            row-gap: 35px;
        }
    }
}

@media screen and (max-width: 1500px) {
    .smart-link-edit {
        :global(.settings-social-banter-container) {
            :global(.settings-social-banter-main-info-wrapper) {
                width: 40%;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .smart-link-edit {
        :global(.settings-social-banter-container) {
            :global(.settings-social-banter-left-wrapper) {
                width: 100%;
            }
        }
    }
}
