@import "../common/styles";

.datepicker {
    // width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    // font: $font-family;
    font-family: $font-family;

    :global(.DayPicker) {
        :global(.DayPicker-Day.DayPicker-Day--selected) {
            background-color: $primary-color;
        }
        // width: 380px;
        // height: 380px;

        :global(.DayPicker-wrapper) {
            // width: 100%;
            height: 100%;

            :global(.DayPicker-Months) {
                // width: 100%;
                height: 100%;

                :global(.DayPicker-Month) {
                    // width: 100%;
                    height: 100%;
                    :global(.DayPicker-Day):hover {
                        background-color: $primary-color;
                        color: white;
                    }

                    :global(.DayPicker-Day) {
                        font-weight: 600;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}
