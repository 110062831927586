@import "../../../common/styles.scss";

.security-settings-control-property-container {
    display: flex;
    flex-direction: column;
}

.security-settings-control-property-title {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: solid 1px $border-color;
}

.security-settings-control-property {
    align-items: initial;
    border: none;
    .settings-control-property-title {
        width: initial;
    }
}