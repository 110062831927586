@import "../../../../../../common/styles";

.root {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    width: 100%;
    align-items: center;

    .dropzone {
        border: 2px dashed #cad8e5;
        border-radius: 12px;
        height: 200px;
        width: 520px;

        display: flex;
        align-items: center;
        column-gap: 40px;
        justify-content: center;
        cursor: pointer;

        &:hover {
            border-color: $primary-color;
            background-color: #f4f8ff;
        }

        .label-and-button {
            display: flex;
            column-gap: 15px;
            align-items: center;

            &__title {
                font-family: $font-family;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 100%;
                text-align: center;
                letter-spacing: 0.02em;
                color: #000000;
            }
        }
    }

    .need-help {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 5px;

        &__title {
            font-family: $font-family;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;

            text-align: center;

            color: #000000;
        }

        &__button {
            font-weight: 600;
        }
    }

    .file-card {
        height: 88px;
        width: 520px;
        padding: 0 20px;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        column-gap: 20px;

        &:hover {
            background: #fafafa;
            border: 1px solid rgba(0, 0, 0, 0.15);
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
            border-radius: 12px;

            .file-card__remove-button {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                border: unset;
                padding: 0;
                cursor: pointer;

                &:hover {
                    background-color: $primary-color;
                }
            }
        }

        &__main-info {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            justify-content: center;
            &__name {
                font-family: $font-family;
                font-style: normal;
                font-weight: 800;
                font-size: 16px;
                line-height: 18px;

                color: #000000;
            }

            &__size {
                font-family: $font-family;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: #808080;
            }
        }

        &__remove-button {
            display: none;
            background-color: #bfbfbf;
            border-radius: 100%;
            width: 24px;
            height: 24px;
            margin-left: auto;
        }
    }
}
