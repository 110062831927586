@import "../common/styles";

.image-source {
    width: 50px;
    height: 50px;
}

.image-source-filler {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: grey;
    display: none;
}

.image-source-src {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.image-source-initials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: $font-family;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #4a90e2;
    font-weight: 600;
    text-transform: uppercase;
    cursor: default;
    line-height: 100%;
    white-space: nowrap;
}

.before-avatar-loaded-wrapper {
    background: #eeeeee;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    .mpd-icon {
         path {
            fill: rgba(0, 0, 0, 0.25);
        }
    }
}
