.ancestor {
    // flex: 1;
    // max-width: 20%;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.tooltip-content {
    display: flex;
    column-gap: 7px;
    align-items: center;

    & > span {
        font-weight: 600;
    }

    .chevron-right {
        height: 10px;
    }
}

.tag-dropdown-item {
    .chevron-right {
        height: 9px;
    }

    &:hover .chevron-right path {
        stroke: white;
    }

    .chevron-right path {
        stroke: black;
    }
}
