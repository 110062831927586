@import "../common/styles";

.user-onboarding {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.user-onboarding-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-onboarding-image-container {
    flex: 1;
    &.hide {
        display: none;
    }
}

.user-onboarding-icon {
    margin-top: 15%;
    display: flex;
    justify-content: center;
}

.user-onboarding-logo {
    z-index: 1;
    background: white;
    max-height: 130px;
    min-height: 130px;
    display: flex;
    align-items: center;
    color: #003657;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 400;
    margin-left: -20px;
    .user-onboarding-icon-text {
        margin-left: 10px;
    }
}

.user-onboarding-bottom-navigation-bar {
    width: initial !important;
    border: none;
    padding-left: 0 !important;
    .bp4-align-right {
        .mpd-button-text {
            margin: 0;
        }
        .actions-button {
            margin: 0;
        }
    }
    
}

.user-onboarding-content-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    flex: 1;
    &.hide {
        padding-left: 0;
    }
}

.user-onboarding-stepper {
    // margin-bottom: 20px;
    min-width: 300px;
    pointer-events: none;
    flex-shrink: 0;
}

.user-onboarding-inner-container {
    padding: 0 70px !important;
    flex: 1;
    display: flex;
    overflow: hidden;
    &.full-screen {
        padding: 0 !important;
    }
}

.user-onboarding-inner-row {
    display: flex;
}

.user-onboarding-bottom-navigation-bar {
    margin-bottom: 25px;
}

.user-onboarding-finish {
    background-color: $primary-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}

.user-onboarding-finish-text {
    color: white;
    font-family: $font-family;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 20px 0;
    user-select: none;
}

.user-onboarding-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
    // margin-top: 100px;
}

.user-onboarding-title {
    color: $navy-color;
    font-family: $font-family;
    font-size: 36px;
    font-weight: 600;
    user-select: none;
    text-align: center;
}

.user-onboarding-subtitle {
    color: $navy-color;
    font-family: $font-family;
    font-size: 18px;
    font-weight: 400;
    user-select: none;
    text-align: justify;
    padding: 10px 0;
    text-align: center;
}

.user-onboarding-body-logo {
    align-self: center;
    background-color: rgba(31, 118, 255, 0.15);
    width: 120px;
    height: 121px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
}

.user-onboarding-body-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .image-upload {
        flex-direction: column;
    }
    .image-upload-icon-filler {
        width: 120px;
        height: 120px;
        border: none;
        background: none;
    }
    .image-upload-icon-image {
        width: 120px;
        height: 120px;
    }
    .image-upload-icon-filler-image {
        margin: 10px 0;
    }
}

.user-onboarding-icon-text {
    align-self: center;
    padding: 10px 0 !important;
    color: $primary-color;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}

.user-onboarding-styled-floating-input {
    height: 100%;
}

.user-onboarding-styled-select {
    .mpd-select {
        padding-top: 3px;
        margin-right: 3px;
    }
}

.user-onboarding-body-form-row {
    .styled-floating-input, .user-onboarding-styled-select {
        flex: 1;
    }
}

.user-onboarding-bottom-bar {
    align-self: flex-end;
    padding: 30px 60px;
}

@include for-size(tablet-portrait-up) {
    .user-onboarding-image-container {
        display: none;
    }
    .user-onboarding-logo {
        display: none;
    }
}

@include for-size(phone-only) {
    .user-onboarding-inner-container {
        display: initial;
        padding: 0 !important;
        overflow: hidden !important;
    }

    .user-onboarding-content-container {
        height: 100%;
        // margin-top: 100px;
    }

    .user-onboarding {
        max-width: 100vw;
    }

    .user-onboarding-image-container {
        display: none;
    }

    .user-onboarding-logo {
        display: none;
    }

    .user-onboarding-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 25px;
    }

    .user-onboarding-content-container {
        padding: 0;
        &.hide {
            position: fixed;
            right: 0;
            left: 0;
            bottom: 0;
            top: 0;
        }
    }

    .user-onboarding-scroll-container {
        overflow: initial;
        padding: 0 25px;
    }

    .user-onboarding-stepper {
        background: white;
        z-index: 100;
    }

    .user-onboarding-bottom-navigation-bar {
        border-top: solid 1px $border-color;
        padding: 5px 25px 0 25px !important;
        margin-bottom: 10px;
    }
}