@import "../../../../common/styles";

.right-api-key-item {
    display: flex;
    align-items: center;

    &-value {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #000000;
        margin-right: 40px;
    }

    &-buttons-wrapper {
        margin-top: 10px;
    }

    &-actions-select-target {
        width: 114px;
        display: flex;
        height: 32px;
    }

    &-black-dot {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0.75);
        margin-right: 3px;
    }

    &-black-dot-inactive {
        opacity: 0.15;
    }

    &-popover {
        padding: 10px;
        background-color: white;
        min-width: 220px;
    }

    // :global(.mpd-select) {
    //     background-color: white;
    // }

    :global(.mpd-select-options),
    :global(.mpd-select-item) {
        box-shadow: unset !important;
    }

    &-enable-button {
        :global(.bp4-button-text) {
            :global(.bp4-spinner) {
                max-width: 20px;
            }
        }
    }

    &-output {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // margin-right: 40px;

        :global(.styled-button) {
            padding: 0 !important;
            min-width: unset;
            margin-right: 10px;
            :global(.mpd-button-text) {
                text-align: left;
            }
        }
    }
}
