.create-contact-form {
    display: grid;
    grid-template-columns: 270px 270px;
    column-gap: 20px;
    row-gap: 30px;
}

.selected-tags {
    display: flex;
    column-gap: 5px;
    align-items: center;
}
