@import "../common/styles";

.channels-status-label {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;

    &::before {
        position: relative;
        display: inline-block;
        content: "";
        -webkit-border-radius: 100%;
        border-radius: 100%;
        height: 8px;
        width: 8px;
        margin-right: 9px;
        background-color: #0ce649;
        top: -1px;
    }
}

.channels-status-label-offline {
    color: #ff1f70;
    &::before {
        background-color: #ff1f70;
    }
}
.channels-status-label-connecting {
    color: $form-lable-color;
    &::before {
        background-color: $form-lable-color;
    }
}

.channels-status-label-online {
    color: #0ce649;
    &::before {
        background-color: #0ce649;
    }
}
