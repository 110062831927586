@import "../../common/styles";

.contacts-list.primary {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .list-wrapper {
        height: 100%;
        width: 100%;
    }

    table {
        width: 100%;
    }

    .no-results table {
        height: 60px;
    }

    .contacts-list-table {
        flex-grow: 1;
    }

    :global(.empty-block) {
        display: flex;
    }

    .header {
        display: flex;
        flex-direction: column;
        min-height: max-content;
        background: #fafafa;
        padding: 9px 20px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        row-gap: 15px;
    }

    .preview-warning {
        display: none;
    }
}

.contacts-list.preview {
    width: 100%;
    max-width: 1082px;
    box-sizing: border-box;
    row-gap: 20px;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        justify-content: center;
        background-color: white;

        :global(.stop-propagation-block) {
            display: none;
        }
    }

    :global(.stop-propagation-block) {
        display: none;
    }

    .list-wrapper {
        border-radius: 12px;
        border: 1px solid #d5d5d5;
        overflow: hidden;
        min-height: 400px;

        display: flex;
        flex-direction: column;
    }

    .preview-warning {
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f4f8ff;
        border-bottom: 1px solid #d5d5d5;
        column-gap: 10px;

        &_bold {
            color: #000;
            font-size: 19px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
        }

        &_thin {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }
    }
}
