@import "../../../../common/styles.scss";

.settings-groups-list-item-container:hover {
    background: rgba(0,0,0,0.01);
}

.settings-groups-list-item-container {
    display: flex;
    border-bottom: solid 1px $border-color;
    align-items: center;
    padding: 10px 15px;
    height: 60px;
    flex-shrink: 0;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    .mpd-select-label {
        color: $off-color;
    }
    .mpd-select-container {
        width: 100%;
    }
    .no-line-blue {
        height: 40px;
        .mpd-select {
            min-width: 100px;
            height: 40px;
            padding: 0px 20px 0px 15px;
        }
        .mpd-select-label {
            margin-right: 20px;
        }
    }
    .groups-list-item-container {
        border-bottom: 0;
        padding: 0;
        flex: 1;
        background: initial;
    }
   
    .settings-select-wrapper {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .settings-groups-list-item-select {
            background: transparent;
        }
    }
    .mpd-select-text-container {
        color: $off-color;
    }
    &.mpd-active {
        .settings-social-account-list-item-title {
            color: black;
        }
        .mpd-select-label {
            color: $primary-color;
        }
    }
}

.settings-groups-list-item-select {
    .mpd-select-label {
        font-size: 12px;
    }
}

.settings-groups-list-item-select-popover {
    .mpd-select-option {
        text-transform: capitalize;
    }
    .mpd-select-options {
        transform: translateX(20px) !important;
    }
}

.settings-groups-list-item-left-loading-wrapper {
    flex: 1;
    align-self: center;
    height: 10px;
}

.settings-groups-list-item-left-loading {
    width: 100px;
    border-radius: 10px;
    height: 100%;
}

.settings-groups-list-item-right-loading {
    width: 50px;
    height: 10px;
    align-self: center;
    border-radius: 10px;
}


@include for-size(tablet-portrait-up) {
    .settings-groups-list-item-container {
        .settings-select-wrapper {
            margin-left: 0;
            .label {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 60px;
            }
            .mpd-select-inner-container {
                min-width: initial
            }
        }
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    .bp4-popover2-enter-done {
        .settings-groups-list-item-select-popover {
            .mpd-select-options {
                margin-top: -125px;
            }
        }
    }
}