@import "../../../../../common/styles.scss";

.audit-log-settings-list-header {
    display: flex;
    border-bottom: solid 1px $border-color;
    height: 50px;
    align-items: center;
    padding: 0 40px;
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 10;
    min-width: 800px;
    overflow-x: auto;
}

.audit-log-settings-list-header-inner-container {
    display: flex;
}

.audit-log-settings-list-header-item {
    white-space: nowrap;
    display: flex;
    user-select: none;
    color: black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    flex: 1;
    width: 150px;
    min-width: 150px;
    &.activity {
        padding: initial;
    }
    &.browser{
        min-width: 70px;
        overflow-x: auto;
    }
}

@include for-size(phone-only) {
    .audit-log-settings-list-header {
        padding: 10px 18px;
    }

    .audit-log-settings-list-header-inner-container {
        width: 50%;
    }

    .audit-log-settings-list-header-item {
        width: 65%;
        overflow: hidden;
    }
    
    .audit-log-settings-list-header-inner-container {
        width: 33%;
        overflow: hidden;
    }

    .audit-log-settings-list-header-item {
        &.ip,
        &.browser{
            display: none;
        }
    }
}
