.root {
    height: 100%;
    display: flex;
    height: calc(100% - 50px);
    z-index: 10;
    position: relative;

    .tabs {
        // width: 100%;
        height: 100%;
        flex: 2;
        display: flex !important;
        flex-direction: column;

        :global(.bp4-tab) {
            width: 120px;
        }

        :global(.bp4-tab-panel) {
            margin: 0;
            flex: 1;
            height: calc(100% - 55px);
        }
    }

    .panel-wrapper {
        height: 100%;
    }
}
