@import "../../../../common/styles";

.add-snippet {
    height: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    form {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
    &-main-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-alert {
        padding: 0 !important;
        :global(.styled-alert-content-wrapper) {
            width: 560px !important;
            // padding: 0 27px 20px 27px;
        }

        :global(.styled-alert-footer) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            bottom: 0;
            width: 100%;
            background-color: #fafafa;
            border-top: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 0 0 20px 20px;
            position: relative;
            width: calc(100% - 54px);
            left: 27px;
            background-color: white;
            padding: 16px 0 24px 0 !important;
            // height: 48px;
        }
    }

    &-alert-toolbar {
        height: 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 20px 20px 0 0;
    }

    &-alert-content {
        width: 560px;
        // padding: 0 27px 25px 27px;

    }

    &-alert-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        color: $black;
    }

    &-input-wrapper {
        display: flex;
        flex: 1;
        margin-right: 40px;
        max-width: 505px;

        :global(.styled-floating-input) {
            width: 100%;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    :global(.mpd-floating-textarea-label) {
        top: 15px !important;
    }

    &-text-input-wrapper {
        margin-top: 52px;
        max-width: 1050px;
        width: 100%;

        :global(textarea) {
            border-radius: 12px !important;
        }
    }

    &-name-and-category-wrapper {
        max-width: 1050px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-categories-search-wrapper > div {
        width: 100%;
    }

    &-categories-search-wrapper {
        background-color: white;
        :global(#search-dropdown-wrapper) {
            z-index: 20;
        }
        :global(.bp4-popover2) {
            max-height: 350px;
        }

        :global(.bp4-popover2-target) {
            min-width: 100%;
        }

        :global(.mpd-select-options) {
            max-height: 350px;
            padding: 0 10px;
            // max-height: 350px;
        }
    }

    &-category-select {
        width: 100%;

        :global(.mpd-select-label) {
            font-family: $font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.04em;
        }
    }

    // &-selected-category {
    //     background: #e6e6e6;
    //     border-radius: 4px;
    //     height: 24px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     padding: 0 10px;
    //     font-family: $font-family;
    //     font-style: normal;
    //     font-weight: 600;
    //     font-size: 12px;
    //     line-height: 16px;
    //     display: flex;
    //     align-items: center;
    //     text-align: center;
    //     letter-spacing: 0.02em;
    //     color: $black;
    //     margin-right: 5px;
    // }
    &-footer {
        background: #fafafa;
        display: flex;
        width: 100%;
        min-height: 70px;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        position: sticky;
        z-index: 1;
        bottom: 0;
        column-gap: 20px;
        justify-content: flex-end;
        align-items: center;
        padding: 0 20px;
    }
}

.categories-dropdown {
    padding-bottom: 17px;
    &-list-subtitle {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;

        color: $black;
        padding: 23px 0 13px 10px;
    }
    &-item {
        max-height: 40px;
        box-sizing: border-box;
        align-items: center;

        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.04em;
    }

    :global(.page-loading-wrapper) {
        padding: 17px 0 0 0;
    }

    &-plus-wrapper {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background-color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        :global(.mpd-icon) {
            path {
                fill: white;
            }
        }
    }

    :global(.mpd-select-item):hover {
        .categories-dropdown-plus-wrapper {
            background-color: white;
            :global(.mpd-icon) {
                path {
                    fill: $primary-color;
                }
            }
        }

        // .categories-dropdown-cat-name {
        //     color: rgba(255, 255, 255, 0.75);
        // }

        :global(.bp4-spinner) {
            :global(.bp4-spinner-head) {
                stroke: white !important;
            }
            :global(.bp4-spinner-track) {
                stroke: rgba(255, 255, 255, 0.4);
            }
        }
    }

    &-add-cat-wrapper {
        height: 60px !important;
        box-sizing: border-box;
        padding: 14px 10px !important;
    }

    &-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */

        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
    }

    &-cat-name {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: 0.02em;
    }

    &-add-category-item {
        display: flex;
        align-items: center;
    }

    &-spinner-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    // width: 100%;
}

@include for-size(phone-only) {
    .add-snippet {
        padding: 10px;
        &-name-and-category-wrapper {
            flex-direction: column;
        }

        &-input-wrapper {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;

            input {
                width: 100%;
            }
        }

        &-text-input-wrapper {
            margin-top: 0px;
        }

        :global(.styled-textarea.primary-static) {
            margin-top: 0px;
        }
    }
}
