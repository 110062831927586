@import "../../../../../common/styles";

.subscription-box {
    display: flex;
    flex-direction: column;
    user-select: none;
    flex: 1;
    flex-shrink: 0;
    min-height: 40px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1), 0 -1px 5px rgba(0, 0, 0, 0.02);
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1), 0 -1px 5px rgba(0, 0, 0, 0.02);
    &.mpd-active {
        border: 2px solid $primary-color;
    }
}

.subscription-box-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 97px;
    background-color: $off-white-color;
    border-radius: 20px 20px 0 0;
    border-bottom: 1px solid $border-color;
    padding: 25px;
    position: relative;
}

.subscription-box-header-title {
    padding-bottom: 10px;
    color: black;
    font-family: $font-family;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
}

.subscription-box-header-subtitle {
    color: $primary-color;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
}