@import "../common/styles.scss";

.#{$ns}-floating-textarea-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  position: relative;
  width: 100%;
  outline: none !important;
  textarea:focus ~ .#{$ns}-floating-textarea-label,
  textarea:not(:focus):valid ~ .#{$ns}-floating-textarea-label {
    font-size: 9px;
    font-weight: 600;
    background: white;
  }

  textarea {
    resize: none;
    width: 100%;
    border-radius: 0;
    font-size: 11px;
    font-weight: 600;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    transition: 0.5s ease-in-out all;
  }

  .#{$ns}-floating-textarea-counter {
    text-align: right;
    font-size: 12px;
    font-weight: 400;
  }

  .#{$ns}-floating-textarea-label {
    position: absolute;
    pointer-events: none;
    font-size: 9px;
    font-weight: 600;
    transform: translateY(0px);
    left: 0px;
    transition: 0.2s ease all;
  }
}
