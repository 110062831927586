@import "../../../common/styles.scss";

$cc: "conversation-card";

.conversation-card.previous-with-sender {
    padding: 15px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    max-height: 92px;
    max-width: 320px;

    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.02);
    }
    &:active {
        transform: scale(1);
    }
    :global(.bp4-skeleton) {
        border-radius: 100px;
    }

    &-from-now:global(.bp4-skeleton),
    &-status-label:global(.bp4-skeleton) {
        height: 17px;
        width: 64px;
    }

    .#{$cc}-last-message:global(.bp4-skeleton) {
        height: 20px;
        margin-top: 10px;
    }

    .#{$cc}-top-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
    }
}

.conversation-card {
    background: #ffffff;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 100%;
    max-width: 365px;
    display: flex;

    cursor: pointer;

    justify-content: flex-end;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.02);
    }
    &:active {
        transform: scale(1);
    }

    .conversation-card-last-message {
        overflow-wrap: anywhere;
    }

    :global(.conversation-status-label) {
        font-size: 10px;
    }

    :global(.image-source-initials) {
        width: 32px;
        height: 32px;
    }

    &-block-tag-wrapper {
        background-color: #fb6375;
        margin: 0;
        border-radius: 20px;
    }

    &-shifted-wrapper {
        width: calc(100% - 5px);
        display: flex;
        justify-content: flex-start;
        background: #ffffff;
        padding: 16px 16px 16px 11px;
        border-radius: 20px;
    }

    &-star {
        margin-right: 10px;
        path {
            fill: #ffd15b;
            stroke: #ffd15b;
        }
    }

    &-accept-button {
        max-height: 26px !important;
        height: 26px !important;
        width: 88px;
        min-height: unset !important;
        min-width: unset !important;
        :global(.bp4-button-text) {
            :global(.mpd-button-text) {
                text-transform: none !important;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    &-avatar {
        margin-right: 10px !important;
        border-radius: 100%;
        height: 32px;
        min-width: 32px;
        width: 32px;
    }

    &-main-info-wrapper {
        width: 86%;
    }

    &-avatar:global(.bp4-skeleton) {
        max-width: 32px;
        min-height: 32px;
    }

    :global(.bp4-skeleton) {
        border-radius: 100px;
        height: 22px;
        margin: 0 0 10px 0;
        width: 100%;

        :last-child {
            margin-bottom: 0px;
        }
    }

    :global(.bp4-skeleton:last-child) {
        margin-bottom: 0px;
    }

    &-name-and-date-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
        height: max-content;
        align-items: center;
        margin-bottom: 5px;
        min-height: 32px;
    }

    &-name-and-tags-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        max-width: 60%;
        margin-right: 5px;
    }

    &-name-and-date-wrapper:global(.bp4-skeleton) {
        min-height: unset;
    }

    &-spam-tag-wrapper {
        background-color: #fb9163;
    }

    &-name {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 8px;
    }

    &-button-date-wrapper {
        height: 100%;
    }

    &-date {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: $primary-color;
        height: 100%;
        flex: 1;
        max-width: 80px;
        width: 80px;
        text-overflow: ellipsis;
        margin: 0;
        overflow: hidden;
        text-align: right;

        white-space: nowrap;
        line-height: 15px;
    }

    &-last-message-wrapper {
        margin-bottom: 10px;
        display: flex;
        max-width: 286px;
        justify-content: space-between;
    }

    &-last-message {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        max-height: 43px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-lines: 2;
        display: -webkit-box;
        width: 100%;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        max-width: 286px;
        color: rgba(0, 0, 0, 0.75);
        line-height: 20px;
    }

    &-unread-marker {
        // position: absolute;
        min-width: 20px;
        width: max-content;
        height: 20px;
        background-color: $primary-color;
        border-radius: 100px;
        padding: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 18px;
        color: white;
        font-family: $font-family;
        font-weight: 700;
        font-size: 10px;
    }

    &-tags-wrapper {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 12px;
        gap: 5px;
    }

    &-tag-wrapper {
        background-color: $primary-color;
    }

    &-tag-wrapper.selected {
        background-color: white;
        color: $primary-color;
    }
}

.conversation-card * {
    -webkit-font-smoothing: antialiased !important;
    backface-visibility: hidden !important;
    -webkit-backface-visibility: hidden !important;
}

.selected.conversation-card {
    background-color: $primary-color;
    .conversation-card-shifted-wrapper {
        background-color: $primary-color;
    }

    // color: white;

    .conversation-card-name {
        color: white;
    }

    .conversation-card-last-message {
        color: rgba(255, 255, 255, 0.75);
    }

    .conversation-card-date {
        color: white;
    }
}

.starred.conversation-card {
    background-color: #ffd15b;
}

@include for-size(phone-only) {
    .conversation-card {
        min-width: 280px;
        width: 100%;
        max-width: unset;

        &-avatar:global(.bp4-skeleton) {
            max-width: 32px;
            min-height: 32px;
        }

        &-date {
            font-size: 11px;
            min-width: unset;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 0 !important;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-main-info-wrapper {
            width: calc(100% - 37px);
        }

        &-button-date-wrapper {
            min-width: unset;
            overflow: hidden;
        }

        :global(.bp4-skeleton):not(&-avatar) {
            max-width: unset;
        }

        &-name-and-date-wrapper {
            max-width: unset;
        }

        &-name-and-tags-wrapper {
            overflow: hidden;
        }

        &-last-message-wrapper {
            max-width: unset;
        }
    }
}
