@import "../common/styles";

.styled-duo-buttons {
  display: flex;
  column-gap: 15px;
  &.primary {
    .styled-button {
      // min-width: 96px;
      min-height: 30px;
    }
    .styled-duo-buttons-second-button {
      margin-right: 0;
    }
  }
  &.secondary {
    display: flex;
    background-color: $lighter-black-color;
    -webkit-backdrop-filter: blur(7px);
    border-radius: 100px;
    margin-bottom: 10px;
    padding: 5px;
    .styled-button {
      width: 100px;
      height: 30px;
      border-radius: 25px;
      font-size: 10px;
      font-weight: 700;
      text-transform: capitalize;
    }
    .styled-duo-buttons-first-button {
      margin: 5px 5px 5px 10px;
      .mpd-button-text {
        color: black;
      }
      background-color: white !important;
      z-index: 2;
    }
    .styled-duo-buttons-second-button {
      color: white;
      background-color: $primary-color !important;
      z-index: 2;
      margin: 5px 10px 5px 5px;
    }
  }
}

@include for-size(tablet-portrait-up) {
  .styled-duo-buttons {
    &.primary {
      position: absolute;
      z-index: 1;
      bottom: 20px;
      transform: translate(-50%, 0);
      left: 50%;
      display: flex;
      justify-content: center;
      background-color: $lighter-black-color;
      -webkit-backdrop-filter: blur(7px);
      border-radius: 100px;
      padding: 5px;
      .styled-button {
        width: 100px;
        height: 30px;
        border-radius: 25px;
        font-size: 10px;
        font-weight: 700;
        text-transform: capitalize;
        .mpd-button-text {
          padding: 0;
        }
      }
      .styled-duo-buttons-first-button {
        margin: 5px 5px 5px 10px;
        .mpd-button-text {
          color: black;
        }
        background-color: white !important;
        z-index: 2;
      }
      .styled-duo-buttons-second-button {
        color: white;
        background-color: $primary-color !important;
        z-index: 2;
        margin: 5px 10px 5px 5px;
        margin-right: 5px;
      }
    }
  }
}

@include for-size(phone-only) {
  .styled-duo-buttons {
    &.primary {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: flex-end;
      background-color: white;
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      height: 50px;
      box-sizing: border-box;

      -webkit-backdrop-filter: blur(7px);
      padding: 5px;
      border-radius: 0;
      .styled-button {
        width: 100px;
        height: 30px;
        border-radius: 25px;
        font-size: 10px;
        font-weight: 700;
        text-transform: capitalize;
        .mpd-button-text {
          padding: 0;
        }
      }
      .styled-duo-buttons-first-button {
        margin: 5px 5px 5px 10px;
        .mpd-button-text {
          color: black;
        }
        background-color: white !important;
        z-index: 2;
      }
      .styled-duo-buttons-second-button {
        color: white;
        background-color: $primary-color !important;
        z-index: 2;
        margin: 5px 10px 5px 5px;
        margin-right: 5px;
      }
    }
  }
}
