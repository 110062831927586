@import "../common/styles";

.root {
    font-family: $font-family;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1.2px;
    margin: 0 0 40px 0;
    text-transform: capitalize;
}
