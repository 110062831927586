@import "../../../common/styles.scss";

.notifications-item {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.notifications-item-num-of-notifications {
    position: absolute;
    left: 15px;
    top: -5px;
    border-radius: 9px;
    width: 19px;
    height: 19px;
    background: #FD3A57;
    border: 2px solid $navy-color;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 11px;
    font-weight: 600;
    font-family: $font-family;
    z-index: 1;
}