@import "../common/styles.scss";

.styled-alert-portal {
    z-index: 24;
}

.confirm-input-wrapper {
    padding: 0 20px 20px 20px;
    margin-top: -20px;
}

:global(.bp4-dialog).styled-alert {
    background-color: white;
    border-radius: 20px;

    :global(.bp4-alert-footer) {
        display: none;
    }

    .styled-alert-footer {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        padding: 20px 0;
        align-items: center;
        margin: 0 20px 0 20px;

        &-right-wrapper {
            display: flex;
            column-gap: 10px;
            display: flex;
            flex: 1;
            justify-content: flex-end;
        }
    }

    &-footer-right-wrapper {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex-end;
    }

    &.warning {
        width: max-content;
        min-width: 460px;
        max-width: 500px;
        border-radius: 20px;

        :global(.styled-alert-global-content) {
            padding: 20px 20px 45px 20px;
        }

        :global(.bp4-alert-body) {
            width: 100%;

            :global(.bp4-alert-contents) {
                width: inherit;
            }
        }

        .styled-alert-footer {
            margin: 0;
            padding: 0 20px 20px 20px;
            border-top: unset;
        }
    }

    &.primary {
        width: initial;
        max-width: initial;
        display: flex;
        flex-direction: column;
        max-width: 600px;
        // padding: 20px;
        border-radius: 20px;
        background-color: white;
        box-shadow:
            0 12px 14px rgba(0, 0, 0, 0.1),
            0 -1px 5px rgba(0, 0, 0, 0.02);
        :global(.bp4-alert-contents) {
            width: 100%;
            height: 100%;
            padding: 0;
        }
        :global(.bp4-alert-body) {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .styled-alert-content-wrapper {
            display: flex;
            flex-direction: column;
            // row-gap: 20px;
        }

        .styled-alert-footer {
            border-top: 1px solid rgba(0, 0, 0, 0.15);
            background-color: white;
            z-index: 1;
        }

        .styled-alert-cancel,
        .styled-alert-confirm {
            font-family: $font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.02em;
        }

        :global(.styled-alert-global-content-top) {
            margin: 0;
        }

        .styled-alert-cancel {
            // margin-right: 10px;
            :global(.mpd-button-text) {
                color: #000000;
            }
        }
        .styled-alert-confirm {
            :global(.mpd-button-text) {
                color: white;
            }
        }

        // :global(.styled-alert-global-content) {
        //     padding: 0;
        //     display: none;
        // }
    }

    &:global(.bp4-alert) {
        padding: 0;
    }

    // &-global-content {
    //     padding: 20px 20px 0 20px;
    // }

    &-global-content-close:global(.mpd-button.bp4-button.only-icon) {
        min-width: unset;
        min-height: unset;
        width: 24px !important;
        height: 24px !important;

        :global(.bp4-button-text) {
            max-width: 15px;
            max-height: 15px;

            :global(.mpd-icon) {
                width: 15px;
                height: 15px;
            }
        }
    }
}

.children-wrapper {
    // padding: 0 20px 20px 20px;
    // padding-top: 20px;
    // padding: 0 20px 20px 20px;
    padding: 20px;
    overflow: auto;
    box-sizing: border-box;
    max-width: 600px;
    max-height: 600px;
    // width: 600px;

    & > :last-child {
        // margin-bottom: 20px;
    }
}

.children-wrapper.children-wrapper_without-padding {
    padding: 0;
}

@include for-size(phone-only) {
    .styled-alert.styled-alert_fullscreen.primary {
        margin: 0;
        border-radius: 0;
        min-width: unset;
        height: 100vh;
        max-width: 100%;
        :global(.styled-alert-global-content-top) {
            position: sticky;
            top: 0;
            z-index: 21;
            background-color: white;
            border-radius: 20px 20px 0 0;
        }

        .styled-alert-content-wrapper {
            height: 100%;
            background-color: white;
            max-height: 100%;
        }

        .styled-alert-footer {
            position: sticky;
            background-color: white;
            bottom: 0;
            margin: 0 !important;
            padding: 10px 20px !important;
            border-radius: 0;
        }

        .children-wrapper {
            max-width: 100%;
            height: 100%;
            max-height: 100%;
        }
    }
}
