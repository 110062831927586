@import "../../../common/styles.scss";

.styled-alert-global-content {
    position: relative;
}

.styled-alert-global-content-title {
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    user-select: none;
    // margin-right: 30px;
    // display: flex;
    // flex: 2;
    // justify-content: center;
    letter-spacing: 0.02em;
}

.styled-alert-global-content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 54px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-right: 25px;
    padding: 0 20px;

    &.add-margin {
        margin-bottom: 20px;
    }
}

.styled-alert-global-content-description {
    color: rgba(0, 0, 0, 0.75);
    font-family: $font-family;
    font-size: 16px;
    font-weight: 400;
    user-select: none;
    text-align: left;
    max-width: 560px;
}

// @include for-size(phone-only) {
//     .styled-alert.styled-alert_fullscreen {
//         height: 100%;
//         margin: 0;
//         border-radius: 0;
//         .styled-alert-global-content-top {
//             position: sticky;
//             top: 0;
//             z-index: 21;
//             background-color: white;
//             border-radius: 20px 20px 0 0;
//         }

//         .styled-alert-footer {
//             position: sticky;
//             background-color: white;
//             bottom: 0;
//             margin: 0 !important;
//             padding: 10px 20px !important;
//             border-radius: 0;
//         }
//     }
// }
