.root {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;

    :global(.PhoneInput) {
        display: flex;
        flex-shrink: 1;
        min-width: 0;
        z-index: 5;
    }

    :global(.PhoneInputInput) {
        margin: 0;
    }

    .country-select-target-wrapper {
        width: 100%;
        position: absolute;
        left: 0;

        :global(.bp4-popover-target) {
            width: 100%;
        }
    }
    .country-select-target {
        display: flex;
        align-items: center;
        column-gap: 5px;
        width: 100%;
    }

    .phone-input-wrapper {
        z-index: 5;
    }

    .phone-input {
        max-width: 100%;
        min-width: 0;
    }
}
