@import "../../../../common/styles.scss";

.settings-groups-member-list-item-container:hover {
    background: rgba(0,0,0,0.01);
}

.settings-groups-member-list-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: solid 1px $border-color;
    user-select: none;
    .settings-select-wrapper {
        width: 230px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .mpd-select-text-container {
        color: $off-color;
    }
    .mpd-select-container {
        width: 100%;
    }
    &.mpd-active {
        .mpd-select-text-container {
            color: $primary-color;
        }
    }
}

.settings-groups-member-list-item-checkbox-label-container {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.settings-groups-member-list-item-container {
    .styled-checkbox {
        align-items: center;
        display: flex;
        margin-bottom: 0;
    }
}

.settings-groups-member-list-item-label-initials  {
    border-radius: 50%;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    min-width: 33px;
    min-height: 33px;
    color: white;
    font-family: $font-family;
}

.settings-groups-member-list-item-label-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.settings-groups-member-list-item-select {
    .mpd-select-label {
        font-size: 12px;
    }
}

@include for-size(phone-only) {
}
