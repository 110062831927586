.start-end-date-timer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;

    &-to {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;

        color: #000000;
        margin-right: 0 !important;
    }

    &.audit-log-settings-styled-dates-picker,
    &.user-settings-view-details-activity-styled-dates-picker {
        margin-right: 10px;
    }

    &-seperator {
        display: flex;
        height: 1px;
        width: 6px;
        margin-right: 10px !important;
        background: #000;
    }

    .styled-datetime-inputs-styled-date-input {
        margin-right: 0;
    }
}
