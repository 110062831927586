@import "../common/styles.scss";
@import "../../components/common/styles";

.flag-icon {
  width: 25px;
  height: 15px;
}

.country-select-arrow-icon {
  margin-left: 0;
}

.styled-floating-phone-input {
  .mpd-select-options {
    min-height: 420px;
  }
}

.#{$ns}-floating-phone-input-container {
  .mpd-floating-phone-input-label {
    transition: all 0.3s;
  }
}

.#{$ns}-floating-phone-input-container:not(.mpd-error) {
  .primary-main-target_focused + .mpd-floating-phone-input-label {
    color: #1f76ff !important;
  }
  .primary-main-target_focused {
    border-color: #1f76ff !important;
    box-shadow: 0 0 0 1px #1f76ff !important;
    .mpd-floating-phone-input-label {
      color: #1f76ff !important;
    }
  }
}

.#{$ns}-floating-phone-input-container.mpd-error {
  .primary-main-target_focused {
    border-color: $form-error-color !important;
    box-shadow: 0 0 0 1px $form-error-color !important;
    .mpd-floating-phone-input-label {
      color: $off-color !important;
    }
  }
}

.disabled {
  .#{$ns}-floating-phone-input-container {
    &:hover {
      .primary-main-target {
        background: #fafafa;
        border: 1px solid rgba(34, 36, 45, 0.12);
      }

      .mpd-floating-phone-input-label {
        color: #7f8896 !important;
      }
    }
  }
}

.#{$ns}-floating-phone-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  outline: none !important;

  .primary-main-target {
    background: #fafafa;
    border: 1px solid rgba(34, 36, 45, 0.12);
    border-radius: 12px;
    display: flex;
    height: 54px;
    overflow: hidden;
    transition: all 0.3s;
    // padding-left: 75px;
    // position: relative;

    .PhoneInput {
      // margin
      position: relative;
      width: 100%;
    }

    .PhoneInputInput {
      left: 75px;
      position: relative;
      width: calc(100% - 75px);
      z-index: 10;
      flex: unset;
      height: 100%;
      padding-left: 5px;
      border-bottom: 0;
    }

    .bp4-popover-target {
      position: absolute;
      width: 100%;
      height: 54px;
      z-index: 9;
      left: 0px;
      top: 0;
      width: calc(100% - 16px);
      width: 100%;
      z-index: 1;
      padding-left: 16px;
    }
  }

  input:focus ~ .#{$ns}-floating-phone-input-label,
  input:not(:focus):valid ~ .#{$ns}-floating-phone-input-label {
    top: 0;
    font-size: 9px;
    font-weight: 600;
  }
  input {
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
    outline: none;
    background: none;
    padding: 0;
    font-size: 11px;
    font-weight: 600;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    transition: 0.5s ease-in-out all;
  }

  &.mpd-active {
    .#{$ns}-floating-phone-input-label {
      top: 0;
    }
  }
}
.#{$ns}-floating-phone-input-counter {
  text-align: right;
  font-size: 12px;
  font-weight: 400;
}

.#{$ns}-floating-phone-input-label {
  position: absolute;
  pointer-events: none;
  transform: translateZ(0); // input disappears on some ios devices
  font-size: 9px;
  // z-index: -1;
  font-weight: 600;
  top: -12px;
  transition: 0.2s ease all;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Open Sans", sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.#{$ns}-floating-phone-input-fixed-placeholder {
  position: absolute;
  top: 6px;
  font-size: 16px;
  font-weight: 600;
  pointer-events: none;
  user-select: none;
}

.#{$ns}-floating-phone-input-invisible {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  user-select: none;
}

.mpd-select-item {
  column-gap: 10px;
  display: flex;
  justify-content: flex-start !important;
  // column-gap: 10px;
  transition: 0s !important;
  align-items: center;
}

.edit {
  border: unset;
  outline: unset !important;

  .PhoneInput {
    border: unset !important;
    outline: unset !important;
  }
  input {
    outline: none !important;
    border: unset;
  }
}
