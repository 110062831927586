:global(.alert-replies-settings-wrapper) {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
}

.root {
    max-width: 560px;
    width: 100%;
}
