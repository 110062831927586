@import "../common/styles.scss";

.nav-item-element-wrapper {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.#{$ns}-navbar-item {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent !important;
}

.#{$ns}-simple-tooltip {
    .bp4-tooltip2 {
        width: max-content !important;
        border-radius: 12px !important;
        height: 40px;
        display: flex;
        align-items: center;
    }
    &.bp4-overlay-open {
        display: initial;
    }
    .bp4-popover2-transition-container {
        // transition: opacity 0.5s step-end;
        // width: max-content;

        .bp4-popover2 {
            border-radius: 12px !important;
        }
        .bp4-popover2-content {
            width: max-content;

            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #ffffff;
            height: 100%;

            background-color: #00263c;
            // border-radius: 4px !important;
        }
        &.bp4-popover2-enter-active {
            opacity: 0;
        }
        &.bp4-popover2-enter-done {
            opacity: 1;
        }
    }
}

.#{$ns}-navbar-item-tooltip {
    z-index: 21;
    .bp4-tooltip2 {
        transform: translateX(-7px) !important;
    }
}
