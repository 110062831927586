@import "../../../../../components/common/styles";

.radio-item {
    display: flex;
    width: 100%;

    .radio-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        :global(.bp4-popover-target) {
            width: 100%;
        }
    }

    &:global(.bp4-control.bp4-radio input:checked ~ .bp4-control-indicator ::before) {
        background-image: radial-gradient(#fff, #fff 24%, transparent 25%);
    }

    .value-input[type="text"] {
        z-index: 1;
    }

    &:global(.bp4-control.bp4-radio input ~ .bp4-control-indicator) {
        width: 12px;
        height: 12px;
        top: 8px;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);

        &:before {
            width: inherit;
            height: inherit;
        }
    }

    .value-input,
    .value-input.value-input_numeric input {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: #293136;
    }

    .value-input.error,
    .value-input.value-input_numeric {
        box-shadow: 0 0 0 2px $form-error-color;
        background-color: rgba(255, 31, 112, 0.2);
    }

    .value-input.value-input_numeric {
        display: flex;
        max-width: 200px;
        overflow: hidden;
        background: #fafafa;
        box-shadow: 0 0 0 1px rgba(34, 36, 45, 0.12);
        border-radius: 6px;
        height: 32px;
        box-sizing: border-box;

        :global(.bp4-input-group) {
            max-width: 150px;
        }
        flex-wrap: nowrap;
        input {
            opacity: 1;
            box-shadow: unset;
            height: 100%;
            background: transparent;
        }

        &:focus-within {
            background: #f4f8ff;
            input {
                background: transparent;
            }
            border-color: transparent; /* remove the border's colour */
            box-shadow: 0 0 0 2px $primary-color;
        }
    }

    input.value-input {
        opacity: 1;
        position: unset;

        background: #fafafa;
        border: unset;
        box-shadow: 0 0 0 1px rgba(34, 36, 45, 0.12);
        border-radius: 6px;
        height: 32px;
        width: 100%;

        // margin-top: 13px;
        padding: 0 10px;

        &:focus:not(.error) {
            background: #f4f8ff;
            box-shadow: 0 0 0 2px #1f76ff;
            border-radius: 6px;
        }

        // } should be refactored
    }
}

.dates-comparisons {
    :global(.settings-title) {
        margin-bottom: 10px;
    }

    .numeric-input-wrapper {
        display: flex;
        column-gap: 10px;
        align-items: center;

        :global(.bp4-button-group .bp4-button) {
            border: unset;
            box-shadow: unset;
            background-color: transparent;
            &:first-child {
                border-radius: 0 6px 0 0;
            }

            &:last-child {
                border-radius: 0 0 6px 0;
            }
        }

        .days-ago {
            display: flex;
            flex: 1;
            font-size: 14px;
        }
    }
}

.default {
    width: 230px;
}

.date {
    width: 300px;
}

.styled-map:global(.styled-map) {
    height: 100%;
}

.plus-circle {
    height: 24px;
    width: 24px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    :global(.mpd-icon) {
        height: 12px;
        width: 12px;
    }
}

.devider {
    width: calc(100% + 20px);
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15);
    margin: 10px 10px 10px -10px;
}
