@import "../../../../../common/styles";

.user-dropdown-item {
    &-entry-component {
        display: flex;
        align-items: center;
        flex: 0 0 40px;
        padding: 10px !important;
        box-sizing: border-box;
        max-height: 40px;
        justify-content: space-between;

        .mention-name {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: 0.02em;
        }

        .selected {
            color: $primary-color;
        }

        :global(.image-source-tooltip-wrapper) {
            width: 25px;
            height: 25px;
        }
    }

    &-avatar {
        height: 25px;
        width: 25px;
        border-radius: 100%;
        margin-right: 8px;
        z-index: unset;
        position: unset;

        :global(.image-source-initials) {
            font-size: 12px;
        }
    }

    &-left-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &-tick-icon {
        width: 13px;
        path {
            stroke: $primary-color;
        }
    }

    &-entry-component:hover {
        .mention-name.selected {
            color: white;
        }

        .user-dropdown-item-tick-icon {
            width: 13px;
            path {
                stroke: white;
            }
        }
    }
}
