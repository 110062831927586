@import "../../common/styles";

.groups-list-item-container:hover {
    background: rgba(0,0,0,0.01);
}

.groups-list-item-container-title {
    font-family: $font-family;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    margin-left: 5px;
}

.groups-list-item-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: solid 1px $border-color;
    align-items: center;
    user-select: none;
    div {
        // margin: 0px 10px;
        font-family: $font-family;
    }
    .bp4-checkbox {
        color: $light-black-color;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
        &.mpd-active {
            color: black;
            font-weight: 600;
            .bp4-control-indicator {
                background-color: $primary-color !important;
            }
        }
        .bp4-control-indicator {
        box-shadow: none !important;
        outline: none !important;
        background-image: none;
        border: 1px solid $border-dark-color;
        border-radius: 4px !important;
        }
    }

    .bp4-control input:checked ~ .bp4-control-indicator {
        border: none !important;
    }

    .bp4-icon {
        border-radius: 4px;
        background-color: $primary-color;
    }
    
    .group-list-item-type {
        text-transform: capitalize;
        text-align: center;
        justify-content: center;
        width: 60px;
        border-radius: 2px;
        padding: 5px 0;
        font-weight: 600;
        font-size: 9px;
    }
    .public {
        background-color: $public-color;
    }
    .private {
        background-color: $private-color;
    }
}

.group-list-item-description {
    white-space: nowrap;
}

.group-list-item-members {
    white-space: nowrap;
    color: $light-black-color;
    font-size: 12px;
    font-weight: 600;
}

.groups-list-item-container-group-list-item-type-wrapper {
    flex: 1;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}

@include for-size(phone-only) {
    .groups-list-item-container {
        .bp4-control {
            width: 70px;
        }
    }
    .group-list-item-description {
        // display: none;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 30px;
    }

    .groups-list-item-container-title {
        overflow: hidden;
        white-space: nowrap;
        max-width: 225px;
        text-overflow: ellipsis;
    }

    .group-list-item-description,
    .groups-list-item-container-group-list-item-type-wrapper {
        width: 0;
    }
}