@import "../../../common/styles";

.qr-style-selector {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .qr-style-selector-label {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #7f8896;
    }

    .qr-styles-list {
        margin: 0;
        padding: 0;
        display: flex;
        column-gap: 20px;
    }

    .qr-styles-list-item {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        cursor: pointer;

        &_active {
            border: 3px solid $primary-color;
            box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
        }
    }
}
