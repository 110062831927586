.account-branding {
    &-icon-title,
    &-info-title {
        margin-top: 0;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;

        letter-spacing: 0.04em;
        text-transform: uppercase;

        color: #768086;
    }

    &-icon-block {
        height: 60px;
        width: 60px;

        .image-upload-icon-image {
            height: 100%;
            width: 100%;
        }
    }

    &-info-text {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        overflow-wrap: anywhere;

        /* Dark Grey */

        color: #293136;
    }
}
