@import "../../../../common/styles";

.add-new-tag {
    padding: 40px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;

    &-name-and-tag-to-nest-wrapper {
        max-width: 1050px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 40px;
        row-gap: 25px;
        flex-wrap: wrap;
    }
    &-main-container {
        // height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        flex: 1;

        form {
            height: 100%;
            overflow: hidden;
        }
    }

    &-categories-search-wrapper {
        background-color: white;
        :global(.bp4-popover2) {
            min-height: 88px;
            max-height: 420px;
        }

        :global(.bp4-popover2-target) {
            min-width: 100%;
            max-width: 505px;
        }

        :global(.mpd-select-label) {
            font-family: $font-family;
        }

        :global(.mpd-select-options) {
            padding: 10px;
            max-height: 420px;
        }
    }

    &-dropdown {
        border-radius: 12px;
        height: 54px;
        border: 1px solid rgba(0, 0, 0, 0.15);
    }

    &-input-wrapper > div {
        width: 100%;
    }

    &-input-wrapper {
        display: flex;
        flex: 1;
        // max-width: 505px;

        :global(.styled-floating-input) {
            width: 100%;
            margin: 0 !important;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &-text-input-wrapper {
        max-width: 1050px;
        width: 100%;
        margin-top: 10px;
        flex-grow: 2;

        :global(textarea) {
            border-radius: 12px !important;
        }
    }

    :global(.mpd-floating-input-container) {
        margin-top: 0;
    }

    :global(.mpd-floating-textarea-label) {
        top: 15px !important;
    }

    &-tag {
        margin-bottom: 0;
        margin-left: 8px;
    }
    &-footer {
        background: #fafafa;
        display: flex;
        width: 100%;
        min-height: 70px;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        position: sticky;
        z-index: 1;
        bottom: 0;
        column-gap: 20px;
        justify-content: flex-end;
        align-items: center;
        padding: 0 20px;
    }

    .subtitle {
        font-family: $font-family;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 1.2px;
    }

    .checkbox-description {
        color: black;
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.28px;
    }

    .tag-settings-wrapper {
        margin-top: 40px;
        :global(.styled-checkbox) {
            display: flex;
            align-items: center;
            max-width: max-content;
            :global(.bp4-control-indicator) {
                margin-top: 0px;
                width: 24px !important;
                height: 24px !important;

                &::before {
                    width: 24px !important;
                    height: 24px !important;
                }
            }
        }
        .checkboxes-wrapper {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
        }
    }
}

@include for-size(phone-only) {
    .add-new-tag {
        padding: 10px;

        &-name-and-tag-to-nest-wrapper {
            flex-direction: column;
        }

        &-input-wrapper {
            width: 100%;
            margin-bottom: 30px;
            margin-right: 0;
            :global(.styled-floating-input.primary) {
                width: 100%;
            }
        }

        &-text-input-wrapper {
            margin-top: 0;
        }
    }
}
