@import "../../../../../../common/styles";
.root {
    height: 60px;

    .file-header {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
    }

    .preview {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #999999;
    }
}

.dropdown-target {
    z-index: 19;
}
