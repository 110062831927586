@import "../../../../common/styles";

.queues {
    display: flex;
    padding: 0 40px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    overflow: auto;

    &-list-wrapper {
        padding: 40px 0;
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    &-list-label {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: $black;
        margin-bottom: 40px;
    }

    &-list-label:not(:first-of-type) {
        margin-top: 40px;
    }

    :global(.empty-block) {
        height: 482px;
        :global(.mpd-icon) {
            width: 63px;
            height: 63px;
            margin-bottom: 18px;
        }
    }
}

@include for-size(phone-only) {
    .queues {
        padding: 0 10px;

        &-list-wrapper {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        &-list-label {
            margin-bottom: 20px;
        }

        &-list-label:not(:first-of-type) {
            margin-top: 20px;
        }
    }
}
