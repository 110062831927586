@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 450px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 451px) and (max-width: 899px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}

@mixin notification {
  .left-wrapper {
    display: flex;
  }

  .icon-wrapper {
    min-width: 46px;
    height: 46px;
    border-radius: 100%;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;

    svg:global(.mpd-icon) {
      path {
        fill: unset;
        stroke: white;
      }
    }
  }

  .event-type {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 12.5143px;
    line-height: 23px;
    color: $gray;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .event-title {
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 14.6px;
    line-height: 20px;
    color: #000000;
  }

  .event-description {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 14.6px;
    line-height: 130%;
    color: rgba(0, 0, 0, 0.75);
    margin-top: 10px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

// should be removed after deleteing profile automation com[onent
@mixin settings-list-item-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 20px;
}

@mixin page-subtitle {
  font-family: $font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color: $black
}
