@import "../common/styles.scss";

.home-side-item {
  .bp4-popover2-wrapper {
    .mpd-icon path {
      opacity: 1 !important;
    }
    .mpd-button-text {
      opacity: 1 !important;
    }
  }
}

.notifications-side-item-button {
  width: 100%;
  height: 100%;
}

@include for-size(phone-only) {
  .notifications-side-item-button {
    .mpd-button-text {
      margin-left: 0;
    }
  }
}
.default-tool-tip {
  z-index: 9999999;
}

.notifications-side-item-button-active {
  svg {
    opacity: 1 !important;
    path {
      fill: rgba(255, 255, 255, 1);
    }
  }
}

.my-profile-sidebar-item {
  .sidebar-profile-item-icon {
    color: rgba(255, 255, 255, 0.5);
  }
  .sidebar-profile-item-button {
    .mpd-button-text {
      margin-left: 30px !important;
    }

    @include for-size(phone-only) {
      .mpd-button-text {
        margin-left: 12px !important;
      }
    }
  }
  &.mpd-hover {
    .sidebar-profile-item-icon {
      color: rgba(255, 255, 255, 0.75);
    }
  }
  .bp4-popover2-wrapper {
    &.bp4-active {
      .sidebar-profile-item-icon {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.my-profile-sidebar-item:active {
  .profile-item-icon {
    color: rgba(255, 255, 255, 1);
  }
}

.mobile-account-status-side-item {
  opacity: 1;
  user-select: none;
  padding: 0;
  .styled-switch {
    color: rgba(255, 255, 255, 0.5);
  }
}

.mobile-account-profile-status-control {
  width: 100%;
  padding: 25px;
}

.mobile-account-home-side-item {
  svg {
    fill: white;
  }
}

.mobile-account-profile-info-side-item,
.mobile-account-status-side-item,
.mobile-account-home-side-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-account-profile-info-side-item {
  height: 76px;
  opacity: 1;
  cursor: default;
}

.mobile-account-profile-info {
  display: flex;
}

.mobile-account-profile-initials {
  width: 36px;
  height: 36px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  background-color: #4a90e2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family;
  font-size: 16px;
  line-height: 10px;
  letter-spacing: 0.05em;
  color: white;
  font-weight: 700;
  margin-right: 22px;
  text-transform: uppercase;
}

.mobile-account-profile-name {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
}

.mobile-account-profile-status {
  font-family: $font-family;
  font-size: 12px;
  font-weight: 600;
  color: hsla(0, 0%, 100%, 0.5);
  padding: 3px 0;
}

.mobile-account-logout-side-item {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-account-home-side-item:first-child {
  .mpd-icon path {
    opacity: 1 !important;
  }
  .mpd-button-text {
    opacity: 1 !important;
  }
}

.mobile-account-profile-icon {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  background-color: #4a90e2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family;
  font-size: 16px;
  line-height: 10px;
  letter-spacing: 0.05em;
  color: white;
  font-weight: 700;
  margin-right: 10px;
}

.styled-sidebar-account-popover {
  margin-left: -15px;
  z-index: 999;
  .bp4-popover2-content {
    margin-bottom: -5px;
  }

  .bp4-popover2-arrow {
    display: block;
  }
}

.sidebar-counter {
  position: absolute;
  background-color: #fd3a57;
  border-radius: 100px;
  height: 19px;
  min-width: 19px;
  border: 2px solid #003554;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  padding: 0 3px;
  color: #ffffff;

  top: 5px;
  right: 10px;

  @include for-size(phone-only) {
    position: relative;
    height: 25px;
    padding: 0 5px;
    // max-width: 25px;
    top: 0px;
  }
}

// .bp4-active,
.mpd-selected {
  .sidebar-counter {
    border: 2px solid #064a8c;
  }
}

.notifications-sidebar-tab {
  position: relative;
  // width: 100%;
  // height: 100%;
  // width: 100%;
  // height: 100%;
}

.notifications-popover-portal {
  .bp4-popover2 {
    overflow: visible;
    border-radius: 20px;
  }
  .bp4-popover2-content {
    height: 100%;
    border-radius: 20px;
  }
  .bp4-popover2-transition-container.bp4-overlay-content {
    height: 100vh;
    padding: 10px 12px 10px 12.5px;
    inset: 0 auto auto 58px !important;
    width: 400px;
  }

  .bp4-popover2-arrow {
    display: block;
    content: url(../../mpd-library/icon/assets/popover-white-arrow.svg);
    left: -18px !important;
    top: 10px;
    width: 34px;
    height: 53px;

    svg {
      width: 30px;
      height: 24px;
    }
  }

  .bp4-popover2-arrow::before {
    display: block !important;
  }

  .bp4-popover2 {
    box-shadow:
      0px -1px 5px rgba(0, 0, 0, 0.02),
      0px 12px 14px rgba(0, 0, 0, 0.1);
  }

  .bp4-popover2-backdrop {
    // position: relative;
    z-index: 10;
  }

  .bp4-popover2-backdrop.bp4-popover2-enter-done {
    background-color: rgba(0, 0, 0, 0.15) !important;
    opacity: 1 !important;
  }

  .bp4-popover2-backdrop.bp4-popover2-enter-active {
    opacity: 1 !important;
    background-color: rgba(0, 0, 0, 0.15) !important;
    transition:
      opacity 0.3s,
      background-color 0.3s !important;
  }
}

.conversations-menu-item-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  @include for-size(phone-only) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.testing {
  @include for-size(phone-only) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
