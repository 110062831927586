@import "../../../../common/styles.scss";

.billing-settings-details-header {
    user-select: none;
    padding: 20px 40px;
    border-bottom: solid 1px $border-color;
}

.billing-settings-details-list-item {
    user-select: none;
    padding: 20px 40px;
    border-bottom: solid 1px $border-color;
}

.billing-settings-details-header-item {
    color: black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
}

.billing-settings-details-list-item-download-button {
    .mpd-button-text {
        color: $primary-color;
        font-family: $font-family;
        font-size: 14px;
        font-weight: 600;
    }
}

.billing-settings-details-list-item {
    color: $light-black-color;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
}