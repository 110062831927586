@import "../../../../common/styles.scss";

.subscription-settings-details-header {
    user-select: none;
    border-bottom: solid 1px $border-color;
}

.subscription-settings-details-list-item {
    user-select: none;
    border-bottom: solid 1px $border-color;
}

.subscription-settings-details-header-item {
    color: black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
}


.subscription-settings-details-list-item {
    color: $light-black-color;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    span {
        color: $lighter-black-color;
    }
}