@import "../../../../components/common/styles";

.dropdown-target.select-target.more {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover :global(.mpd-icon path) {
        // stroke: white !important;
        stroke: unset !important;
        fill: $primary-color !important;
    }

    & :global(.mpd-icon path) {
        stroke: unset !important;
    }

    .label {
        display: none;
    }
    .arrow-icon {
        margin-left: unset;
    }
}

.dropdown-target .arrow-icon,
.dropdown-target .left-icon {
    flex-shrink: 0;
}

.dropdown-target .label {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-target.select-target-primary {
    background-color: $primary-color;

    .arrow-icon path {
        stroke: white;
    }

    &:global(.bp4-active),
    &:hover {
        box-shadow: 0 2px 4px $border-dark-color;
        background-color: lighten($color: $primary-color, $amount: 10%) !important;
        transition: box-shadow 0.3s;

        .arrow-icon path {
            stroke: white !important;
        }
    }

    &:global(.bp4-active) {
        background-color: $primary-color !important;
    }

    .label {
        color: white !important;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.02em;
        max-width: max-content;
    }
}

.dropdown-target.half-button-target:hover,
.dropdown-target.select-target:hover,
.dropdown-target.more-select-taget:hover,
.dropdown-target.filter-comparison-select:hover,
.dropdown-target.select-target:global(.bp4-active),
.dropdown-target.more-select-taget:global(.bp4-active) {
    &:not(.disabled) {
        background: #f4f8ff;
        border: 1px solid #1f76ff;

        .arrow-icon path,
        .left-icon path {
            stroke: #1f76ff;
        }

        .label,
        .title {
            color: #1f76ff;
        }
    }
}

.dropdown-target.form-select-target:hover,
.dropdown-target.form-select-target:global(.bp4-active) {
    background: #f4f8ff;
    border: 1px solid #1f76ff;
    box-shadow: 0 0 0 1px #1f76ff;
    transition: all 0.3s;

    .arrow-icon path {
        stroke: #1f76ff;
    }

    .label,
    .title {
        color: #1f76ff;
    }
}

.simple-select-target:hover:not(.disabled) {
    border: unset;
    background: rgba(31, 118, 255, 0.1);
    color: $primary-color;

    .arrow-icon path {
        stroke: #1f76ff;
    }
}

.simple-select-target:global(.bp4-active) {
    border: unset;
    background: unset;

    .arrow-icon path {
        stroke: #1f76ff;
    }
}

.dropdown-target:not(.more):global(.bp4-active) {
    .arrow-icon {
        transform: rotate(540deg);
    }
}

.arrow-icon {
    transition: all 0.2s;
    display: flex;
    flex-shrink: 0;
    margin-left: auto;
}

.dropdown-target {
    display: flex;
    align-items: center;
    width: max-content;
    column-gap: 10px;
    cursor: pointer;
    position: relative;
}

.label {
    display: flex;
    // flex: 2;
    white-space: nowrap;
}

.gradient {
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 70px;
        height: 100%;
        z-index: 10;
        background: linear-gradient(270deg, #e4edfb 40.24%, rgba(228, 237, 251, 0) 100%);
    }
}

.half-button-target,
.select-target {
    max-width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    background-color: white;
    padding: 0 11px;

    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;

    color: #000000;
    height: 32px;
    min-width: 32px;
    box-sizing: border-box;

    .left-icon {
        display: flex;
        max-width: 16px;
        max-height: 16px;
        min-width: 8px;
        flex-grow: 1;
    }

    :global(.avatar) {
        margin-left: -7px;
    }

    .left-label {
        flex-shrink: 0;
        color: #808080;
    }
}

.form-select-target {
    height: 54px;
    width: 100%;
    padding: 0 13px;
    background: #fafafa;
    border: 1px solid rgba(34, 36, 45, 0.12);
    border-radius: 12px;
    margin-top: 28px;

    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #293136;

    .label {
        width: max-content;
    }

    &.is-not-selected {
        .label {
            color: #bfbfbf;
        }
    }

    .title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #7f8896;
        position: absolute;
        top: -28px;
        left: 0;
    }

    .search-input {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.04em;
        border: unset;
        background-color: transparent;
        color: #293136;
        flex: 1;

        &::placeholder {
            font-family: $font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #bfbfbf;
        }
    }

    .search-and-selected {
        display: flex;
        align-items: center;
        overflow: auto;
        column-gap: 5px;
        flex: 1;
        position: relative;
        height: 100%;
    }
    .left-icon {
        min-width: 18px;
    }

    .arrow-icon {
        min-width: 12px;
    }
}

.filter-select {
    background: #e6e6e6;
    padding: 0 10px;
    height: 32px;
    font-weight: 700;
    .left-icon {
        min-width: 16px;
    }

    .remove-dropdown-button-tooltip {
        width: 32px;
        height: 32px;
        position: absolute;
        z-index: 10;

        // right: -10px;
        right: 0;

        & > div {
            height: 100%;
            width: 100%;
            .remove-dropdown-button {
                border: unset;
                height: 100%;
                width: 100%;

                // background: linear-gradient(270deg, #e4edfb 60%, rgba(228, 237, 251, 0) 100%);
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 10px;

                // background: red;

                :global(.bp4-icon path) {
                    fill: $primary-color;
                }
            }
        }
    }
}

.filter-select:global(.bp4-active),
.filter-select:hover {
    &:not(.disabled) {
        background: rgba(31, 118, 255, 0.1);

        span.label,
        .label span {
            color: #1f76ff !important;
        }

        .left-icon:global(.mpd-icon) path {
            stroke: #1f76ff;
        }
    }
}

.filter-select.error,
.filter-select.error:hover,
.filter-select.error:global(.bp4-active) {
    &:not(.disabled) {
        background-color: rgba(255, 31, 112, 0.2);
        span {
            color: $form-error-color !important;
        }

        svg.left-icon path {
            stroke: $form-error-color;
        }

        .gradient::after {
            background: linear-gradient(270deg, #fed2df 40.24%, rgba(228, 237, 251, 0) 100%);
        }

        :global(.bp4-icon path) {
            fill: $form-error-color;
        }
    }
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.half-button-target {
    border-radius: 0 100px 100px 0;
    .label,
    .left-icon {
        display: none;
    }
}

.filter-comparison-select {
    border-radius: 6px;
    background: #fafafa;
    border: 1px solid rgba(34, 36, 45, 0.12);
    border-radius: 6px;
    height: 32px;
    padding: 0 10px;
    font-size: 14px;
}

.disabled {
    opacity: 0.4;
}

.form-select-table-target {
    height: 40px;
    margin: 0;
}

.edit-select-target {
    .title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: 0.1em;
        color: #7f8896;
        position: absolute;
    }

    .label {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #293136;
    }

    .arrow-icon {
        margin-left: unset;
        path {
            stroke: $primary-color;
        }
    }
}

.edit-select-target:global(.bp4-active) {
    .arrow-icon path {
        stroke: $primary-color;
    }
}

.edit-select-target.with-selected-value {
    height: 41px;
    box-sizing: border-box;
    justify-content: flex-start;

    .title {
        top: 0;
    }

    .label {
        align-self: flex-end;
        width: max-content;
        flex: unset;
        margin-bottom: 3px;
    }

    .arrow-icon {
        align-self: flex-end;
        margin-bottom: 6px;
    }
}

.edit-select-target,
.edit-select-target:hover,
.edit-select-target:global(.bp4-active) {
    border-bottom: 1px solid $primary-color;
}
.edit-select-target {
    height: 32px;
    border-bottom: 1px solid transparent;
    width: 100%;

    .search-and-selected {
        display: flex;
        align-items: center;
        overflow: auto;
        column-gap: 5px;
        flex: 1;
    }
}
