@import "../common/styles";

.toast {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 50px;
    opacity: 0;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 10;
    transition: opacity 1s ease-out;
    &.mpd-active {
        transition: opacity 1s ease-in;
        opacity: 1;
    }
    &.saved {
        .toast-wrapper {
            color: #09a55f;
            border-color: #09a55f;
            background: #eafff6;
        }
    }
}

.toast-wrapper {
    padding: 5px 15px;
    border: solid 1px $border-color;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 700;
    border-radius: 5px;
    background: white;
}

.global-toast.bp4-toast-container {
    z-index: 100;
}

.bp4-toast.conversation-push-notification {
    box-shadow: 0px -1.04286px 5.21429px rgba(0, 0, 0, 0.02), 0px 12.5143px 14.6px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 390px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;

    .bp4-button {
        display: none;
    }

    .bp4-toast-message {
        padding: 0;
    }
}
