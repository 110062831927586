@import "../../../../common/styles";

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.header-item {
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #000000;
}

.animated-wrapper {
    transition: background-color 0.5s ease;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: background-color 0.3s ease;
}

.root {
    width: 100%;
    display: grid;
    max-height: 60px;
    height: 60px;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr 1fr 2fr 3fr 2fr;
    align-items: center;
    min-width: 1080px;

    padding: 0 60px 0 0;
    cursor: pointer;

    .status-dot-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
    }

    .status-dot {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        margin-right: 10px;
    }

    .counter {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 17px;
        height: 17px;
        font-size: 11px !important;
        border-radius: 12px;
        background-color: #e6e5e5;
        padding: 0 5px;
        font-weight: 700 !important;
    }

    .status-dot:global(.bp4-skeleton) {
        border-radius: 100%;
    }

    .status-dot.success {
        background-color: #1deaa0;
    }

    .status-dot.error {
        background-color: #ff1f70;
    }

    .status-dot.warning {
        background-color: #ffcd00;
    }

    .value {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.75);
    }

    .value:global(.bp4-skeleton) {
        border-radius: 12px;
        max-width: 50%;
        height: 12px;
    }

    .text-result-error {
        color: #ff1f70;
    }

    .text-result-warning {
        color: #ffcd00;
    }

    .path-value {
        background: #f4f8ff;
        max-width: max-content;
        border-radius: 3px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 3px;
    }
}

// .collapse {
//     min-height: 300px;
// }

// .api-request-record-enter {
//     transform: translateY(-100%);
//     height: 0;
//     min-height: 0;
//     opacity: 0;
// }

// .api-request-record-enter-active {
//     transition: all 500ms;
//     transform: scale(1.04);
//     min-height: 60px;
//     opacity: 1;
//     background-color: rgb(31, 118, 255, 0.06);
// }

// .api-request-record-enter-done {
//     transition: all 500ms;
//     transform: translateY(0%), scale(1);
//     min-height: 60px;
//     opacity: 1;
//     background-color: white;
// }
