@import "../../../../common/styles.scss";

.settings-roles-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    flex-shrink: 0;
    height: 60px;
    border-bottom: solid 1px $border-color;
    user-select: none;
    cursor: pointer;

    .bp4-skeleton {
        max-height: 10px;
        border-radius: 20px;
    }
}

.settings-roles-list-item-label-title {
    font-family: $font-family;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}

.settings-roles-list-item-label-wrapper {
    display: flex;
    align-items: center;
}

.settings-roles-list-item-select-wrapper {
    display: flex;
    align-items: center;
}

.settings-roles-list-item-left-wrapper {
    display: flex;
    align-items: center;
}

.settings-roles-list-item-label-initials {
    border-radius: 50%;
    background-color: #1f76ff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    color: white;
    font-family: $font-family;
    margin-right: 10px;
    text-transform: uppercase;
}

.settings-roles-list-item-select-popover {
    .mpd-select-options {
        transform: translateX(-10px);
        width: 150px;
    }
}

.settings-roles-list-item-select {
    .mpd-select {
        padding-right: 0;
    }
}

.settings-roles-list-item-status-text {
    font-family: $font-family;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    color: #1F76FF;
    font-weight: 600;
}

@supports (-webkit-overflow-scrolling: touch) {
    .bp4-popover2-enter-done {
        .settings-roles-list-item-select-popover {
            .mpd-select-options {
                margin-top: -125px;
            }
        }
    }
}