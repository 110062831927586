.styled-datetime-inputs {
    display: flex;
    flex: 1 1;
    align-items: flex-start;
}

.styled-datetime-inputs-content-time {
    position: relative;
    margin-left: 10px;
    flex: 1;

    .mpd-select {
        width: 140px;
        height: 32px;

        border-radius: 100px;
    }
}

.styled-datetime-inputs-styled-date-input {
    flex: 1;
    margin-right: 10px;

    .bp4-input {
        width: 130px !important;
        height: 32px;

        border-radius: 100px;
    }
}