@import "../../../../common/styles";
.json-output {
    padding: 20px 40px;
    width: 100%;

    &-panel-wrapper {
        width: 100%;
        height: 100%;
    }

    &-tabs-wrapper {
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.02), 0px 2px 6px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        overflow: hidden;
    }

    :global(.bp4-tab-panel) {
        margin: 0;
    }
    :global(.bp4-tab-list) {
        background-color: #fafafa;
    }

    :global(.bp4-tab) {
        min-width: 120px;
    }

    &-warnings-list {
        list-style: none;
        padding: 30px 40px;

        & > li:last-of-type {
            margin-bottom: 0;
        }
    }
    &-warnings-list-item {
        display: list-item;
        position: relative;
        display: flex;
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.75);
        margin-bottom: 20px;
    }

    &-warnings-list-item::before {
        content: "";
        display: block;
        background-color: #ffcd00;
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        max-height: 12px;
        border-radius: 100%;
        margin-right: 9px;
        margin-top: 4px;
        // position: absolute;
        // left: -9px;
    }
}
