@import "../../../common/styles";

.subscription-settings {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
}



@include for-size(phone-only) {
    .subscription-settings {
        .bp4-tab {
            flex: 1;
        }
    }
}