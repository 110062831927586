@import "../common/styles";

.filters-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    position: relative;

    .cancel-edit-button {
        min-width: unset;
        :global(.bp4-button-text .mpd-button-text) {
            color: #f23a50;
            min-width: unset;
        }
    }
}

.value-key-label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #000000;
}

.filter-value {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    white-space: nowrap;

    color: #000000;
}

.filter-slice {
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
    row-gap: 10px;
}

.filter-group-wrapper {
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    column-gap: 1px;
    flex-wrap: nowrap;
    row-gap: 10px;
    flex-wrap: wrap;

    .filter-unit {
        display: flex;
        flex-wrap: nowrap;
        column-gap: 1px;
        row-gap: 10px;
    }
}

.operator-between-group-dropdown-target {
    width: 46px;
    height: 32px;
    padding: 0 10px;
    font-weight: 700;
    margin-left: -20px;
    display: flex;
    justify-content: center;
    span {
        display: block;
        text-align: center;
    }
}

.operator-dropdown-between-units span {
    color: #808080;
}

.plus-dropdown-target {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.remove-button {
    margin-left: 10px;
    position: relative;
    z-index: 2;

    :global(.mpd-button-text) {
        color: #f23a50 !important;
    }
}
