@import "../../../../../common/styles";

.queue-item {
    width: 100%;
    height: 88px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 0 20px;
    cursor: pointer;
    justify-content: space-between;
    display: flex;
    align-items: center;
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.01);
    }

    &-admins-tooltip-portal {
        :global(.bp4-popover2-content) {
            padding: 0 12px !important;
            background-color: #00263c !important;
        }
    }

    :global(.image-source-initials) {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 10px;
        text-align: center;
        color: #ffffff;
    }

    &-admins-tooltip {
        width: max-content;
    }

    &-admins-tooltip-list {
        display: flex;
        flex-direction: column;

        max-height: 277px;
        max-width: 160px;
        overflow: auto;
        padding: 19px 0 !important;
    }

    &-avatar {
        // border: 1px solid #ffffff;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        // position: absolute;
        // order: 1;
    }

    &-queue-name-first-letter {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 18px;
        /* identical to box height, or 75% */

        display: flex;
        align-items: center;
        text-align: center;

        /* White */

        color: #ffffff;
    }

    &-avatars-wrapper {
        position: relative;
        max-width: 143px;
        height: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        :nth-child(1) {
            z-index: 6;
            left: 0px;
        }
        :nth-child(2) {
            z-index: 5;
            left: 28px;
        }
        :nth-child(3) {
            z-index: 4;
            left: 56px;
        }
        :nth-child(4) {
            left: 84px;
            z-index: 2;
        }
        :nth-child(5) {
            z-index: 1;
            left: 112px;
        }
    }

    &-admin-plus {
        // z-index: 10;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 1px solid #ffffff;
        background-color: #eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 10px;

        color: rgba(0, 0, 0, 0.75);
    }

    &:last-of-type {
        margin-bottom: 40px;
    }

    &-quque-logo {
        background-color: $primary-color;
        min-width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-right: 15px;

        :global(.mpd-icon) {
            width: 18px;
            height: 17px;
            path {
                fill: white;
            }
        }
    }

    &-left-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &-name-and-description-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        div:last-of-type {
            margin-bottom: 0 !important;
        }
    }

    &-queue-name {
        font-family: $font-family;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 18px;
        /* identical to box height, or 112% */

        display: flex;
        align-items: center;

        color: $black;
        margin-bottom: 11px;
    }

    &-queue-description {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;

        color: $gray;
    }
}

@include for-size(phone-only) {
    .queue-item {
        padding: 10px;
        box-sizing: border-box;
        height: unset;
        min-height: 88px;
        margin-bottom: 15px;

        &-left-wrapper {
            display: flex;
            height: 100%;
            align-items: flex-start;
        }
    }
}
