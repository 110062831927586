@import "../common/styles.scss";

.image-upload-icon-filler {
    border: 1px solid $border-color;
    background-color: rgba(0, 0, 0, 0.1);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.image-upload-icon-filler-image {
    margin-left: 10px;
    color: $primary-color;
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;

    text-transform: capitalize;
    cursor: pointer;
    position: initial;
}

.image-upload-icon-filler-text {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.image-upload {
    display: flex;
    position: relative;
    align-items: center;

    .image {
        cursor: pointer;
    }
}

.image-upload-icon-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}
