@import "../../../common/styles";

.select-item-left-icon {
    width: 18px;
    height: 18px;
}

.root {
    max-width: 320px;
    height: 100%;
    border-left: 1.5px solid rgba(34, 36, 45, 0.12);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px 70px 20px;
    width: 320px;
    box-sizing: border-box;
    flex: 1;
    position: relative;
    z-index: 10;
    height: 100vh;
    top: -50px;
    background-color: white;

    .user-main-info {
        display: flex;
        column-gap: 15px;
        align-items: center;
        padding: 30px 0 20px 0;

        :global(.image-source-initials) {
            font-size: 21px;
        }

        &__avatar {
            width: 50px;
            height: 50px;
            border-radius: 100%;
        }

        &__left-wrapper {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            &__name {
                font-family: $font-family;
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 22px;
                color: #000000;
                max-height: 22px;
            }

            &__active-since {
                font-family: $font-family;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 22px;
                color: $primary-color;
            }
        }
    }

    .empty-label {
        font-family: $font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #808080;
        position: absolute;
    }

    .channels {
        display: flex;
        column-gap: 15px;
        justify-content: center;
    }

    // section {
    //     border-bottom: 1px solid rgba(34, 36, 45, 0.12);
    // }

    hr {
        background-color: rgba(34, 36, 45, 0.12);
        width: calc(100% + 40px);
        margin: 0 0 0 -20px;
        height: 1px;
        box-sizing: border-box;
        border: none;
    }

    .tags-wrapper {
        display: flex;
        column-gap: 5px;
        row-gap: 5px;
        flex-wrap: wrap;
        // max-width: 100%;
    }

    .add-button {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        :global(.mpd-button-text) {
            color: $primary-color;
        }
    }

    .outer-left-icon {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
    }
}
