@import "../common/styles";

.rounded-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    object-fit: contain;
    flex-shrink: 0;
}

.initials {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    color: #ffffff;
}
