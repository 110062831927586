@import "../../../common/styles";

.root {
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: column;

    header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        height: 50px;
        min-height: 50px;
        align-items: center;
        background-color: #fafafa;
        padding: 0 20px;
    }

    .main-content {
        height: 100%;
        padding: 0 40px;
        background: white;
    }

    .search-input {
        max-width: 230px;
    }

    .sticky-header {
        background-color: white;
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: #000000;
        text-transform: uppercase;
        padding-bottom: 28px;
        padding-top: 28px;
    }
}
