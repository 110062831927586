@import "../../../../../common/styles";

.profile-automation-item:nth-of-type(2n) {
    margin-bottom: 60px;
}

.profile-automation-item {
    width: 100%;
    // height: 88px;
    background: #ffffff;

    // border: 1px solid rgba(0, 0, 0, 0.15);
    // box-sizing: border-box;
    // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    // border-radius: 12px;
    @include settings-list-item-wrapper;

    display: flex;
    flex-direction: column;
    padding: 22px;
    box-shadow: border-box;
    transition: all 0.3s;
    margin-bottom: 20px;

    &-top-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 44px;
        cursor: pointer;
        // margin-bottom: 22px;
        // padding-bottom: 22px;
    }

    &-left-wrapper {
        display: flex;
        justify-content: flex-start;

        svg {
            margin-right: 20px;
            width: 42px;
            height: 42px;

            path {
                stroke: $primary-color;
            }
        }
    }

    &-titles-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 18px;
        /* identical to box height, or 112% */

        display: flex;
        align-items: center;
    }

    &-subtitle {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        /* identical to box height, or 112% */

        display: flex;
        align-items: center;

        /* Grey */

        color: #808080;
    }

    &-down-arrow {
        transition: all 0.3s;
        transform: rotate(-90deg);
    }

    // &-collapse {
    //     padding: 0 0 0 64px;
    // }
}

.profile-automation-item.active {
    border: 2px solid #1f76ff;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    padding: 22px;
    // height: 100%;
    max-height: max-content;

    .profile-automation-item-down-arrow {
        transform: rotate(0deg);
    }

    .profile-automation-item-down-arrow {
        path {
            stroke: $primary-color;
        }
    }
}

@include for-size(phone-only) {
    .profile-automation-item {
        padding: 20px 10px;

        &-left-wrapper {
            svg {
                min-width: 35px;
                height: 35px;
                max-width: usnet;
                max-height: unset;
                margin-right: 10px;
            }
        }

        &-down-arrow {
            display: none;
        }
    }

    .profile-automation-item.active {
        padding: 20px 10px !important;
    }
}
