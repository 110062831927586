.main-content-wrapper {
    width: 420px;
    display: flex;
    flex-direction: column;
}

.selected-tags {
    display: flex;
    column-gap: 5px;
    align-items: center;
}
