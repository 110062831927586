@import "../common/styles";

.navigation-list-items-heading {
    font-family: $font-family;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.46px;
    padding: 20px;
    user-select: none;

    .bp4-skeleton {
        display: block;
        min-width: 200px;
    }
}

.navigation-list-items-dots {
    flex: initial;
    display: none !important;
    position: absolute;
    right: 10px;
}

.navigation-list-items-item {
    color: rgba(0, 0, 0, 0.5);
    font-family: $font-family;
    font-size: 15px;
    font-weight: 600;
    padding-left: 40px;
    line-height: normal;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    letter-spacing: 0.02em;
    justify-content: space-between;
}

.navigation-list-items-inner-container:not(.bp4-disabled):hover {
    background: #f4f8ff;
    .navigation-list-items-item {
        color: $primary-color;
    }
    hr {
        display: none;
    }
    .navigation-list-items-dots {
        display: initial !important;
    }
}

.navigation-list-items-inner-container span.bp4-skeleton {
    width: 150px;
    display: block;
}

.navigation-list-items-inner-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 35px;
    padding-right: 20px;
    hr {
        left: 20px;
        top: 0;
        bottom: 0;
        height: 100%;
        margin: 0;
        position: absolute;
        border-style: solid;
        border-color: $border-color;
        border-width: 0px 1px 0px 0px;
    }
    &.mpd-active {
        background: initial !important;
        hr {
            display: initial !important;
            border-color: $primary-color !important;
        }
        .navigation-list-items-item,
        .counter {
            color: $primary-color !important;
            font-weight: 600 !important;
        }
    }
}

.counter {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #bfbfbf;
}

@supports (-webkit-overflow-scrolling: touch) {
    .navigation-list-items-inner-container:hover {
        background: none;
        .navigation-list-items-item {
            color: rgba(0, 0, 0, 0.5);
        }
        hr {
            display: initial;
        }
        .navigation-list-items-dots {
            display: none !important;
        }
    }
}
