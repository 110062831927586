.skeleton-title {
    height: 16px;
    border-radius: 18px !important;
    min-width: calc(100% - 20px);
}

.skeleton-icon {
    border-radius: 50% !important;
}
.skeleton-summary{
    height: 16px;
    border-radius: 18px !important;
    width: calc(100% - 100px);
}

