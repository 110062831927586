@import "../../../../../../common/styles";

.unmapped {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 2;

    &__counter {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: #293136;
    }

    :global(.stop-propagation-block) {
        width: max-content;
    }

    &__checkbox {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.75);
    }
}

.unmapped-fields-tooltip-target {
    border: 1.5px solid $primary-color;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-color;
    cursor: pointer;
}

.tooltip-content {
    font-family: $font-family;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
}
