@import "../common/styles";
  
.bottom-navigation-bar {
    display: flex;
    justify-content: space-between;
    height: 50px;
    background: white !important;
    border-top: 1px solid $border-color;
    padding-left: 10px;
    padding-right: 8px;
    background-color: white;
    border-bottom-left-radius: 16px;
    z-index: 1;
    &.geofence {
      display: none;
    }
    &.mpd-loading {
      opacity: 0.5;
      pointer-events: none;
    }
    .mpd-button-text {
      font-weight: 700;
      text-transform: uppercase;
    }
  
    .bp4-align-right {
      display: flex;
      justify-content: flex-end;
    }
  
    .bp4-align-left {
      .mpd-button {
        &.mpd-hide {
          display: none;
        }
      }
  
      .mpd-button-text {
        margin-left: 5px;
      }
    }
    .bp4-align-right {
      .extra-button {
        margin: 0 15px;
      }

      .tim-clock {
        min-width: initial !important;
        padding: 0 !important;
        margin-right: 10px;
        flex: initial;
        &.mpd-active {
           border: 1px solid #1f76ff !important;
        }
      }
    }
  }

.bottom-navigation-bar-date-input {
  .DayPicker-NavButton {
    outline: none;
  }
  .DayPicker-wrapper {
    outline: none;
  }
}