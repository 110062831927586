@import "../../../../common/styles.scss";

.organization-settings-detail-edit {
    &-image-upload {
        height: 82px;
        .image-upload-icon-image {
            width: 60px;
            height: 60px;
        }
    }
    &-empty-image-upload {
        .image-upload-icon-filler {
            border: 2px dashed rgba(0, 0, 0, 0.15);
            background: #fff;
            svg {
                path {
                    fill: rgba(0, 0, 0, 0.15);
                }
            }
        }
    }

    &-details-info-item-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    &-main-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.organization-settings-detail-edit-icon-container {
    display: flex;
}

.organization-settings-detail-edit-account-owner-names {
    .styled-floating-input {
        margin-top: 0;
    }
}

@include for-size(tablet-portrait-up) {
    .organization-settings-detail-edit-details-info {
        padding-bottom: 60px;
    }
}

@include for-size(phone-only) {
    .organization-settings-detail-edit-details-info {
        padding-bottom: 60px;
    }
}
