@import "../../../common/styles";

.map-layers-settings {
    height: calc(100% - 50px);
    position: relative;
    display: flex;
    flex-direction: column;
    .geo-map {
        .geo-map-draw-controls {
            &.mpd-justify-top {
                top: 100px;
            }
        }
    }
}

.map-layers-settings-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(229, 227, 223);
}

.map-layers-settings-empty {
    height: 100%;
    justify-content: center;
    .title {
        font-size: 22px;
    }
    .subtitle {
        color: $light-black-color;
        font-size: 16px;
    }
}

.map-layers-settings-alert-top {
    position: relative;
    .mpd-icon {
        cursor: pointer;
    }
    .styled-button {
        top: 0;
        right: 5px;
        position: absolute;
        padding: 0 !important;
    }
}

.map-layers-settings-alert-title {
    text-align: center;
    color: black;
    font-family: "Open Sans";
    font-size: 19px;
    font-weight: 700;
}

.map-layers-settings-box-select:first-child {
    margin-right: 20px;
}

.map-layers-settings-box-select {
    flex: 1;
    .box-select-image {
        width: 100%;
    }
}

.map-layers-settings-alert {
    width: 450px !important;
    .box-select-title {
        width: initial;
    }
    .styled-alert-global-content-description {
        padding-bottom: 0;
    }
    .bp4-alert-footer {
        margin-top: 0px;
        .bp4-button {
           background-color: $primary-color !important;
        }
        .bp4-button-text {
            color: white;
            font-family: $font-family;
            background-image: none;
            font-family: "Open Sans";
            font-size: 10px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
    .bp4-alert-contents {
        width: 100%;
    }
}

.map-layers-settings-alert-body {
    display: flex;
    padding: 30px 0;
    justify-content: space-between;
}

