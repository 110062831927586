@import "../../../../common/styles";

.user-settings-view-details-activity-wrapper {
    height: 100%;
    padding: 30px 0;
    overflow: auto;
}

.user-settings-view-details-activity-item {
    user-select: none;
}

.user-settings-view-details-activity-day {
    color: black;
    font-family: $font-family;
    font-size: 18px;
    font-weight: 600;
}

.user-settings-view-details-activity-list{
    height: 100%;
    min-width: 800px;
    padding: 30px 0;
    overflow-x: auto;
    overflow-y: hidden;
}

.user-settings-view-details-activity-scrolled-data {
    height: 100%;
    margin: 0;
    overflow-y: auto;
}

.user-settings-view-details-activity-styled-date-inputs {
    width: 150px;
    max-width: 150px;
}

.user-settings-view-details-activity-styled-date-inputs.user-settings-view-details-activity-styled-date-inputs:first-child {
    margin-right: 10px;
}

.user-settings-view-details-activity-spinner {
    margin: 30px 0 60px;
}

@include for-size(phone-only) {
    .user-settings-view-details-activity-wrapper {
        padding: 15px 0;
    }

    .user-settings-view-details-activity-header {
        flex-wrap: wrap;
    }

    .user-settings-view-details-activity-styled-dates-picker {
        margin-bottom: 18px;
    }

    .user-settings-view-details-activity-list {
        min-width: auto;
        padding-top: 5px;
    }
}
