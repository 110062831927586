@import "../common/styles.scss";

.search-input.bp4-active {
  background: #f4f8ff;
  border: 1px solid #1f76ff;
  box-shadow: 0 0 0 1px #1f76ff;
}

.search-input {
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
  border: 1px solid $border-color;
  background-color: white;
  overflow: hidden;

  &_list {
    border-radius: 100px;
  }

  &_dropdown {
    border-radius: 12px;
    height: 40px;
  }

  .mpd-icon {
    margin: 0px 10px;
    width: 17px;
    flex-shrink: 0;
  }
  input {
    background-color: unset !important;
    padding-right: 10px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    flex: 1;
    padding-bottom: 2px;
    font-family: $font-family !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #000000;
  }
  ::placeholder {
    font-family: $font-family !important;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #bfbfbf;
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 7.5px;
    width: 7.5px;
    content: url(../../mpd-library/icon/assets/searchInputCancel.svg);
    cursor: pointer;
  }
}
