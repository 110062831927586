@import "../common/styles";

.access-permissions {
    svg {
        path {
            fill: $primary-color;
            fill-opacity: 1;
        }
    }
}
