
@import "../common/styles";

.list-header-icons {
    display: flex;
    align-items: center;
}

.list-header-icons-item {
    border-radius: 4px;
    border: 1px solid $border-color;
    background-color: white;
    margin: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.list-header-icons-button {
    margin-right: 10px;
    .bp4-button-text {
        // min-width: 30px;
        min-height: 0px;
    }
}

.list-header-icons-select {
    .mpd-select {
        width: 32px !important;
    }
}

.list-header-icons-popover {
    .mpd-select-options {
        min-width: 100px !important;
    }
}