@import "../../../common/styles.scss";
  
.sidebar-profile-item {
    display: flex;
    justify-content: center;
}

.sidebar-profile-item-icon {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #4a90e2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family;
    font-size: 16px;
    line-height: 10px;
    letter-spacing: 0.05em;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
}

.sidebar-profile-item-button {
    display: none !important;
    letter-spacing: 0.05em;
    color: $light-white-color;
    font-size: 15px;
    font-weight: 600;
    font-family: $font-family;
    .mpd-button-text {
        margin-top: 0 !important;
        margin-left: 20px !important;
    }
}

@include for-size(phone-only) {
    .sidebar-profile-item-wrapper {
        min-width: initial;
    }
    .sidebar-profile-item-button {
        display: flex !important;
    }
}