@import "../../../../../components/common/styles";
.root {
    position: relative;
    align-items: center;
    display: flex;
    padding: 0;
    // overflow-x: hidden;
    // overflow: hidden;
    .selected-contacts-label {
        position: absolute;
        top: -28px;
        left: 0;
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        color: #7f8896;
    }
    .selected-contacts {
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 100%;
        // overflow-x: auto;
        // overflow-y: visible;
        padding: 0 10px;
        overflow: auto;
        margin-right: -10px;
    }

    .selected-contact {
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        padding: 0 10px;
        white-space: nowrap;
        height: 28px;
        align-items: center;
        display: flex;

        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #999999;
    }
}
