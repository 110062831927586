@import "../common/styles";

.dropdown-search-header {
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    max-height: 100px;
    position: sticky;
    row-gap: 20px;
    top: 0;
    width: 100%;
    background-color: white;
    overflow: hidden;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    z-index: 2;

    &-title {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: $black;
    }

    &-search-input {
        background-color: white;
        // border: 2px solid #1f76ff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        border-radius: 12px !important;
        width: 100%;
        height: 40px;
        border-radius: 12px;
        height: 40px;

        input {
            margin-right: 5px;
            transition: all 0.3s ease-out;
            font-size: 14px;
        }

        transition: all 0.3s ease-out;

        &:focus-within {
            border: 1px solid #1f76ff;
            box-shadow: 0 0 0 1px #1f76ff;
            background: #f4f8ff;
            border-radius: 12px !important;

            input {
                background: #f4f8ff;
            }
        }
    }

    .back-button {
        position: absolute;
        top: 5px;
        left: 10px;
    }

    // need to not overlap close modal button on smartphones. May be there more elegant solution
    @include for-size(phone-only) {
        top: 52px;
    }
}
