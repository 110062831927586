@import "../../../../../common/styles";

.smart-link-info {
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
    flex: 1;
    max-width: 100%;
    min-width: 20%;

    .link-to-branding {
        cursor: pointer;
    }

    & > :first-child {
        max-width: 100px;
        width: 100px;
        flex: 1;
    }

    & > :nth-child(2) {
        max-width: 300px;
        flex: 2;
    }

    :global(.info-cell) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 15px;
    }

    :global(.info-cell-title) {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000000;
    }

    :global(.info-cell-value) {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: $primary-color;
        white-space: nowrap;
        text-overflow: ellipsis;
        // max-width: 80%;
        // max-width: 260px;
        width: 100%;
        overflow: hidden;
    }

    :global(.qr-code) {
        min-width: 56px;
        max-width: 56px;
        height: 56px;
        background-image: url("../../../../../../mpd-library/icon/assets/transparent-bg.jpeg");
        background-size: 10px;
    }
}
