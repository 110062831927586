@import "../../../../../common/styles";

.subscription-box-content-list-item {
    display: flex;
    align-items: center;
}

.subscription-box-content {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding: 20px;
    flex: 1;
}

.subscription-box-content-list-item-title {
    color: $lighter-black-color;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 10px;
}

.subscription-box-content-title {
    align-self: center;
    color: black;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 5px;
}

.subscription-box-content-action {
    position: absolute;
    align-self: center;
    bottom: 20px;
    .mpd-button-text {
        color: $primary-color;
        font-family: $font-family;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
    }
}