@import "../common/styles";

.alert-replies-settings-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
}

.alert-reply-settings {
    margin-top: 14px;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;

    &-tags-dropdown-wrapper {
        :global(.bp4-popover2) {
            padding: 0;
        }
    }
    &-collapsed-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 360px;
    }

    &-expandable-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: $black;
    }

    &-custom-options-wrapper {
        padding: 10px;
    }

    :global(.single-admin-dropdown-search-dropdown-content-wrapper) {
        max-height: 200px;
    }

    :global(.bp4-popover2-target) {
        width: 100%;
    }

    :global(.admins-dropdown),
    :global(.dropdown-select-with-search-main-wrapper),
    :global(.alert-reply-settings-dropdown) {
        max-width: 360px;
    }

    &-tags-popover {
        width: 297px !important;
    }

    // &-tags-dropdown-wrapper {
    //     :global(.bp4-popover2) {
    //         padding: 0;
    //     }

    //     :global(.tags-dropdown-main-wrapper) {
    //         padding: 0 10px 10px 10px;
    //     }
    // }

    :global(.admins-dropdown),
    :global(.dropdown-select-with-search-main-wrapper) {
        margin-top: 20px;
    }
}
