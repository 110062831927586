@import "../../../common/styles";

.root {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 10px;
    .top-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .note-by {
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #000000;

        span {
            font-weight: 700;
        }
    }

    .date {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        text-align: right;
        color: #1f76ff;
    }

    .note-value {
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
    }
}
